import { EventType } from '../constants';

interface EntityNames {
  sites: Record<number, string>;
  labels: Record<number, string>;
  users: Record<number, string>;
}

export const formatEventLogMessage = (
  eventType: EventType,
  data: any,
  entityNames: EntityNames,
): string => {
  switch (eventType) {
    // Site related events
    case EventType.CREATE_SITE:
      return `Created site ${entityNames.sites[data.SiteID] || 'Unknown Site'}`;
    case EventType.UPDATE_SITE:
      return `Updated site ${
        entityNames.sites[data.site_id] || 'Unknown Site'
      }`;
    case EventType.DELETE_SITE:
      return `Deleted site ${data.deletedSiteName}`;

    // Label related events
    case EventType.CREATE_LABEL:
      return `Created site group ${
        entityNames.labels[data.LabelID] || 'Unknown Site Group'
      }`;
    case EventType.UPDATE_LABEL:
      return `Updated site group ${
        entityNames.labels[data.id] || 'Unknown Site Group'
      }`;
    case EventType.DELETE_LABEL:
      return `Deleted site group ${data.deletedLabelName}`;

    // User related events
    case EventType.INVITE_USER:
      return `Invited user ${data.email}`;
    case EventType.REMOVE_USER:
      return data.email
        ? `Removed user ${data.email}`
        : `Removed user ${entityNames.users[data.userID] || 'Unknown User'}`;
    case EventType.DELETE_INVITE:
      return `Deleted invitation for ${data.email}`;

    // Access rules events
    case EventType.CREATE_ACCESS_RULES:
      return `Created access rule for ${
        entityNames.users[data.UserID] || 'Unknown User'
      }`;
    case EventType.UPDATE_ACCESS_RULES:
      return `Updated access rule for ${
        entityNames.users[data.UserID] || 'Unknown User'
      }`;
    case EventType.DELETE_ACCESS_RULES:
      return `Deleted access rule for ${
        entityNames.users[data.UserID] || 'Unknown User'
      }`;
    case EventType.UPDATE_SITE_STATUS:
      return `Updated site status for ${
        entityNames.sites[data.site_id] || 'Unknown Site'
      }`;
    default:
      return `-`;
  }
};
