import ChannelImageStream from '@/components/channel-image-stream';
import ChannelVideoStream from '@/components/channel-video-stream';
import DFVideo from '@/components/DFVideo';
import FrontierUpload from '@/components/FrontierUpload';
import ImageStripScrubber from '@/components/ImageStripScrubber';
import ViewInsightTile from '@/components/view-insight-tile';
import { recordTransaction } from '@/monitoring';
import CreateEvent from '@/pages/investigations/components/create-event';
import UpdateEventTime from '@/pages/investigations/components/update-event-time';
import {
  convertActualTimeToESTime,
  CUE_TYPE,
  dispatchWithFeedback,
  getCurrentCustomerID,
  getImageDimensions,
  linkTo,
  META_VMS_PLUGIN_ID,
  relativeTimezone,
  SHOULD_BE_LIVE_BUFFER,
  STREAM_TYPES,
} from '@/utils/utils';
import { Heatmap } from '@ant-design/plots';
import { Button } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import semver from 'semver';
// import { generateInsight } from '@/utils/insight';

const datetimeFormat = 'YYYY-MM-DD HH:mm:ss Z';

import { DEFAULT_BASESTATION_VERSION } from '@/constants';
import Journey from './my-journey';
import styles from './timeline.less';

const imageStreamSelections = [
  'CIF_025',
  'CIF_1',
  'SD_1',
  'R720p_025',
  'R720p_1',
  'R1080p_1',
  'R50MP_15',
  'R50MP_20',
  'R53MP_15',
  'R53MP_20',
  'R4k_1',
];
const videoStreamSelections = [
  'DEFAULT',
  'CIF_1',
  'SD_1',
  'R720p_025',
  'R720p_1',
  'R720p_15',
  'R1080p_1',
  'R1080p_15',
  'R50MP_15',
  'R50MP_20',
  'R53MP_15',
  'R53MP_20',
  'R4k_1',
];

// higher index renders first
export const CUE_RENDER_ORDER = [
  CUE_TYPE.INSIGHT,
  CUE_TYPE.CLOUD_THUMBNAIL,
  CUE_TYPE.STREAMING,
  CUE_TYPE.HEATMAP,
  //CUE_TYPE.PENDING_UPLOAD,
  CUE_TYPE.CLOUD_VIDEO,
  CUE_TYPE.SEARCH_RESULT,
];

export const SKIP_END_OVERRIDE = [
  CUE_TYPE.PENDING_UPLOAD,
  CUE_TYPE.STREAMING,
  CUE_TYPE.INSIGHT,
];

export const INFINITE_TIMELINES = [CUE_TYPE.INSIGHT];

export const getDefaultCueRange = () => {
  // round out to 100 seconds so we're not updating cuesRange too much,
  // which would lead to a lot of updates
  const nowTime = Date.now() / 1000;

  return [
    Math.round((nowTime - 60 * 10) / 100) * 100,
    Math.round((nowTime + 60 * 10) / 100) * 100,
  ];
};

//Function that takes the retention period of the base station (seconds)
//and converts it to a range of [now-retention_secs, now]
export const getCueRangeForArchivedStreams = (retention_period: number) => {
  const nowTime = Date.now() / 1000;
  const retentionFromTime = nowTime - retention_period;

  // Round the timestamps to the nearest 100 seconds
  const roundedNowTime = Math.round(nowTime / 100) * 100;
  const roundedRetentionFromTime = Math.round(retentionFromTime / 100) * 100;

  return [roundedRetentionFromTime, roundedNowTime];
};

export const getTileLabel = (channel, showLink) => {
  if (!channel) {
    return '';
  }
  const timezoneStr = relativeTimezone(channel?.Timezone);
  const str =
    timezoneStr === '' ? (
      ''
    ) : (
      <span style={{ fontSize: '10px' }}>&nbsp;{timezoneStr}</span>
    );

  if (!showLink) {
    return (
      <span>
        {channel?.Name} {str}
      </span>
    );
  }

  return (
    <span>
      {linkTo(
        'CHANNEL',
        { chID: channel.ID, locID: channel.ProjectID },
        channel?.Name,
      )}
      {str}
    </span>
  );
};

export const getTrackID = (type, entityID) => {
  return entityID ? entityID.toString() : type;
};

export const getSavedVideoCues = (cues) => {
  return _.filter(cues, (cue) => cue.data.cue_type === CUE_TYPE.CLOUD_VIDEO);
};

// when multiple cues overlap in time, their media needs
// to be merged so all of their info can be shown at once:
// eg if there are 3 search result hits at a playhead, we need
// to see all 3 bboxes
const mediaMergerFn = (c1Media, c2Media) => {
  c1Media.bboxes = [
    ..._.get(c1Media, 'bboxes', []),
    ..._.get(c2Media, 'bboxes', []),
  ].sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
  return c1Media;
};

export const getPendingUploadTasks = (dispatch, channel) => {
  if (!channel) {
    return Promise.resolve([]);
  }
  const channelID = channel.ID;

  return dispatchWithFeedback(
    dispatch,
    'Fetching status',
    {
      type: 'apps/doAppOpNoloader',
      appID: 48,
      payload: {
        op: 'upload_video',
        params: {
          customer_id: getCurrentCustomerID(),
          channel_id: channelID,
        },
      },
    },
    true,
  ).then((res) => {
    // check which, if any, of the tasks apply here
    const list = _.get(res, 'Data.tasks_list', []);
    const tasks = _.map(list, (task) => {
      const taskChannelID = _.get(task, 'Data.channel_id');
      if (taskChannelID !== channelID) {
        return null;
      }

      // these are fully qualified timestamps with timezone, so correspond to UTC
      const startTimeStr = _.get(task, 'Data.start_time');
      const endTimeStr = _.get(task, 'Data.end_time');

      if (!startTimeStr && !endTimeStr) {
        return null;
      }

      // we need local times
      const timezone = channel.Timezone || 'UTC';
      const startTimeLocal = convertActualTimeToESTime(
        moment.tz(startTimeStr, datetimeFormat, timezone),
        timezone,
      );
      const endTimeLocal = convertActualTimeToESTime(
        moment.tz(endTimeStr, datetimeFormat, timezone),
        timezone,
      );

      // the event expects time corresponding to channel local time
      return {
        startTimeLocal,
        endTimeLocal,
        channelID: taskChannelID,
        state: task.State,
        stateMessage: task.StateMessage,
        taskID: task.TaskID,
      };
    }).filter((x) => x);
    return tasks;
  });
};

export const getTimezoneForList = (items: any[]) => {
  let common_timezone: string | null = null;

  items.forEach((item) => {
    const timezone =
      _.get(item, 'Timezone') || _.get(items, 'Channel.Timezone');
    if (common_timezone === null) {
      common_timezone = timezone;
    } else if (timezone && common_timezone !== timezone) {
      common_timezone = moment.tz.guess();
    }
  });

  return common_timezone;
};

//Returns retention period (in seconds) for the base station (if applicable)
export const getArchiveVideoRetentionPeriod = (
  channelConfig: any,
  channelID: number,
) => {
  let retention_secs = 0;

  //Check if there is a channel specific NAS value configured
  const channel_retentions = _.get(
    channelConfig,
    [
      channelID,
      'ConfigProfiles',
      'dc_onprem_externalStorageDevicesConfigs',
      'values',
      'configs',
      0,
      'ChannelRetentionPeriods',
    ],
    [],
  );

  if (channel_retentions.length > 0) {
    const this_channel_retention = channel_retentions.filter((channel: any) => {
      return channel.ChannelID == channelID;
    });
    if (this_channel_retention && this_channel_retention.RetentionDurationInt) {
      retention_secs = this_channel_retention.RetentionDurationInt;
    }
  }

  //Check if there is a default NAS value configured
  if (!retention_secs) {
    retention_secs = _.get(
      channelConfig,
      [
        channelID,
        'ConfigProfiles',
        'dc_onprem_externalStorageDevicesConfigs',
        'values',
        'configs',
        0,
        'DefaultChannelRetentionPeriod',
      ],
      0,
    );
  }

  //Check if there is any other value configured
  if (!retention_secs) {
    retention_secs =
      _.get(
        channelConfig,
        [
          channelID,
          'ConfigProfiles',
          'dc_onprem_mediaArchiveAgeInMins',
          'values',
          'minutes',
        ],
        0,
      ) * 60;
  }

  return retention_secs > 0 ? retention_secs : null;
};
// ----------

class Cue {
  constructor(params = {}, type = null) {
    _.assign(this, params);

    // if we're explicitly passed one
    this.data.type = this.data.type || type;

    // key to figure out whether to recreate react els
    this.key = this.getKey();

    // cues with same track show up in the same line
    this.data.track = getTrackID(this.data.type);
  }

  shouldRender() {
    return !!this.data.render;
  }

  getKey() {
    return `${this.data.cue_type}-${this.data.startEdge}-${this.data.endEdge}`;
  }

  getLabel() {
    return this.data.label;
  }

  getPillRange() {
    return [this.interval.low, this.interval.high];
  }
}

class ChannelCue extends Cue {
  constructor(params) {
    super(params);
    this.data.track = getTrackID(this.data.cue_type, this.data.channelID);

    // timezone from the channel object
    this.data.timezone = this.data.timezone || this.data.channel?.Timezone;
  }

  getLabel(showLink) {
    return this.data.label || getTileLabel(this.data.channel, showLink);
  }

  getPillClass() {
    return styles['media-pill-processing'];
  }
}

class CloudVideoCue extends ChannelCue {
  constructor(params) {
    super(params);
    this.data.render = this.renderFn;
    this.data.mediaMerger = mediaMergerFn;
    this.data.actions = this.actionsFn;
    this.data.preview = this.previewFn;
  }

  getKey() {
    // uploadid uniquifies the search result which the rest of it do not
    return `${this.data.cue_type}-${this.data.media?.ChannelID}-${
      this.data.media?.UploadID
    }-${this.data.startEdge}-${this.data.endEdge}-${_.get(
      this.data,
      'event.InvestigationEventID',
      '',
    )}`;
  }

  getPillClass() {
    if (
      this.data.media?.TranscodedVideo?.SignedUrl ||
      this.data.media?.UploadedVideo?.SignedUrl
    ) {
      return styles['media-pill'];
    }
    if (this.data.media?.ThumbnailStrip?.SignedUrl) {
      return styles['media-pill-onprem'];
    }
    return '';
    // return styles['media-pill-processing'];
  }

  actionsFn = () => {
    if (!this.data.showTimelineActions) {
      return <></>;
    }
    return _.get(this.data.event, 'Channel.CreatedFrom') === 'FILE-UPLOAD' ? (
      <div
        className="df-menu-container"
        style={{ zIndex: 4 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        {/* <div className="df-menu-item-container">
           <AlignEventTime
           event={event}
           // hoverEpoch={Math.min(hoverEpoch, eventEnd + 0.0001)}
           >
           <div className="df-menu-item">Align Timecode</div>
           </AlignEventTime>
           </div> */}
        <div className="df-menu-item-container">
          <UpdateEventTime event={this.data.event}>
            <div className="df-menu-item">Update Metadata</div>
          </UpdateEventTime>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  previewFn = (hoverTime) => {
    const thumbnail = _.get(this.data.media, 'Thumbnail');
    const thumbnailStrip = _.get(this.data.media, 'ThumbnailStrip');

    const positionRatio =
      (hoverTime - this.data.actualVideoStartSecs) /
      (this.data.actualVideoEndSecs - this.data.actualVideoStartSecs);
    return (
      <ImageStripScrubber
        key={`${this.key}-scrub`}
        showLine={false}
        thumbnail={thumbnail}
        strip={thumbnailStrip}
        positionRatio={positionRatio}
      />
    );
  };

  renderFn = (
    TimelineComponent,
    cue,
    media,
    axisAnchorDate,
    position,
    _internalUser,
  ) => {
    const playhead = TimelineComponent.state.currentPlayTime;

    // find bboxes from the sorted array that are within a second
    // we need this because the bboxes are created at different fps
    // than the video, so for a given playhead there might be bboxes
    // a bit before and after. we triangulate the bboxes if needed
    const lowIndex = _.sortedIndexBy(
      media.bboxes,
      { timestamp: playhead - 2.0 },
      'timestamp',
    );
    const highIndex = _.sortedLastIndexBy(
      media.bboxes,
      { timestamp: playhead + 2.0 },
      'timestamp',
    );

    // for each object, find the bbox that is closest to the playhead,
    // before and after the current playhead
    const nearestOnes = {};
    media.bboxes.slice(lowIndex, highIndex).forEach((bbox) => {
      let obj = nearestOnes[bbox.objectID];
      if (!obj) {
        obj = {};
      }
      if (
        playhead > bbox.timestamp &&
        Math.abs(playhead - _.get(obj, 'lower.timestamp', 0)) >
          Math.abs(playhead - bbox.timestamp)
      ) {
        obj.lower = bbox;
      } else if (
        playhead <= bbox.timestamp &&
        Math.abs(playhead - _.get(obj, 'higher.timestamp', 0)) >
          Math.abs(playhead - bbox.timestamp)
      ) {
        obj.higher = bbox;
      }
      nearestOnes[bbox.objectID] = obj;
    });

    const finalList = [];
    // for each object, figure out which of its bboxes to show
    // if we only have one nearest, use that. if we have one before
    // and after playhead, use triangulation to find the middle
    Object.entries(nearestOnes).forEach(([_objectID, nearby]) => {
      let chosen;
      if (!nearby.lower) {
        chosen = nearby.higher;
      } else if (!nearby.higher) {
        chosen = nearby.lower;
      } else {
        const ratio =
          (playhead - nearby.lower.timestamp) /
          (nearby.higher.timestamp - nearby.lower.timestamp);
        const x1 =
          nearby.lower.bbox.x1 +
          (nearby.higher.bbox.x1 - nearby.lower.bbox.x1) * ratio;
        const x2 =
          nearby.lower.bbox.x2 +
          (nearby.higher.bbox.x2 - nearby.lower.bbox.x2) * ratio;
        const y1 =
          nearby.lower.bbox.y1 +
          (nearby.higher.bbox.y1 - nearby.lower.bbox.y1) * ratio;
        const y2 =
          nearby.lower.bbox.y2 +
          (nearby.higher.bbox.y2 - nearby.lower.bbox.y2) * ratio;
        chosen = {
          objectID: nearby.lower.objectID,
          timestamp: playhead,
          bbox: { x1, x2, y1, y2 },
          clickHandler: nearby.lower.clickHandler,
          inferenceHeight: nearby.lower.inferenceHeight,
          inferenceWidth: nearby.lower.inferenceWidth,
          // border: '1px solid red',
        };
        // the following, plus the border above, can be used to
        // visualize triangulation of the bboxes
        // console.log('triangulated', nearby.lower, nearby.higher, chosen);
        // finalList.push(nearby.lower);
        // finalList.push(nearby.higher);
      }
      finalList.push(chosen);
    });

    // This can be used to test the m3uURLs:
    // return <video src={cue.data.m3uURL} />;
    if (cue.data.m3uURL || cue.data.videoURL) {
      return (
        <DFVideo
          key={getTrackID(cue.data.cue_type, cue.data.channelID)}
          isPlaying={TimelineComponent.state.isPlaying}
          pauseTObjForLoading={TimelineComponent.pauseTObjForLoading}
          playTObjAfterLoading={TimelineComponent.playTObjAfterLoading}
          // will use the m3uURL if available
          src={cue.data.m3uURL || cue.data.videoURL}
          transcodedVideoDimension={cue.data.transcodedVideoDimension}
          bboxes={finalList}
          videoRef={this.data.media.videoRef}
          ref={(e) => {
            //console.log(`syncing ${cue.data.actualVideoStartSecs} for ${playhead} with ${this.data.event.ChannelID}, for ${e}`);
            TimelineComponent.syncElementToTimingObj(
              e,
              cue.data.actualVideoStartSecs * 1000,
            );
            this.data.media.videoRef = e;
          }}
        />
      );
    }
    const key = `ch-${cue.data.channelID}-archive-${Math.round(
      axisAnchorDate,
    )}`;
    return (
      <div className={styles['cue-ctn']} key={key}>
        <FrontierUpload
          loadHistoryForAxisRange={TimelineComponent.loadHistoryForAxisRange}
          channelID={cue.data.channelID}
          timezone={cue.data.timezone}
          position={position}
        />
      </div>
    );
  };
}

class CloudThumbnailCue extends ChannelCue {
  constructor(params) {
    super(params);
    this.data.render = this.renderFn;
    this.data.mediaMerger = mediaMergerFn;
    this.data.actions = this.actionsFn;
    this.data.preview = this.previewFn;
  }

  getKey() {
    // uploadid uniquifies the search result which the rest of it do not
    return `${this.data.cue_type}-${this.data.media?.ChannelID}-${
      this.data.media?.UploadID
    }-${this.data.startEdge}-${this.data.endEdge}-${_.get(
      this.data,
      'event.InvestigationEventID',
      '',
    )}`;
  }

  getPillClass() {
    return styles['media-pill-onprem'];
  }

  actionsFn = () => {
    return <></>;
  };

  previewFn = (hoverTime) => {
    const thumbnail = _.get(this.data.media, 'Thumbnail');
    const thumbnailStrip = _.get(this.data.media, 'ThumbnailStrip');

    const positionRatio =
      (hoverTime - this.data.actualVideoStartSecs) /
      (this.data.actualVideoEndSecs - this.data.actualVideoStartSecs);
    return (
      <ImageStripScrubber
        key={`${this.key}-scrub`}
        showLine={false}
        thumbnail={thumbnail}
        strip={thumbnailStrip}
        positionRatio={positionRatio}
      />
    );
  };

  renderFn = () => {
    return <></>;
  };
}

export class InsightCue extends Cue {
  constructor(params) {
    super(params, CUE_TYPE.INSIGHT);
    this.data.render = this.renderFn;

    this.data.track = this.getKey();
  }

  getKey() {
    return (
      _.get(this.data, 'event.key', null) ||
      `${_.get(this.data, 'cue_type', null)}-${_.get(
        this.data,
        'event.insightID',
        null,
      )}`
    );
  }

  getPillClass() {
    return styles['insight-pill'];
  }

  renderFn = (TimelineComponent) => {
    const { insightID, vizSpec } = _.get(this.data, 'event', {});

    if (insightID) {
      const playhead = TimelineComponent?.state.currentPlayTime;
      return (
        <ViewInsightTile
          insightID={insightID}
          playhead={playhead}
          vizSpec={vizSpec}
        />
      );
    }
    return <></>;
  };
}

export class HeatmapCue extends Cue {
  constructor(params) {
    super(params, CUE_TYPE.HEATMAP);
    this.data.render = this.renderFn;
  }

  getKey() {
    return `${this.data.cue_type}-${this.data.startEdge}-${this.data.endEdge}`;
  }

  getLabel() {
    return this.data.event.heatmapConfig.label;
  }

  getPillClass() {
    return styles['heatmap-pill'];
  }

  renderFn = () => {
    const heatmapConfig = this.data.event.heatmapConfig;
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}>
        <img
          ref={(e) => {
            // always null the first time:
            // https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
            if (!e) {
              return;
            }
            this.imageEl = e;
            // when we get a reference, and we didn't know the dimensions,
            // ask for re-render so we can overlay the heatmap properly
            //
            // we need to capture both e.complete (img was cached already)
            // and onLoad (img was downloaded, below) to cover both cases.
            //
            // note that if the img fails to load, complete will still be true,
            // we have to check for naturalWidth for this case.
            if (e.complete && e.naturalWidth > 0 && !this.imgDims) {
              // if we don't know the image dimensions of the backing image, the
              // heatmap doesn't know what to layer on
              this.imgDims = getImageDimensions(this.imageEl);
              this.data.container.forceUpdate();
            }
            // when the hetmap is not in the context of the player, resizes
            // will not cause a re-render and so we have to explicit keep track
            // of resizes
            new ResizeObserver(() => {
              const newDims = getImageDimensions(this.imageEl);
              if (!_.isEqual(newDims, this.imgDims)) {
                this.imgDims = getImageDimensions(this.imageEl);
                this.data.container.forceUpdate();
              }
            }).observe(this.imageEl);
          }}
          onLoad={() => this.data.container?.forceUpdate()}
          src={heatmapConfig.src}
          style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        />
        {this.imgDims && (
          <div
            style={{
              position: 'absolute',
              width: this.imgDims.width,
              height: this.imgDims.height,
              margin: 'auto',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            }}>
            <Heatmap {...heatmapConfig.config} />
          </div>
        )}
      </div>
    );
  };
}

class StreamingCue extends ChannelCue {
  player: React.RefObject<any>;
  constructor(params) {
    super(params);
    this.data.render = this.renderFn;
    this.player = React.createRef();
  }

  shouldRender() {
    return !this.data.eventsOnly;
  }

  getKey() {
    return `${this.data.cue_type}-${this.data.channelID}`;
  }

  getPillClass() {
    return `${styles['media-pill']} ${styles.archive_stream}`;
  }

  getPillRange() {
    if (this.data.retentionPeriod) {
      return getCueRangeForArchivedStreams(this.data.retentionPeriod);
    }
    return null;
  }

  toggleMute(isMute) {
    if ('setMute' in this.player) {
      this.player.setMute(isMute);
    }
  }

  renderArchive = (
    TimelineComponent: any,
    _dispatch: any,
    channelID: any,
    locationID: any,
    baseStationID: any,
    baseStationWifiMAC: any,
    cue: any,
    axisAnchorDate: number,
    position: any,
  ) => {
    // console.log('renderArchive');
    // when the user clicks on a different point in the axis, the
    // axisAnchorDate will change. since the key below will change,
    // we will unmount the previous element, and
    // mount a new one. this will correctly disable the previous
    // element starting from the previous anchor date, and start
    // a new one starting from this new anchor date.
    // const streamStartTime = Math.round(axisAnchorDate);
    const key = `ch-${channelID}-archive`;

    // the time shown should be the actual time shown in the display
    // of the timeline, since that's what the user will expect to be
    // downloaded from the channel. this becomes complicated if there
    // are multiple channels displayed, each with a different timezone:
    // should this dialog show the time corresponding to the channel,
    // or the timezone that the timeline player is showing (possibly in
    // a different timezone)
    // we choose to show the 'right' time for the channel, so if the user
    // just hits 'ok', the right data will be downloaded.
    const currentInChannelTimezone = moment.tz(
      position * 1000,
      cue.data.timezone,
    );
    const uploadSpec = {
      startTime: currentInChannelTimezone.clone().add(-5, 'minutes'),
      endTime: currentInChannelTimezone.clone().add(5, 'minutes'),
      channelID,
    };
    if (cue.data.vmsID !== META_VMS_PLUGIN_ID) {
      return (
        <div className={styles['cue-ctn']} key={key}>
          <div className={styles.status}>
            <p>Video for this time is not available.</p>
            <p>Create an event to retrieve video for this time.</p>
            <div>
              <CreateEvent {...uploadSpec}>
                <Button type="link">Create Event</Button>
              </CreateEvent>
            </div>
          </div>
        </div>
      );
    }
    const appVersion = _.get(
      this,
      'data.appVersion',
      DEFAULT_BASESTATION_VERSION,
    ).split('-')[0];
    if (!semver.gte(appVersion, '2.6.83')) {
      return (
        <div className={styles['cue-ctn']} key={key}>
          <FrontierUpload
            loadHistoryForAxisRange={TimelineComponent.loadHistoryForAxisRange}
            channelID={channelID}
            timezone={cue.data.timezone}
            position={position}
          />
        </div>
      );
    } else {
      const streamChangeCallback = this.data.streamChangeCallback;
      const streamType = this.data.streamType || STREAM_TYPES.R720p_15;

      return (
        <div className={styles['cue-ctn']} style={{ position: 'relative' }}>
          <ChannelVideoStream
            isArchive={true}
            startTime={Math.round(position)}
            ref={(e: any) => {
              if (e) {
                this.player = e;
                // let mediaEle;
                // try {
                //   mediaEle = this.player.getMediaElement();
                // } catch (err) {
                //   console.error(err);
                // }
                // if (mediaEle) {
                //   console.log(mediaEle, position);
                //   TimelineComponent.syncElementToTimingObj(
                //     mediaEle,
                //     streamStartTime,
                //   );
                // }
              }
            }}
            baseStationVersion={appVersion}
            key={key}
            baseStationID={baseStationID}
            baseStationWifiMAC={baseStationWifiMAC}
            locationID={locationID}
            channelID={channelID}
            axisAnchorDate={axisAnchorDate}
            isPlaying={TimelineComponent.state.isPlaying}
            streamSelections={videoStreamSelections}
            streamType={streamType}
            streamChangeCallback={streamChangeCallback}
            TimelineComponent={TimelineComponent}
          />
        </div>
      );
    }
  };

  renderLive = (
    TimelineComponent,
    channelID,
    locationID,
    baseStationID,
    baseStationWifiMAC,
    axisAnchorDate,
    _internalUser,
  ) => {
    // if we're paused, nothing to show
    if (!TimelineComponent.state.isPlaying) {
      return (
        <div className={styles['cue-ctn']}>
          <div className={styles.status}>
            <p>Live feed paused.</p>
          </div>
        </div>
      );
    }

    const key = `ch-${channelID}-live`;
    const streamChangeCallback = this.data.streamChangeCallback;
    const streamType = this.data.streamType;

    const appVersion = _.get(
      this,
      'data.appVersion',
      DEFAULT_BASESTATION_VERSION,
    ).split('-')[0];
    return (
      <div className={styles['cue-ctn']} style={{ position: 'relative' }}>
        {semver.gte(appVersion, '2.6.72') &&
        !this.data.fallbackToImageStream ? (
          <ChannelVideoStream
            ref={(e) => {
              this.player = e;
            }}
            key={key}
            baseStationID={baseStationID}
            baseStationWifiMAC={baseStationWifiMAC}
            baseStationVersion={appVersion}
            locationID={locationID}
            channelID={channelID}
            axisAnchorDate={axisAnchorDate}
            isPlaying={TimelineComponent.state.isPlaying}
            streamSelections={videoStreamSelections}
            streamType={streamType || STREAM_TYPES.R720p_15}
            streamChangeCallback={streamChangeCallback}
            TimelineComponent={TimelineComponent}
          />
        ) : (
          <ChannelImageStream
            key={key}
            showLoader={true}
            showStreamTypeSelector={true}
            streamSelections={imageStreamSelections}
            streamChangeCallback={streamChangeCallback}
            streamType={streamType || STREAM_TYPES.CIF_1}
            channelID={channelID}
          />
        )}
      </div>
    );
  };

  renderFn = (
    TimelineComponent,
    cue,
    media,
    axisAnchorDate,
    position,
    internalUser,
  ) => {
    const channelID = cue.data.channelID;
    const locationID = cue.data.locationID;
    const baseStationID = cue.data.baseStationID;
    const baseStationWifiMAC = cue.data.baseStationWifiMAC;

    // archive viewing only works if you're logged in. ask users
    // to do that if they're not. this will often happen when we're
    // showing a timeline in a public url, but the video is not in the
    // cloud at all, or so far.
    if (!cue.data.isLoggedIn) {
      return (
        <div className={styles['cue-ctn']}>
          <div className={styles.status}>
            <p>Restricted content. Login to view.</p>
          </div>
        </div>
      );
    }

    let isArchive = true;
    // close to current, we want to go live
    const now = Date.now() / 1000;

    // console.log('anchor date, comparing for live', dd(now), dd(axisAnchorDate), dd(position));
    const diff_now = now - position;
    if (diff_now < 0 || Math.abs(diff_now) < SHOULD_BE_LIVE_BUFFER) {
      isArchive = false;
    }

    // console.log('now, playtime, archive', dd(now), dd(position), isArchive);

    if (isArchive) {
      return this.renderArchive(
        TimelineComponent,
        this.data.container.props.dispatch,
        channelID,
        locationID,
        baseStationID,
        baseStationWifiMAC,
        cue,
        axisAnchorDate,
        position,
      );
    }

    // if we're not archive, and we're not supposed to be showing live,
    // there's nothing to render. Return null so the Timeline can choose
    // to show previously stored video, instead of showing a text
    // message
    if (!cue.data.showLive || !cue.data.hasLiveChannels) {
      return null;
    }

    return this.renderLive(
      TimelineComponent,
      channelID,
      locationID,
      baseStationID,
      baseStationWifiMAC,
      axisAnchorDate,
      internalUser,
    );
  };
}

class SearchResultCue extends ChannelCue {
  constructor(params) {
    super(params);
    this.data.mediaMerger = mediaMergerFn;
  }

  getKey() {
    // clipIndex is required because it's hard to find any other way to differentiate
    // search results from each other - and if you don't keep them unique, they randomly
    // get merged and cause errors like getPillIndex not found etc.
    return `${this.data.cue_type}-${this.data.startEdge}-${this.data.endEdge}-${this.data.event.clipIndex}`;
  }

  getPillClass() {
    return `${styles['media-pill']} ${styles.search_result}`;
  }
}

class JourneyCue extends Cue {
  constructor(params) {
    super(params, CUE_TYPE.JOURNEY);
    this.data.render = this.renderFn;
    this.journeyData = params.data.journeyData;
    this.mapId = params.data.mapId;
  }
  // what happens if I remove these three functions, key, label, pillclass
  getKey() {
    return `journey`;
  }

  getLabel() {
    return null;
  }

  getPillClass() {
    return styles['heatmap-pill'];
  }

  renderFn = (TimelineComponent: any) => {
    return (
      <Journey
        journeyData={this.journeyData}
        mapId={this.mapId}
        playbackTimeInSeconds={TimelineComponent.state.currentPlayTime}
      />
    );
  };
}
class PendingUploadCue extends ChannelCue {
  static statusMap = {
    // the base station is done, but if we're still showing this cue,
    // it means that the video is not available yet. probably processing,
    // or errored out.
    noVideoFound: 'No Video Found for the choosen time.',
    done: 'Processing in Cloud...',
    queued: 'Queueing...',
    pushed: 'Retrieval Queued...',
    processing: 'Retrieving...',
  };

  constructor(params) {
    super(params);
    this.data.render = this.renderFn;
  }

  getKey() {
    return `${this.data.cue_type}-${this.data.startEdge}-${this.data.endEdge}-${this.data.event.task.taskID}`;
  }

  getPillClass() {
    return `${styles['pending-video-pill']}`;
  }

  renderFn(TimelineComponent, cue) {
    let taskState = cue.data?.event?.task?.state;
    const taskMessage = cue.data?.event?.task?.stateMessage;
    if (
      (taskMessage && taskMessage === 'Exported 0 video files') ||
      taskMessage ===
        'the on demand export is older than the media archive age set'
    ) {
      taskState = 'noVideoFound';
    }

    const status = PendingUploadCue.statusMap[taskState];
    return (
      <div className={styles['cue-ctn']}>
        <div className={styles.status}>
          <p>{status || 'Retrieval Started...'}</p>
        </div>
      </div>
    );
  }
}

export class CueFactory {
  static getCue = (params) => {
    params.data.type =
      params.data.type || params.data.event?.type || CUE_TYPE.CLOUD_VIDEO;
    params.data.cue_type =
      params.data.type || params.data.event?.type || CUE_TYPE.CLOUD_VIDEO;

    const Klass = {
      [CUE_TYPE.CLOUD_THUMBNAIL]: CloudThumbnailCue,
      [CUE_TYPE.CLOUD_VIDEO]: CloudVideoCue,
      [CUE_TYPE.HEATMAP]: HeatmapCue,
      [CUE_TYPE.STREAMING]: StreamingCue,
      [CUE_TYPE.SEARCH_RESULT]: SearchResultCue,
      [CUE_TYPE.PENDING_UPLOAD]: PendingUploadCue,
      [CUE_TYPE.INSIGHT]: InsightCue,
      [CUE_TYPE.JOURNEY]: JourneyCue,
    }[params.data.type];

    return new Klass(params);
  };
}

//Temp throw away code for tracking, will be removed after a few days
function groupCuesByChannelID(cues: any[] = []) {
  return cues.reduce((acc, cue) => {
    const channelID = cue.data?.channelID;
    if (channelID) {
      if (!acc[channelID]) {
        acc[channelID] = [];
      }
      acc[channelID].push(cue);
    }
    return acc;
  }, {});
}

// Helper function to check if a timestamp falls within a cue's interval
function isTimestampInRange(cue: any, timestamp: number): boolean {
  return cue.data?.startEdge
    ? timestamp >= cue.data.startEdge && timestamp <= cue.data.endEdge
    : false;
}

// Check and log instances where there is a race condition between streaming & cloud video
export function checkAndRecordRaceCondition(
  prevCues: any[],
  newCues: any[],
  timestamp: number,
) {
  const groupedPrevCues = groupCuesByChannelID(prevCues);
  const groupedNewCues = groupCuesByChannelID(newCues);

  Object.keys(groupedNewCues).forEach((channelID) => {
    const prevCuesForChannel = groupedPrevCues[channelID] || [];
    const newCuesForChannel = groupedNewCues[channelID] || [];

    // Check if there was a StreamingCue in prevCues at the given timestamp
    const hadStreamingCue = prevCuesForChannel.some(
      (cue) => cue instanceof StreamingCue,
    );

    // Check if there was no CloudVideoCue in prevCues at the given timestamp
    const hadNoCloudVideoCue = !prevCuesForChannel.some(
      (cue) =>
        cue instanceof CloudVideoCue && isTimestampInRange(cue, timestamp),
    );

    // Check if there is a CloudVideoCue in newCues
    const hasCloudVideoCue = newCuesForChannel.some(
      (cue) =>
        cue instanceof CloudVideoCue && isTimestampInRange(cue, timestamp),
    );

    if (hadStreamingCue && hadNoCloudVideoCue && hasCloudVideoCue) {
      recordTransaction('archive_cloud_video_race_condition', 10);
    }
  });
}
