import styled from 'styled-components';

export const TeamScenesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TeamScenesListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
  gap: 20px;
  width: 100%;
`;

export const TeamScenesLoadMoreCtaContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 40px;
`;
