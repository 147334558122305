/**
 * @generated SignedSource<<419d4c2232dc9bd795958ec1942dca95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type FleetManagerApp_sites$data = {
  readonly sites:
    | {
        readonly edges: ReadonlyArray<
          | {
              readonly node:
                | {
                    readonly id: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
        readonly ' $fragmentSpreads': FragmentRefs<
          | 'EventLog_sites'
          | 'FleetStatus_sites'
          | 'Labels_sites'
          | 'Sites_sites'
        >;
      }
    | null
    | undefined;
  readonly ' $fragmentType': 'FleetManagerApp_sites';
};
export type FleetManagerApp_sites$key = {
  readonly ' $data'?: FleetManagerApp_sites$data;
  readonly ' $fragmentSpreads': FragmentRefs<'FleetManagerApp_sites'>;
};

const node: ReaderFragment = (function () {
  var v0 = ['sites'];
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'after',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'app_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customer_id',
      },
      {
        defaultValue: 10000,
        kind: 'LocalArgument',
        name: 'first',
      },
    ],
    kind: 'Fragment',
    metadata: {
      connection: [
        {
          count: 'first',
          cursor: 'after',
          direction: 'forward',
          path: v0 /*: any*/,
        },
      ],
      refetch: {
        connection: {
          forward: {
            count: 'first',
            cursor: 'after',
          },
          backward: null,
          path: v0 /*: any*/,
        },
        fragmentPathInResult: [],
        operation: require('./SitesRefetchQuery.graphql'),
      },
    },
    name: 'FleetManagerApp_sites',
    selections: [
      {
        alias: 'sites',
        args: [
          {
            kind: 'Variable',
            name: 'appId',
            variableName: 'app_id',
          },
          {
            kind: 'Variable',
            name: 'customerId',
            variableName: 'customer_id',
          },
          {
            kind: 'Literal',
            name: 'sort',
            value: ['NAME_ASC'],
          },
        ],
        concreteType: 'SiteConnection',
        kind: 'LinkedField',
        name: '__FleetManagerApp_sites_connection',
        plural: false,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'Labels_sites',
          },
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'Sites_sites',
          },
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'EventLog_sites',
          },
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'FleetStatus_sites',
          },
          {
            alias: null,
            args: null,
            concreteType: 'SiteEdge',
            kind: 'LinkedField',
            name: 'edges',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'Site',
                kind: 'LinkedField',
                name: 'node',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: '__typename',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'cursor',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PageInfo',
            kind: 'LinkedField',
            name: 'pageInfo',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'endCursor',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'hasNextPage',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'Query',
    abstractKey: null,
  };
})();

(node as any).hash = '3ce907a65a9e9c025eb09da09a39fcfc';

export default node;
