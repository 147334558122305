/* eslint-disable no-useless-escape */
import { AXIS_SCALES, carColorOptions, personColorOptions } from '@/constants';
import { filterModulesInfo } from '@/utils/filterModules';
import { DEFAULT_ROLE, INTERNAL_ROLES, ROLE_INFO } from '@/utils/roles';
import TIMINGSRC from '@/utils/timingsrc/timingsrc-v3';
import { Button, Input, notification, Space } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import deepdash from 'deepdash';
import _ from 'lodash';
import moment from 'moment-timezone';
import { parse } from 'querystring';
import cookie from 'react-cookies';
import { history, Link } from 'umi';
import { getNewTokens } from '../services/user';

export const UPLOADER_VMS_PLUGIN_ID = 1;
export const GENETEC_VMS_PLUGIN_ID = 5;
export const ONVIF_VMS_PLUGIN_ID = 8;
export const META_VMS_PLUGIN_ID = 10; // AKA Frontier

export enum CUE_TYPE {
  // when we only have thumbnail (no video) in cloud
  CLOUD_THUMBNAIL = 'cloud_thumbnail',
  SEARCH_RESULT = 'search_result',
  CLOUD_VIDEO = 'cloud_video',
  STREAMING = 'streaming',
  HEATMAP = 'heatmap',
  INSIGHT = 'insight',
  PENDING_UPLOAD = 'pending_upload',
  JOURNEY = 'journey',
}

export const SHOULD_BE_LIVE_BUFFER = 30;
export const PLAY_BACK_RATES = [
  ['1/64x', 1 / 64],
  ['1/16x', 1 / 16],
  ['1/4x', 1 / 4],
  ['1/2x', 1 / 2],
  ['1x', 1],
  ['2x', 2],
  ['4x', 4],
  ['16x', 16],
  ['64x', 64],
];

export const CIF_VIEWPORT = '352x240';
export const SD_VIEWPORT = '720x480';
export const R720p_VIEWPORT = '1280x720';
export const R1080p_VIEWPORT = '1920x1080';
export const R50MP_VIEWPORT = '2592x1944';
export const R53MP_VIEWPORT = '3072x1728';
export const R4k_VIEWPORT = '3840x2160';
export const DEFAULT_VIEWPORT = '0x0';

const getStreamTypeLabel = (viewport, fps) => {
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <span>{viewport}</span>
      {fps ? (
        <span>
          <span style={{ color: '#8e8e95cc' }}>{' @ '}</span>
          <span>{fps}</span>
          <span style={{ color: '#8e8e95cc' }}>{' fps'}</span>
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export const STREAM_TYPES = {
  CIF_025: {
    viewport: CIF_VIEWPORT,
    fps: 0.25,

    name: 'CIF (352x240) @ ¼ fps',
    label: getStreamTypeLabel('CIF', '¼'),
    key: `${CIF_VIEWPORT}/0.25`,
  },

  CIF_1: {
    viewport: CIF_VIEWPORT,
    fps: 1.0,

    name: 'CIF (352x240) @ 1 fps',
    label: getStreamTypeLabel('CIF', '1'),
    key: `${CIF_VIEWPORT}/1.0`,
  },

  SD_1: {
    viewport: SD_VIEWPORT,
    fps: 1.0,

    name: 'SD (720x480) @ 1 fps',
    label: getStreamTypeLabel('SD', '1'),
    key: `${SD_VIEWPORT}/1.0`,
  },

  R720p_025: {
    viewport: R720p_VIEWPORT,
    fps: 0.25,

    name: 'HD 720p (1280x720) @ ¼ fps',
    label: getStreamTypeLabel('720p', '¼'),
    key: `${R720p_VIEWPORT}/0.25`,
  },

  R720p_1: {
    viewport: R720p_VIEWPORT,
    fps: 1.0,

    name: 'HD 720p (1280x720) @ 1 fps',
    label: getStreamTypeLabel('720p', '1'),
    key: `${R720p_VIEWPORT}/1.0`,
  },

  R720p_15: {
    viewport: R720p_VIEWPORT,
    fps: 15.0,

    name: 'HD 720p (1280x720) @ 15 fps',
    label: getStreamTypeLabel('720p', '15'),
    key: `${R720p_VIEWPORT}/15.0`,
  },

  R1080p_1: {
    viewport: R1080p_VIEWPORT,
    fps: 1.0,

    name: 'HD 1080p (1920x1080) @ 1 fps',
    label: getStreamTypeLabel('1080p', '1'),
    key: `${R1080p_VIEWPORT}/1.0`,
  },

  R1080p_15: {
    viewport: R1080p_VIEWPORT,
    fps: 15.0,

    name: 'HD 1080p (1920x1080) @ 15 fps',
    label: getStreamTypeLabel('1080p', '15'),
    key: `${R1080p_VIEWPORT}/15.0`,
  },

  R50MP_15: {
    viewport: R50MP_VIEWPORT,
    fps: 15.0,

    name: 'HD 5MP (2592x1944) @ 15 fps',
    label: getStreamTypeLabel('5MP', '15'),
    key: `${R50MP_VIEWPORT}/15.0`,
  },

  R53MP_15: {
    viewport: R53MP_VIEWPORT,
    fps: 15.0,

    name: 'HD 5.3MP (3072x1728) @ 15 fps',
    label: getStreamTypeLabel('5.3MP', '15'),
    key: `${R53MP_VIEWPORT}/15.0`,
  },

  R50MP_20: {
    viewport: R50MP_VIEWPORT,
    fps: 20.0,

    name: 'HD 5MP (2592x1944) @ 20 fps',
    label: getStreamTypeLabel('5MP', '20'),
    key: `${R50MP_VIEWPORT}/20.0`,
  },

  R53MP_20: {
    viewport: R53MP_VIEWPORT,
    fps: 20.0,

    name: 'HD 5.3MP (3072x1728) @ 20 fps',
    label: getStreamTypeLabel('5.3MP', '20'),
    key: `${R53MP_VIEWPORT}/20.0`,
  },

  R4k_1: {
    viewport: R4k_VIEWPORT,
    fps: 1.0,

    name: '4k (3840x2160) @ 1 fps',
    label: getStreamTypeLabel('4k', '1'),
    key: `${R4k_VIEWPORT}/1.0`,
  },

  R4k_15: {
    viewport: R4k_VIEWPORT,
    fps: 15.0,

    name: '4k (3840x2160) @ 15 fps',
    label: getStreamTypeLabel('4k', '15'),
    key: `${R4k_VIEWPORT}/15.0`,
  },

  DEFAULT: {
    viewport: DEFAULT_VIEWPORT,
    fps: -1,

    name: 'Default',
    label: getStreamTypeLabel('Default', ''),
    key: `${DEFAULT_VIEWPORT}/-1.0`,
  },
};

export const getStreamTypeFromKey = (key) => _.find(STREAM_TYPES, ['key', key]);

// standard video tag params, since most of the time
// we want the videos to play inline with our own custom
// controls to manipulate the video
export const VIDEO_TAG_PARAMS = {
  // so video stays within the player
  playsInline: true,
  // don't take video outside the player
  disablePictureInPicture: true,
  // controls to show on chrome
  controlsList: 'nodownload',
  // native browser conrols
  controls: false,
};

const __ = deepdash(_);
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path: any) => reg.test(path);
export const getPageQuery = () => parse(window.location.href.split('?')[1]);
export const getURLParams = () => parse(window.location.href.split('#')[1]);

const emailRE = /^[^\s]+@[^\s]+\.[^\s]+$/;
export const isEmail = (email) => emailRE.test(email);

export const phoneRE = /^\+[\d]+$/;
export const isPhone = (phone) => phoneRE.test(phone);

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export type AddConfigProfile = {
  entity_type: string;
  entity_id: string;
  profile_type: string;
  profile_name: string;
};

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

/**
 * @param  {object} prev: previous to compare
 * @param  {object} current: current to compare
 * @return {void}
 */
export const logUpdatedDiff = (prev, current) => {
  const now = Object.entries(current);
  const added = now.filter(([key, val]) => {
    if (prev[key] === undefined) return true;
    if (prev[key] !== val) {
      console.log(
        `${key}
        %c- ${JSON.stringify(prev[key], getCircularReplacer())}
        %c+ ${JSON.stringify(val, getCircularReplacer())}`,
        'color:red;',
        'color:green;',
      );
    }
    return false;
  });
  added.forEach(([key, val]) =>
    console.log(
      `${key}
        %c+ ${JSON.stringify(val, getCircularReplacer())}`,
      'color:green;',
    ),
  );
};

export const isiOsSafari = () => {
  let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  return isSafari && iOS;
};

export const displayTZ = (timezone) =>
  timezone ? moment().tz(timezone).format('z') : '';

export const getFlexDate = (
  date,
  hrs = false,
  secs = false,
  msecs = false,
  useTimezone,
) => {
  if (!date) {
    return '';
  }

  const momentDate =
    date instanceof moment ? date : moment.tz(date, useTimezone);
  const now = new Date();

  let str = '';

  if (
    !(
      momentDate.isSame(now, 'day') &&
      momentDate.isSame(now, 'month') &&
      momentDate.isSame(now, 'year')
    )
  ) {
    str += momentDate.format('DD MMM ');
  }
  if (!momentDate.isSame(now, 'year')) {
    str += momentDate.format("'YY ");
  }
  if (hrs) {
    str += momentDate.format('HH:mm');
    if (secs) {
      str += momentDate.format(':ss');
      if (msecs) {
        str += momentDate.format('.SSS');
      }
    }
  }
  if (useTimezone) {
    str += ` ${momentDate.zoneAbbr()}`;
  }

  return str.trim();
};

export const getFlexibleDateFormat = (
  date,
  secs = false,
  msecs = false,
  useTimezone = false,
) => getFlexDate(date, true, secs, msecs, useTimezone);

export const isUnixTimestampSecs = (date) => {
  return (
    Number.isFinite(date) && date < Date.now() / 1000 + 60 * 60 * 24 * 365 * 10
  );
};

// useful function for showing dates in a user-friendly way
export const dd = (date) => {
  // guess if this is in secs or msecs
  if (isUnixTimestampSecs(date)) {
    date = date * 1000;
  }

  return getFlexibleDateFormat(date, true, true, 'UTC');
};

export const isInternalUser = (currentUser) => {
  const userEmail = currentUser.Email;
  return (
    _.endsWith(userEmail, 'dragonfruit.ai') ||
    cookie.load('use_admin_mode') === 'true'
  );
};

export const getAssigneeData = (
  users,
  currentUser,
  includeUnassigned = false,
) => {
  let assignees = users
    .filter((x) => {
      if (
        !isInternalUser(currentUser) &&
        _.endsWith(x.email, 'dragonfruit.ai')
      ) {
        return false;
      }
      let role =
        _.get(_.intersection(x.Roles, Object.keys(ROLE_INFO)), '0') ||
        DEFAULT_ROLE;

      if (!role || INTERNAL_ROLES.indexOf(role) !== -1) {
        return false;
      }
      return true;
    })
    .sort((a, b) =>
      `${a.User.FirstName}/${a.User.LastName}`.localeCompare(
        `${b.User.FirstName}/${b.User.LastName}`,
      ),
    )
    .map((x) => ({
      label: `${x.User.FirstName} ${x.User.LastName}`,
      key: x.User.UserID,
      value: x.User.UserID,
    }));

  if (includeUnassigned) {
    assignees.unshift({
      label: 'Unassigned',
      key: 'none',
      value: 'none',
    });
  }

  return assignees;
};

export const getLabelPosition = (axisLength, labelWidth, left) => {
  let labelLef = -labelWidth / 2;
  // make sure we can see the whole label
  if (left + labelWidth / 2 > axisLength) {
    labelLef = -(labelWidth - (axisLength - left));
  }
  if (left < labelWidth / 2) {
    labelLef = -left;
  }
  return labelLef;
};

export const isFullScreen = () => {
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    return false;
  }
  return true;
};

export const toggleFullScreen = (elem = document.documentElement) => {
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    } else {
      console.error('requestFullscreen is not supported by this browser');
      notification.open({
        message: `Fullscreen mode is not supported by this browser`,
        className: 'df-notification',
        placement: 'bottomRight',
      });
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else {
      console.error('exitFullscreen is not supported by this browser');
    }
  }
};

export const registerFullScreenExitHandler = (exitHandler) => {
  document.addEventListener('fullscreenchange', exitHandler);
  document.addEventListener('webkitfullscreenchange', exitHandler);
  document.addEventListener('mozfullscreenchange', exitHandler);
  document.addEventListener('MSFullscreenChange', exitHandler);
};

export const deregisterFullScreenExitHandler = (exitHandler) => {
  document.removeEventListener('fullscreenchange', exitHandler);
  document.removeEventListener('webkitfullscreenchange', exitHandler);
  document.removeEventListener('mozfullscreenchange', exitHandler);
  document.removeEventListener('MSFullscreenChange', exitHandler);
};

type URL_TO_TYPE =
  | 'LOCATION'
  | 'CHANNEL-GROUP'
  | 'CHANNEL'
  | 'INVESTIGATION'
  | 'LOCATION-CONFIGURE-CAMERA';

type URL_TO_ID_TYPE = {
  locID?: number;
  chGrpID?: number;
  chID?: number;
  investID?: number;
};
export const urlTo = (
  type: URL_TO_TYPE,
  { locID, chGrpID, chID, investID }: URL_TO_ID_TYPE,
) => {
  let url = null;
  switch (type) {
    case 'LOCATION':
      if (locID) {
        url = `/locations/${locID}`;
      }
      break;
    case 'LOCATION-CONFIGURE-CAMERA':
      if (locID) {
        url = `/locations/${locID}/configure-cameras`;
      }
      break;
    case 'CHANNEL-GROUP':
      if (locID && chGrpID) {
        url = `/locations/${locID}/channel-groups/${chGrpID}`;
      }
      break;
    case 'CHANNEL':
      if (locID && chID) {
        url = `/locations/${locID}/channels/${chID}`;
      }
      break;
    case 'INVESTIGATION':
      if (investID) {
        url = `/investigations/${investID}`;
      }
      break;
  }
  return url;
};

export const linkTo = (
  type: URL_TO_TYPE,
  ids: URL_TO_ID_TYPE,
  name: string,
) => {
  const url = urlTo(type, ids);
  if (url && name) {
    return <Link to={url}>{name}</Link>;
  }
  if (name) {
    return name;
  }
  return null;
};

export const getMailtoLink = (email, subject, body) => {
  let url = `mailto:${email}`;
  if (subject || body) {
    url += '?';
  }
  if (subject) {
    url += `subject=${subject}&`;
  }
  if (body) {
    url += `body=${body}&`;
  }
  return url;
};

export const dispatchWithFeedback = (
  dispatch: (_obj: any) => Promise<any>,
  msgPrefix: string,
  payload: any,
  failureOnly: boolean = false,
  customReducer: Function | null = null,
) => {
  return dispatch({ ...payload, customReducer }).then((ret) => {
    let text = 'succeeded.';
    let succeeded = true;
    if (!ret || !ret.success) {
      succeeded = false;
      const message = _.get(ret, 'data.message');
      text = 'failed';
      if (message) {
        text += ` with error: ${message}`;
      }
      const type = _.get(ret, 'data.type');
      if (type === 'PERMISSION_DENIED') {
        history.push('/home');
      }
    }
    if (!succeeded || !failureOnly) {
      notification.open({
        message: `${msgPrefix} ${text}`,
        className: 'df-notification',
        placement: 'bottomRight',
      });
    }
    if (!succeeded) {
      return null;
    }
    return ret?.data;
  });
};

export const getCurrentCustomerID = () => {
  const customerID = localStorage.getItem('currentCustomerID');
  return !customerID ? -1 : +customerID;
};

export const isUserAdmin = (currentUser: any) => {
  if (!currentUser) return false;
  const currentUserRoles = _.get(
    currentUser,
    `Customers[${getCurrentCustomerID()}].Roles`,
    [],
  );
  return currentUserRoles.includes('ADMIN_USER');
};

export const getIdToken = async () => {
  // Get new token if exired
  // Update localstorage
  let tokens = JSON.parse(localStorage.getItem('tokens'));
  if (tokens && tokens.ExpiresAt - new Date().getTime() < 300000) {
    const response = await getNewTokens({ refreshToken: tokens.RefreshToken });
    tokens = { ...tokens, ...response.data };
    tokens.ExpiresAt = new Date().getTime() + tokens.ExpiresIn * 1000;
    localStorage.setItem('tokens', JSON.stringify(tokens));
  }
  return Promise.resolve(tokens && tokens.IdToken);
};

export const getAccessToken = async () => {
  // Get new token if exired
  // Update localstorage
  let tokens = JSON.parse(localStorage.getItem('tokens'));
  if (tokens && tokens.ExpiresAt - new Date().getTime() < 300000) {
    const response = await getNewTokens({ refreshToken: tokens.RefreshToken });
    tokens = { ...tokens, ...response.data };
    tokens.ExpiresAt = new Date().getTime() + tokens.ExpiresIn * 1000;
    localStorage.setItem('tokens', JSON.stringify(tokens));
  }
  return Promise.resolve(tokens && tokens.AccessToken);
};

export const getRefreshToken = async () => {
  // Get new token if exired
  // Update localstorage
  let tokens = JSON.parse(localStorage.getItem('tokens'));
  if (tokens && tokens.ExpiresAt - new Date().getTime() < 300000) {
    const response = await getNewTokens({ refreshToken: tokens.RefreshToken });
    tokens = { ...tokens, ...response.data };
    tokens.ExpiresAt = new Date().getTime() + tokens.ExpiresIn * 1000;
    localStorage.setItem('tokens', JSON.stringify(tokens));
  }
  return Promise.resolve(tokens && tokens.RefreshToken);
};

export const getFlattendLocationChannels = (location: any) => {
  let flatChannels = _.flatMapDeep(location.ChannelGroups, (value) =>
    value.Channels.map((channel: any) => {
      channel.ChannelGroup = _.omit(value, 'Channels', 'ChannelGroups');
      return channel;
    }),
  );
  flatChannels = flatChannels.concat(location.Channels);
  return flatChannels;
};

const findChInChGrp = (_channelID: any, chGrp: any) => {
  const channelID = parseInt(_channelID);
  let channelPath = [];
  let channelFound;
  for (let j = 0; j < chGrp.length; j += 1) {
    channelPath = [chGrp[j]];
    const channel = chGrp[j].Channels.find(
      (c: any) => c.ChannelID === channelID,
    );
    if (channel !== undefined) {
      channelFound = true;
      channelPath.push(channel);
      break;
    } else {
      const res = findChInChGrp(channelID, chGrp[j].ChannelGroups);
      if (res.channelFound) {
        channelFound = true;
        channelPath = channelPath.concat(res.channelPath);
        break;
      } else {
        channelPath = [];
      }
    }
  }
  return { channelPath, channelFound };
};

export const findChannelInLoc = (_channelID: any, location: any) => {
  const channelID = parseInt(_channelID);
  let channelPath = [location];
  const channel = location.Channels.find(
    (ch: any) => ch.ChannelID === channelID,
  );
  if (channel !== undefined) {
    channelPath.push(channel);
  } else {
    const res = findChInChGrp(channelID, location.ChannelGroups);
    if (res.channelFound) {
      channelPath = channelPath.concat(res.channelPath);
    }
  }
  return channelPath;
};

export const findChannelInLocations = (channelID: any, locations: any) => {
  if (!channelID) {
    return null;
  }
  channelID = parseInt(channelID);
  let channelPath = [];
  for (let i = 0; i < locations.length; i += 1) {
    channelPath = findChannelInLoc(channelID, locations[i]);
    if (channelPath.length > 1) {
      return channelPath;
      break;
    }
  }
  return null;
};

export const findLocationForChannel = (channelID: any, allLocations: any) => {
  let channelPath = findChannelInLocations(channelID, allLocations);
  return _.get(channelPath, 0);
};

export const getChannelObjectForChannel = (channelID, allLocations) => {
  let channelPath = findChannelInLocations(channelID, allLocations);
  return _.last(channelPath);
};

export const getInsight = (insightID, insights) => {
  if (!insightID) return null;
  let insight = _.find(insights.Insights, ['InsightID', insightID]);
  for (let i = 0; i < insights.InsightGroups.length; i += 1) {
    if (insight === undefined) {
      insight = getInsight(insightID, insights.InsightGroups[i]);
    }
  }
  return insight;
};

export const getInsightGroup = (insightGroupID, insights) => {
  if (!insightGroupID) return null;
  insightGroupID = parseInt(insightGroupID);
  let insightGroup;
  for (let i = 0; i < insights.InsightGroups.length; i += 1) {
    if (insights.InsightGroups[i].InsightGroupID === insightGroupID) {
      insightGroup = insights.InsightGroups[i];
    }
    if (insightGroup === undefined) {
      insightGroup = getInsightGroup(insightGroupID, insights.InsightGroups[i]);
    }
  }
  return insightGroup;
};

// export const getInsightOptions = (insights, heatmapNotSupported = false) => {
//   let options = [];
//   _.get(insights, 'Insights', []).forEach((i) => {
//     if (heatmapNotSupported && isHeatmap(i)) {
//       options.push({
//         value: i.InsightID,
//         label: i.Name + ' (Heat map not supported here)',
//         disabled: true,
//       });
//     } else {
//       options.push({
//         value: i.InsightID,
//         label: i.Name,
//       });
//     }
//   });
//   _.get(insights, 'InsightGroups', []).forEach((ig) => {
//     if (ig.Insights.length > 0 || ig.InsightGroups.length > 0) {
//       options.push({
//         value: ig.InsightGroupID,
//         label: ig.Name,
//         children: getInsightOptions(ig, heatmapNotSupported),
//       });
//     }
//   });
//   return options;
// };

export const getChannelLink = (allLocations, channelID) => {
  let channelPath = findChannelInLocations(channelID, allLocations);
  if (!channelPath) {
    return { channelObj: {}, link: '/locations' };
  }
  let channelObj = _.last(channelPath);
  let link = null;
  if (channelObj) {
    link = `/locations/${channelPath[0].ProjectID}/channels/${channelObj.ChannelID}`;
  }

  return { channelObj, link };
};

export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  ((p) => p.toString() === '[object SafariRemoteNotification]')(
    !window.safari ||
      (typeof safari !== 'undefined' && safari.pushNotification),
  );

/* ELASTIC TIMESTAMPS - an explanation

  Elastic stores timestamps for video start times in
  ESVideoStartTime and ESVideoEndTime fields. However, these
  timestamps, thought they look like unix timestamps, don't
  represent a specific unixtime corresponding to the start/end of
  videos or events.

  Instead, these are numeric values for that instance in time, if
  it was occurring in UTC.

  For example, say a video starts at 10:14:16 on Jan 24 '22 in New
  York. The ESVideoStartTime in this case would be 1643019256000.
  This corresponds to "Mon Jan 24 2022 10:14:16 GMT+0000". Note
  that this is _incorrect_ as a momentjs unixtime, since the
  instant in time _did not_ happen at the unixtime that's stored
  (i.e. in UTC), we just stored it as a number that happens to
  look like that.

  But, if you want to interpret this as a moment, you need to
  first:

  - treat it as a moment() that happend in UTC
  - force add New York timezone to the moment()

  At this point, if you inspect the moment() for the unixtime, it
  will be _different_ from where it started, since in the second
  step it has 'fixed up' the moment baseline such that the
  unixtime is adjusted to correspond to the time in UTC when it
  was 10:14:16 in New York.

  Note that this is why it is a big mistake to interpret the
  ESVideo*Times with a moment.tz(ESVideoStartTime, timezone) -
  since that assumes that the ESVideo*Time was _in_ UTC, and we're
  merely figuring out what time it would be _in_ said timezone.
  You'll be wrong by 2 * timezone offset if you did so.
*/

// given a moment, it will give the timestamp for the same time,
// format DD MM YYYY, for that time zone in unix
export const getUnixBasedOnTimeZone = (
  moment_obj: moment.Moment,
  ch_Timezone: string,
): number => {
  const tz_moment: moment.Moment = moment_obj.tz(ch_Timezone);
  return (tz_moment.unix() + tz_moment.utcOffset() * 60) * 1000;
};

export const getActualTimeFromESTime = (esTime, timezone) => {
  // we can't simply use moment timezone math, because when the daylight
  // saving time comes into play, this will give wrong results
  // e.g. if the time is 3AM (after DST has changed), the 3AM in UTC
  // actually corresponds to a completely different time. instead we
  // have to do some contortions here to do this right

  // support both unixtime (secs) or milliseconds
  if (isUnixTimestampSecs(esTime)) {
    esTime = esTime * 1000;
  }

  // first, what would the string look like in UTC? note that the esTime
  // passed could be a (fake) unixtime or a string
  let utcBaseline = moment.utc(esTime);
  let utcString = utcBaseline.format();

  // now we need to pretend that this utcString is actually the time string
  // _in_ that timezone, so remove the 'Z' that indicates it's UTC
  let tzString = utcString.replace('Z', '');

  // now, read that string as if it was in that timezone in the first place
  return moment.tz(tzString, timezone);
};

// if we get a time that's the actual UTC time, but a struct (e.g. event)
// requires a unixtime-looking number that corresponds to the local time
export const convertActualTimeToESTime = (utcTime, timezone = 'UTC') => {
  // first convert to a string that just has the time
  let str = moment.tz(utcTime, 'UTC').tz(timezone).format(DATETIME_FORMAT);
  // now we convert this using our ES function
  return getActualTimeFromESTime(str, 'UTC');
};

export const getTimeInEventFormatMsecs = (utcTime, timezone) => {
  return convertActualTimeToESTime(utcTime, timezone).valueOf();
};

/* ESVideoStartTime vs VideoStartTime

  Sometimes we get video data directly from mediadb, in which case we
  do not get ESVideoStartTime. the VideoStartTime gets munged to be the
  correct value.

  However, search results come with ESVideoStartTime, which should be
  used in perference since in these cases the VideoStartTime isn't munged
  (for some reason) and reflects the original times (I think)
 */

export const getActualVideoStartSecs = (m, timezone) => {
  // see ELASTIC explanation above
  // see ESVideoStartTime explanation above.
  return (
    getActualTimeFromESTime(
      m.ESVideoStartTime || m.VideoStartTime,
      timezone,
    ).valueOf() / 1000
  );
};

export const getLocalVideoStartSecs = (m) => {
  return moment(m.ESVideoStartTime || m.VideoStartTime).valueOf() / 1000;
};

export const getActualVideoEndSecs = (m, timezone) => {
  // see ELASTIC explanation above

  // see ESVideoStartTime explanation above.
  if (m.ESVideoEndTime !== undefined) {
    let endObj = getActualTimeFromESTime(m.ESVideoEndTime, timezone);
    return endObj.valueOf() / 1000;
  }

  if (m.ESVideoStartTime !== undefined) {
    let obj = getActualTimeFromESTime(m.ESVideoStartTime, timezone);
    return obj.add(_.get(m, 'VideoDurationMsec', 600), 'ms').valueOf() / 1000;
  }

  if (m.VideoEndTime !== undefined) {
    let endObj = getActualTimeFromESTime(m.VideoEndTime, timezone);
    return endObj.valueOf() / 1000;
  }

  let obj = getActualTimeFromESTime(m.VideoStartTime, timezone);

  return obj.add(_.get(m, 'VideoDurationMsec', 600), 'ms').valueOf() / 1000;
};

export const getLocalVideoEndSecs = (m, timezone) => {
  // this is a bit convoluted - we _could_ parse using the actual m fields
  // above, but we're taking an easier way out
  return (
    convertActualTimeToESTime(
      getActualVideoEndSecs(m, timezone) * 1000,
      timezone,
    ).valueOf() / 1000
  );
};

export const getClipStartTime = (clip: any, timezone = 'UTC') => {
  if (!clip.ESVideoStartTime) {
    return null;
  }

  // see ELASTIC explanation above
  const start_offset = _.get(clip, 'start', 0);

  return getActualTimeFromESTime(clip.ESVideoStartTime, timezone).add(
    start_offset,
    'seconds',
  );
};

export const getClipEndTime = (clip: any, timezone = 'UTC') => {
  if (!clip.ESVideoStartTime && !clip.ESVideoEndTime) {
    return null;
  }

  // see ELASTIC explanation above
  const end_offset = _.get(clip, 'end', 0);

  if (end_offset) {
    return getActualTimeFromESTime(clip.ESVideoStartTime, timezone).add(
      end_offset,
      'seconds',
    );
  } else if (clip.ESVideoEndTime) {
    return getActualTimeFromESTime(clip.ESVideoEndTime, timezone);
  }

  return null;
};

export const seconds_to_formatedTime = (sec: number): string => {
  if (!sec) {
    return '-';
  }
  sec = Number(sec);
  const d = Math.floor(sec / 86400);
  const h = Math.floor((sec % 86400) / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = Math.floor((sec % 3600) % 60);

  const dDisplay = d > 0 ? `${d}d` : '';
  const hDisplay = h > 0 ? ` ${h}h` : '';
  const mDisplay = m > 0 ? ` ${m}m` : '';
  const sDisplay = s > 0 ? ` ${s}s` : '';

  return (dDisplay + hDisplay + mDisplay + sDisplay).trim();
};

function filterColorKeys(keys: any, prefix: string) {
  const prefixStr = `${prefix}_`;
  const countStr = `${prefix}_count`;
  return keys
    .filter((k) => {
      const isPrefixed = k.indexOf(prefixStr) !== -1;
      return isPrefixed && k !== countStr;
    })
    .map((k) => {
      return k.trim().replace(prefixStr, '').split('_');
    })
    .reduce((curr, acc) => {
      return acc.concat(curr);
    }, []);
}

// TODO: update the types here
export type ClipData = {
  clip: any;
  thumbnails: any[];
  specialImages: any[];
  timezone?: any;
  score_str?: string;
  label_confidence?: number;
  location?: any;
  location_obj?: any;
  channel_group?: any;
  channel_group_obj?: any;
  channel?: any;
  channel_obj?: any;
  primary_thumbnails?: any;
  secondary_thumbnails?: any;
  preview?: any;
  info_title?: string;
  from?: any;
  to?: any;
  elapsed_time?: any;
  elapsed_time_str?: any;
  object_type?: any;
  object_type_str?: any;
  person_details?: any;
  vehicle_details?: any;
  events?: any;
};

type LocationObj = {
  ID: number;
  Name: string;
  Timezone?: string;
  ProjectID?: number;
};

type ChannelObj = {
  ID: number;
  Name: string;
  ChannelGroupID?: number;
  ProjectID?: number;
  Timezone?: string;
};

type ChannelGroupObj = {
  ID: number;
  Name: string;
};

type ChannelInfoResult = {
  location?: string;
  location_obj?: LocationObj;
  channel_group?: string;
  channel_group_obj?: ChannelGroupObj;
  channel?: string;
  channel_obj?: ChannelObj;
  timezone?: string;
};

export const getChannelInfo = (
  channelID: number | string,
  locations,
): ChannelInfoResult => {
  let result: ChannelInfoResult = {};
  channelID = +channelID;

  const channel_obj: ChannelObj | null = _.get(
    locations,
    `ch.byId[${channelID}]`,
    null,
  );
  const channel_name = channel_obj && channel_obj.Name;

  const channel_group_obj: ChannelGroupObj | null =
    channel_obj &&
    _.get(locations, `ch_grp.byId[${channel_obj.ChannelGroupID}]`);
  const channel_group_name = channel_group_obj && channel_group_obj.Name;

  const location_obj: LocationObj | null =
    channel_obj && _.get(locations, `loc.byId[${channel_obj.ProjectID}]`);
  const location_name = location_obj && location_obj.Name;

  if (location_name) {
    result.location = location_name;
    result.location_obj = location_obj;
  }
  if (channel_group_name) {
    result.channel_group = channel_group_name;
    result.channel_group_obj = channel_group_obj;
  }
  if (channel_name) {
    result.channel = channel_name;
    result.channel_obj = channel_obj;
  }
  if (channel_obj) {
    result.timezone = channel_obj.Timezone || location_obj?.Timezone;
  }

  return result;
};

export const interpretClipData = (
  clip_obj: any,
  locations: any,
  use_timezone?: any,
  searchResults?: any,
): ClipData => {
  let timezone = use_timezone;

  let thumbnail_info: ClipData = {
    clip: clip_obj,
    thumbnails: [],
    specialImages: [],
  };

  if (use_timezone) {
    thumbnail_info.timezone = timezone;
  }

  // this defaults to a score of 100%
  let score = _.get(clip_obj, 'labelConfidence', 1);
  if (score) {
    thumbnail_info.score_str = `${Math.round(score * 100)}%`;
  }

  // this is the actual score and may or may not exist, in the range [0, 1]
  let labelConfidence = _.get(clip_obj, 'labelConfidence', null);
  if (_.isNumber(labelConfidence) && _.isFinite(labelConfidence)) {
    thumbnail_info.label_confidence = labelConfidence;
  }

  let channelID = _.get(clip_obj, 'ChannelID', null);
  if (channelID) {
    thumbnail_info = {
      ...thumbnail_info,
      ...getChannelInfo(channelID, locations),
    };
  }
  const clip_obj_keys = Object.keys(clip_obj);
  const clip_start = getClipStartTime(clip_obj, timezone);
  const clip_end = getClipEndTime(clip_obj, timezone);

  if (_.get(clip_obj, 's3image', null)) {
    thumbnail_info.thumbnails.push(_.get(clip_obj, 's3image', null));
  }
  if (_.get(clip_obj, 'primary_thumbnails.length', 0) > 0) {
    const primary_thumbnails = _.get(clip_obj, 'primary_thumbnails', []);
    thumbnail_info.primary_thumbnails = primary_thumbnails;
  } else if (_.get(thumbnail_info, 'thumbnails.length', 0) > 0) {
    thumbnail_info.primary_thumbnails = thumbnail_info.thumbnails;
  }
  if (_.has(clip_obj, 'secondary_thumbnails')) {
    const _secondary_thumbnail_obj_ = _.get(
      clip_obj,
      'secondary_thumbnails',
      {},
    );
    if (Object.keys(_secondary_thumbnail_obj_).length > 0) {
      const secondary_thumbnails: any = [];
      Object.keys(_secondary_thumbnail_obj_).forEach((key) => {
        if (_.get(_secondary_thumbnail_obj_, `${key}.values.length`, 0) > 0) {
          const _slide_obj = {};
          _slide_obj['label'] = _.get(
            _secondary_thumbnail_obj_,
            `${key}.name`,
            key,
          );
          _slide_obj['list'] = _.get(
            _secondary_thumbnail_obj_,
            `${key}.values`,
            [],
          );
          secondary_thumbnails.push(_slide_obj);
        }
      });
      if (secondary_thumbnails.length > 0) {
        thumbnail_info.secondary_thumbnails = secondary_thumbnails;
      }
    }
  }
  if (_.get(clip_obj, 'ThumbnailStrip', null)) {
    const preview = _.get(clip_obj, 'ThumbnailStrip', null);
    preview['VideoThumbnail'] = _.get(clip_obj, 'Thumbnail.SignedUrl', null);
    thumbnail_info.preview = preview;
  }
  if (clip_start && clip_start instanceof moment) {
    thumbnail_info.info_title = getFlexibleDateFormat(clip_start);
    thumbnail_info.from = clip_start;
  }
  if (clip_end && clip_end instanceof moment) {
    thumbnail_info.to = clip_end;
  }
  if (
    clip_start &&
    clip_start instanceof moment &&
    clip_end &&
    clip_end instanceof moment
  ) {
    thumbnail_info.elapsed_time = clip_end.diff(clip_start, 'seconds');
    thumbnail_info.elapsed_time_str = seconds_to_formatedTime(
      thumbnail_info.elapsed_time,
    );
  }
  if (_.get(clip_obj, 'doc_type', null)) {
    const doc_type = _.get(clip_obj, 'doc_type', null);
    thumbnail_info.object_type = doc_type;
    thumbnail_info.object_type_str =
      doc_type.charAt(0).toUpperCase() + doc_type.slice(1);

    if (doc_type === 'person') {
      const top_colors = filterColorKeys(clip_obj_keys, 'top_color');
      const bottom_colors = filterColorKeys(clip_obj_keys, 'bottom_color');
      thumbnail_info.person_details = {
        ...thumbnail_info.person_details,
        top_colors,
        bottom_colors,
      };
    }
    if (doc_type === 'vehicle') {
      const vehicle_colors = filterColorKeys(clip_obj_keys, 'vehicle_color');
      thumbnail_info.vehicle_details = {
        ...thumbnail_info.vehicle_details,
        vehicle_colors,
      };
    }

    Object.values(filterModulesInfo).forEach((info) => {
      if (_.has(info, 'interpretClipData')) {
        info.interpretClipData(clip_obj, thumbnail_info);
      }
    });
  }

  timezone = timezone || 'UTC';
  if (thumbnail_info.from && thumbnail_info.to) {
    let events;
    if (searchResults) {
      /* eslint-disable */
      events =
        convertSearchClipsIntoSearchEvents(
          searchResults,
          locations,
          null,
          timezone,
        ) || [];
      /* eslint-enable */
    } else {
      events = [
        {
          type: CUE_TYPE.SEARCH_RESULT,
          ChannelID: +channelID,
          // EventStart|End are ES time: local time written in UTC
          EventStart: getTimeInEventFormatMsecs(thumbnail_info.from, timezone),
          EventEnd: getTimeInEventFormatMsecs(thumbnail_info.to, timezone),
        },
      ];
    }
    thumbnail_info.events = events;
  }
  return thumbnail_info;
};

export const populateObjectSearchPayload = (
  clipData: any,
  searchPayload: any,
) => {
  /* eslint-disable prefer-destructuring  */
  searchPayload.Objects = ['timeline.person'];
  searchPayload['thumbnail'] = clipData.s3image.trim().split('?')[0];
  searchPayload['videoID'] = clipData.VideoID;
  /* eslint-enable prefer-destructuring  */
  return searchPayload;
};

export const populateSearch2Payload = (
  currentSearch: any,
  searchPayload: any,
) => {
  // Embeddings search parameters
  const search_object = _.get(currentSearch, 'search_object');
  const embedding_search = _.get(currentSearch, 'embedding_search');
  const is_embeddings_search = search_object && embedding_search;
  if (is_embeddings_search) {
    searchPayload.queryID = '2';
    searchPayload.Objects = [`timeline.${search_object}`];
    searchPayload.EmbeddingSearch = embedding_search;
  }

  // Person Color Filter
  const person_top_color = _.get(currentSearch, 'person_colors.top_colors', []);
  const person_bottom_color = _.get(
    currentSearch,
    'person_colors.bottom_colors',
    [],
  );
  if (person_top_color.length > 0 || person_bottom_color.length > 0) {
    searchPayload.queryID = '2';
    if (!searchPayload.Objects) {
      searchPayload.Objects = [];
    }
    if (!is_embeddings_search) {
      searchPayload.Objects.push('timeline.person');
    }

    if (
      person_top_color.length > 0 &&
      person_top_color.length < personColorOptions.length
    ) {
      searchPayload.person_colors = _.get(searchPayload, 'person_colors', {});
      searchPayload.person_colors.top_colors = person_top_color;
    }
    if (
      person_bottom_color.length > 0 &&
      person_bottom_color.length < personColorOptions.length
    ) {
      searchPayload.person_colors = _.get(searchPayload, 'person_colors', {});
      searchPayload.person_colors.bottom_colors = person_bottom_color;
    }
  }

  // Vehicle Color Filter
  const vehicle_color = _.get(currentSearch, 'vehicle_colors', []);
  if (vehicle_color.length > 0) {
    searchPayload.queryID = '2';
    if (!searchPayload.Objects) {
      searchPayload.Objects = [];
    }
    if (!is_embeddings_search) {
      searchPayload.Objects.push('timeline.vehicle');
    }
    if (vehicle_color.length < carColorOptions.length) {
      searchPayload.vehicle_colors = vehicle_color;
    }
  }

  // Line Filter
  const lineFilters = _.get(currentSearch, 'lineFilters', {});
  if (Object.keys(lineFilters).length > 0) {
    const lines = Object.entries(lineFilters).reduce((acc, [channel, line]) => {
      acc[parseInt(channel)] = line[0];
      return acc;
    }, {});
    searchPayload.queryID = '2';
    searchPayload.lineFilter = {
      channels: Object.keys(lineFilters),
      lines: lines,
    };
  }

  // Region Filter
  const regionFilters = _.get(currentSearch, 'regionFilters', {});
  if (Object.keys(regionFilters).length > 0) {
    const regions = Object.entries(regionFilters).reduce(
      (acc, [channel, region]) => {
        acc[parseInt(channel)] = region[0];
        return acc;
      },
      {},
    );
    searchPayload.queryID = '2';
    searchPayload.regionFilter = {
      channels: Object.keys(regionFilters),
      regions: regions,
    };
  }

  // Path Filter
  const pathFilters = _.get(currentSearch, 'pathFilters', {});
  if (Object.keys(pathFilters).length > 0) {
    const paths = Object.entries(pathFilters).reduce((acc, [channel, path]) => {
      acc[parseInt(channel)] = path[0];
      return acc;
    }, {});
    searchPayload.queryID = '2';
    searchPayload.pathFilter = {
      channels: Object.keys(pathFilters),
      paths: paths,
    };
  }

  Object.values(filterModulesInfo).forEach((info) => {
    info.search2Builder(info.key, currentSearch, searchPayload);
  });

  // if investigationIDs exist, we're doing an investigation search
  const investigationIDs = _.get(currentSearch, 'investigationIDs');
  if (investigationIDs && investigationIDs.length !== 0) {
    searchPayload.queryID = 'event_search_query';
    searchPayload.investigation_ids = investigationIDs;
  }
  return searchPayload;
};

let fe_defaults = {
  //   fe_hls_video_stream_visibility: {
  //     values: {
  //       visibility: 'off',
  //     },
  //   },
};
Object.values(filterModulesInfo).forEach((info) => {
  fe_defaults = {
    ...fe_defaults,
    ...info.feDefaults,
  };
});

export const getCustomerProfileValue = (
  currentUser: any,
  profile_name: string,
) => {
  return _.get(
    currentUser,
    `Customers[${getCurrentCustomerID()}].Customer.ConfigProfiles[${profile_name}].values`,
    {},
  );
};

export const isCustomerProfileEnabled = (
  currentUser: any,
  profile_name: string,
  defaultEnabled: boolean = false,
) => {
  const values = getCustomerProfileValue(currentUser, profile_name);
  // Profile Values Convention:
  // - "enabled": true / false
  // - "strategy": "on" / "off"
  // - "visibility": "on" / "off"
  return !!_.get(values, 'enabled', defaultEnabled);
};

export const getVisibility = (currentCustomer: any, profile_name: string) => {
  const config_key = `fe_${profile_name}_visibility`;
  // const customerID = getCurrentCustomerID();
  let values;
  values = _.get(currentCustomer, `ConfigProfiles[${config_key}].values`);
  if (!values) {
    values = fe_defaults[config_key].values;
  }
  return values.visibility;
};

export const setChannelSearchFormScrollPosition = () => {
  setTimeout(() => {
    const _PageContainerDOM = document.getElementById('page-container');
    const _scrollTop = _PageContainerDOM.scrollTop;
    _PageContainerDOM.scrollTop = _scrollTop + 1;
  }, 100);
};

export const getPath = (obj: any, k: any, v: any) => {
  const _path = __.findPathDeep(
    obj,
    (value, key) => {
      if (key === k && value === v) return true;
      return false;
    },
    {
      pathFormat: 'array',
    },
  );
  if (_path && _path.length) {
    return _path;
  }
  return null;
};

export const resetSearchForm2 = (dispatch: any) => {
  const keys = [
    'channelIDs',
    'person_colors',
    'vehicle_colors',
    'lineFilters',
    'regionFilters',
    'pathFilters',
    'showSearchResults',
    'showObjectSearchResult',
    'searchResults',
    'objectSearchResults',
  ];
  Object.entries(filterModulesInfo).forEach(([mKey]) => {
    keys.push(mKey);
  });

  return dispatch({
    type: 'search2/resetFields',
    payload: { keys },
  });
};

export const getModalWidth = (
  max_width: number = 720,
  min_width: number = 480,
) => {
  const width = window.innerWidth - 40;
  if (width > max_width) {
    return max_width;
  } else if (width < min_width) {
    return min_width;
  } else {
    return width;
  }
};

export const TodaysStartEndMoment = () => ({
  start_time: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  end_time: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
});

// ------ Merge Intervals vvvvvv --------------------
export const mergeIntervals = (
  interval: moment.Moment[][],
): moment.Moment[][] => {
  const mergedIntervals: moment.Moment[][] = [];

  const len = interval.length;
  if (len === 0 || len === 1) {
    return interval;
  }

  const sorted_interval = _.sortBy(interval, [(val) => val[0]]);

  let indexInterval = sorted_interval[0];
  for (let i = 1; i < len; i += 1) {
    if (indexInterval[1] >= sorted_interval[i][0]) {
      indexInterval[0] = moment.min(indexInterval[0], sorted_interval[i][0]);
      indexInterval[1] = moment.max(indexInterval[1], sorted_interval[i][1]);
    } else {
      mergedIntervals.push(indexInterval);
      indexInterval = sorted_interval[i];
    }
  }
  mergedIntervals.push(indexInterval);

  return mergedIntervals;
};
// ------ Merge Intervals ^^^^^^ --------------------

// FAVOURITE FILTER
export const createFavouriteFilterDSL = (search2) => {
  const filter_json: Record<string, any> = {};

  // PERSON
  const person_top_colors = _.get(search2, 'person_colors.top_colors', []);
  const person_bottom_colors = _.get(
    search2,
    'person_colors.bottom_colors',
    [],
  );
  if (person_top_colors.length + person_bottom_colors.length > 0) {
    filter_json['Objects'] = _.get(filter_json, 'Objects', []);
    filter_json['Objects'].push('timeline.person');
    filter_json['person_colors'] = {};
    if (
      person_top_colors.length > 0 &&
      person_top_colors.length < personColorOptions.length
    ) {
      filter_json['person_colors']['top_colors'] = person_top_colors;
    }
    if (
      person_bottom_colors.length > 0 &&
      person_bottom_colors.length < personColorOptions.length
    ) {
      filter_json['person_colors']['bottom_colors'] = person_top_colors;
    }
  }

  // VEHICLE
  const vehicle_colors = _.get(search2, 'vehicle_colors', []);
  if (vehicle_colors.length > 0) {
    filter_json['Objects'] = _.get(filter_json, 'Objects', []);
    filter_json['Objects'].push('timeline.vehicle');
    filter_json['vehicle_colors'] = [];
    if (vehicle_colors.length < carColorOptions.length) {
      filter_json['vehicle_colors'] = vehicle_colors;
    }
  }

  // LINE FILTER
  const lineFilters = _.get(search2, 'lineFilters', {});
  if (Object.keys(lineFilters).length > 0) {
    filter_json['lineFilters'] = lineFilters;
  }

  // REGION FILTER
  const regionFilters = _.get(search2, 'regionFilters', {});
  if (Object.keys(regionFilters).length > 0) {
    filter_json['regionFilters'] = regionFilters;
  }

  // PATH FILTER
  const pathFilters = _.get(search2, 'pathFilters', {});
  if (Object.keys(pathFilters).length > 0) {
    filter_json['pathFilters'] = pathFilters;
  }

  Object.entries(filterModulesInfo).forEach(([_mKey, info]) => {
    info.search2Builder(info.key, search2, filter_json);
  });

  delete filter_json.queryID;
  const filter_dsl = {
    type: '2',
    filter_json,
  };

  return filter_dsl;
};

export const isApplicableFilter = (filterDSL, chID) => {
  if (_.get(filterDSL, 'type') !== '2') {
    return true;
  }
  const channelID = chID && parseInt(chID);

  let isApplicable = true;
  ['lineFilters', 'regionFilters', 'pathFilters'].forEach((type) => {
    const filters = _.get(filterDSL, `filter_json.${type}`);

    if (!_.isEmpty(filters)) {
      // if we have a filter but no channel id specified,
      // the search will not work as expected
      if (
        !channelID ||
        // if we do have a channelID but it's not in the filter
        // the search will not work as expected
        !_.get(filters, channelID)
      ) {
        isApplicable = false;
      }

      // uniqe case :- if channelID is "NO_SPATIAL_FILTER"
      if (chID == 'NO_SPATIAL_FILTER') {
        isApplicable = false;
      }
    }
  });
  return isApplicable;
};

const augmentSearchFormPayload = (objects, payload) => {
  if (
    objects.includes('timeline.person') &&
    payload.person_colors.top_colors.length === 0 &&
    payload.person_colors.bottom_colors.length === 0
  ) {
    payload.person_colors = {
      top_colors: personColorOptions.map((opt) => opt.value),
      bottom_colors: personColorOptions.map((opt) => opt.value),
    };
  }
  if (
    objects.includes('timeline.vehicle') &&
    payload.vehicle_colors.length === 0
  ) {
    payload.vehicle_colors = carColorOptions.map((opt) => opt.value);
  }
};

export const getSearchFormPayloadType1 = (allFilters: any) => {
  const _channelIDs = _.get(allFilters, 'Metadata.ChannelID', []);
  const payload = {
    person_colors: {
      top_colors: _.get(allFilters, 'person_colors.top_colors', []),
      bottom_colors: _.get(allFilters, 'person_colors.bottom_colors', []),
    },
    vehicle_colors: _.get(allFilters, 'vehicle_colors', []),
    lineFilters: {},
    regionFilters: {},
    pathFilters: {},
  };

  augmentSearchFormPayload(_.get(allFilters, 'Objects', []), payload);

  Object.entries(filterModulesInfo).forEach(([mKey, info]) => {
    payload[mKey] = _.get(allFilters, mKey, info.stateDefault);
  });

  const _lineFilters = _.get(allFilters, 'lineFilter.line', null);
  const _regionFilters = _.get(allFilters, 'regionFilter.regions', null);
  const _pathFilters = _.get(allFilters, 'pathFilter', null);

  _channelIDs.forEach((_id: any) => {
    if (_lineFilters) {
      payload.lineFilters[`${_id}`] = [_lineFilters];
    }
    if (_regionFilters) {
      payload.regionFilters[`${_id}`] = _regionFilters;
    }
    if (_pathFilters) {
      payload.pathFilters[`${_id}`] = [_pathFilters];
    }
  });

  return payload;
};

export const getSearchFormPayloadType2 = (filter_json: any) => {
  const payload = {
    person_colors: {
      top_colors: _.get(filter_json, 'person_colors.top_colors', []),
      bottom_colors: _.get(filter_json, 'person_colors.bottom_colors', []),
    },
    vehicle_colors: _.get(filter_json, 'vehicle_colors', []),
    lineFilters: _.get(filter_json, 'lineFilters', {}),
    regionFilters: _.get(filter_json, 'regionFilters', {}),
    pathFilters: _.get(filter_json, 'pathFilters', {}),
  };

  Object.entries(filterModulesInfo).forEach(([mKey, info]) => {
    payload[mKey] = _.get(filter_json, mKey, info.stateDefault);
  });

  augmentSearchFormPayload(_.get(filter_json, 'Objects', []), payload);

  return payload;
};

// NOTE: elasticsearch stores times in 'naive' format - i.e. as a generic
// time, not UTC. however, for legacy reasons, it does have a 'Z' in the end
// which typically means UTC format... but it is _not_ UTC. the channel or
// location timezone is required to interpret that naive format correctly
export const getESFormat = (momentTime: any) => {
  return `${momentTime.format('YYYY-MM-DDTHH:mm:ss.000000')}Z`;
};
export const fromESFormat = (str: any) => {
  return moment.utc(str, 'YYYY-MM-DDTHH:mm:ss.000000Z');
};

export const gd = (o: any, v: any) => _.get(o, v, {});
export const ga = (o: any, v: any) => _.get(o, v, []);

export const doAppOp = (
  appID: any,
  dispatch: any,
  op: any,
  params: any,
  refresh = true,
) => {
  op.requiredParams.forEach((p: any) => {
    if (_.get(params, p) === undefined) {
      throw new Error(`Missing required param ${p} for call ${op.name}`);
    }
  });

  const type = refresh ? 'apps/fetchApp' : 'apps/doAppOp';

  return dispatchWithFeedback(
    dispatch,
    'Operation',
    {
      type,
      appID,
      payload: {
        op: op.name,
        params,
      },
    },
    true,
  );
};

export const getAxisStartAndEnd = (noOfTicks, scaleConfig, axisAnchorDate) => {
  const halfTicks = noOfTicks / 2;
  const currentStart = Math.round(
    axisAnchorDate - scaleConfig.ticks.step * halfTicks,
  );
  const currentEnd = Math.round(
    axisAnchorDate + scaleConfig.ticks.step * halfTicks,
  );
  return { currentStart, currentEnd };
};

export const findAxisScaleThatFits = (
  noOfTicks,
  start,
  end,
  fitWithin = true,
) => {
  let axisScale;
  let axisRange = [start, end];
  let axisAnchorDate = Math.round((start + end) / 2);

  if (!noOfTicks) {
    return {
      axisScale: 'MINUTES',
      axisRange,
      axisAnchorDate,
    };
  }

  // find scale to fit
  const scales = Object.keys(AXIS_SCALES);
  for (let i = 0; i < scales.length; i += 1) {
    const scaleConfig = AXIS_SCALES[scales[i]];

    let { currentStart, currentEnd } = getAxisStartAndEnd(
      noOfTicks,
      scaleConfig,
      axisAnchorDate,
    );

    // does this scale completely encompass the range?
    if (
      _.inRange(start, currentStart, currentEnd + 1) &&
      _.inRange(end, currentStart, currentEnd + 1)
    ) {
      if (fitWithin) {
        // we want to find the biggest scale where the full timeline
        // fits completely. we just found one.
        axisRange = [currentStart, currentEnd];
        axisScale = scales[i];
        break;
      } else {
        // NOTE: in this scenario we're picking up the axisScale
        // from the PREVIOUS iteration.

        // we don't want to fit completely in the timeline. why?
        // say we have a channel history, and we're trying to show an axis scale that
        // covers that history range. if we do, the extremities of the range will
        // show as empty (since the range that we fetched doesn't cover _all_ of the
        // channel history, just the one we fetched). to the user this will look like
        // there's no history beyond the ones they see.
        // so, instead, we should pick the _previous_ range where we did _not_ fit
        // completely, so the customer isn't confused about what they see

        // if we've decided to not show everything, what parts should we show?
        //
        // if we've been provided with an anchor that needs to be visible, we'll
        // reorient accordingly
        //
        // else, we'll keep the end date in view.
        const endDelta =
          end + AXIS_SCALES[axisScale || scales[i]].ticks.step - axisRange[1];
        currentStart = axisRange[0] + endDelta;
        currentEnd = axisRange[1] + endDelta;
        axisAnchorDate += endDelta;
        axisRange = [currentStart, currentEnd];
        break;
      }
    }

    // continue the loop. set these vars since the !fitWithin requires it
    axisScale = scales[i];
    axisRange = [currentStart, currentEnd];
  }

  if (!axisScale) {
    // if we're here, we didn't find a pre-existing scale where the entire range can be displayed
    // just choose a default then.
    axisScale = 'MINUTES';
    const { currentStart, currentEnd } = getAxisStartAndEnd(
      noOfTicks,
      AXIS_SCALES[axisScale],
      axisAnchorDate,
    );
    axisRange = [currentStart, currentEnd];
  }

  return { axisScale, axisRange, axisAnchorDate };
};

export const andify = (a) => {
  return [a.slice(0, -1).join(', '), a.slice(-1)[0]].join(
    a.length < 2 ? '' : ' and ',
  );
};

export const getMainContentWidth = () => {
  // 232 - left sidebar
  // 282 - right sidebar
  // 16 - right/left padding on body
  // 16 pixel scroll bar
  // 5 - right/left padding on search results
  return window.innerWidth - 232 - 282 - 16 * 3 - 5 * 2;
};

export const getSearchResultsHeight = () => {
  // THIS NEEDS TO MATCH:
  // src/components/SearchResults/style.less .ctn
  //
  // 56 - app header
  // 64 - page header
  // 16 - paddings
  // 0.35 - % of height to search results
  // 40 - pagination
  return (window.innerHeight - 56 - 64 - 2 * 16) * 0.4 - 40;
};

// BASE STATION

// https://nathanielpaulus.wordpress.com/2016/09/04/finding-the-true-dimensions-of-an-html5-videos-active-area/
export const getVideoDimensions = (video) => {
  // Ratio of the video's intrisic dimensions
  let videoRatio = video.videoWidth / video.videoHeight;
  // The width and height of the video element
  let width = video.offsetWidth;
  let height = video.offsetHeight;
  // The ratio of the element's width to its height
  let elementRatio = width / height;
  // If the video element is short and wide
  if (elementRatio > videoRatio) {
    width = height * videoRatio;
  } else {
    // It must be tall and thin, or exactly equal to the original ratio
    height = width / videoRatio;
  }

  // console.log(`wxh el/vid ${elementRatio}/${videoRatio} video ${video.videoWidth}x${video.videoHeight} offset ${video.offsetWidth}x${video.offsetHeight} dim ${width}x${height}`);

  return { width, height };
};

// figures out what the rendered size is for images that are added with object-fit CSS
// works in conjunction with getImageDimensions below
const getRenderedSize = (contains, cWidth, cHeight, width, height, pos) => {
  if (!height || !cHeight) {
    return null;
  }

  let oRatio = width / height,
    cRatio = cWidth / cHeight;
  return function () {
    if (contains ? oRatio > cRatio : oRatio < cRatio) {
      this.width = cWidth;
      this.height = cWidth / oRatio;
    } else {
      this.width = cHeight * oRatio;
      this.height = cHeight;
    }
    this.left = (cWidth - this.width) * (pos / 100);
    this.right = this.width + this.left;

    return this;
  }.call({});
};

export const getImageDimensions = (img) => {
  if (!img) {
    return null;
  }
  let pos = window
    .getComputedStyle(img)
    .getPropertyValue('object-position')
    .split(' ');
  return getRenderedSize(
    true,
    img.width,
    img.height,
    img.naturalWidth,
    img.naturalHeight,
    parseInt(pos[0]),
  );
};

// use this like this:
//   componentDidUpdate(prevProps, prevState) {
//     showChangesForRender(this.props, prevProps, this.state, prevState);
//     -OR-
//     showChangesForRender(this.props, prevProps, null, null, true);
//   }

export const showChangesForRender = (
  props,
  prevProps,
  state,
  prevState,
  showChange,
) => {
  Object.entries(props).forEach(([key, val]) => {
    if (prevProps[key] !== val) {
      console.log(`(===) Prop '${key}' changed`);
      if (showChange) {
        console.log(prevProps[key], ' > ', val);
      }
    }
    if (!_.isEqual(prevProps[key], val)) {
      console.log(`(_iE) Prop '${key}' changed`);
      if (showChange) {
        console.log(prevProps[key], ' > ', val);
      }
    }
  });
  if (state) {
    Object.entries(state).forEach(
      ([key, val]) =>
        prevState[key] !== val && console.log(`State '${key}' changed`),
    );
  }
};

export const getPreciseInterval = (low, high) => {
  // cue.interval.high does _not_ include that end point, but since
  // it's in seconds and not in milliseconds, we have to go almost
  // all the way
  let intervalHigh = high + 1 - 0.001;
  return new TIMINGSRC.Interval(low, intervalHigh);
};

// eslint-disable-next-line @typescript-eslint/no-use-before-define
export const convertSearchClipsIntoSearchEvents = (
  searchClips,
  locations,
  setCurrentClipandSeek = () => {},
  useTimezone = 'UTC',
) => {
  return searchClips?.map((clip, clipIndex) => {
    // when we get search results back that are _not_ from elastic, but
    // directly from mediadb as the sequence of videos, the clips do not
    // have the channelid in the right place
    if (!clip.ChannelID) {
      clip.ChannelID = clip.MetadataDF.ChannelID;
    }

    // note that we might not have locations when this method is called
    // through a public endpoint
    const channel = locations ? locations.ch.byId[clip.ChannelID] : null;
    // if we can't get the channel, the timezone will be wrong
    useTimezone = channel?.Timezone || useTimezone;

    // note that there's a circular dependency since interpretClipData
    // calls convertSearchClipsIntoSearchEvents and vice versa, but they
    // are calling different kinds of clips. this needs to be cleaned up
    // at some point...
    let clipData = interpretClipData(clip, locations, useTimezone);
    let fps = clip.Fps || 15;
    let actualVideoStartSecs = getActualVideoStartSecs(
      clip,
      clipData['timezone'],
    );

    // sometimes the inference streams lag the video stream, this can be used
    // to test how far they're off
    let delta = 0; // 1.5;

    let eventStart = clipData['from'].valueOf() - delta * 1000;
    let eventEnd = clipData['to'].valueOf() - delta * 1000;

    let inferenceWidth = clip.InferenceWidth || null;
    let inferenceHeight = clip.InferenceHeight || null;

    // each result is for a unique object
    let objectID = clip.ObjectID || (Math.random() * 1000).toString();

    let bboxes = (clip.bbox || []).map((bbox) => {
      let timestamp = actualVideoStartSecs + bbox.frame_id / fps - delta;
      // console.log(`vs ${dd(actualVideoStartSecs)}/ es ${dd(eventStart)}/ ee ${dd(eventEnd)}/ frame_id ${bbox.frame_id}/ fps ${fps}/ clipfps ${clip.Fps}/ ts ${dd(timestamp)}`, bbox);
      return {
        objectID,
        timestamp,
        bbox: bbox.bbox,
        inferenceWidth,
        inferenceHeight,
        clickHandler:
          setCurrentClipandSeek &&
          (() => setCurrentClipandSeek(clip, clipIndex)),
      };
    });

    return {
      type: CUE_TYPE.SEARCH_RESULT,
      clip,
      clipIndex,
      bboxes,

      Channel: clipData.channel_obj,
      ChannelID: +clip.ChannelID,
      Media: [clip],
      // EventStart|End are ES time: local time written in UTC
      EventStart: getTimeInEventFormatMsecs(eventStart, useTimezone),
      EventEnd: getTimeInEventFormatMsecs(eventEnd, useTimezone),
      Timezone: useTimezone,
    };
  });
};

export const relativeTimezone = (timezone) => {
  if (!timezone) {
    return '';
  }

  let now = moment.utc();
  let offset = moment.tz.zone(timezone).utcOffset(now);
  let myOffset = moment.tz.zone(moment.tz.guess()).utcOffset(now);
  let diff = myOffset - offset;

  if (!diff) {
    return '';
  }
  return `${diff > 0 ? '+' : '-'}${seconds_to_formatedTime(diff * 60)}`;
};

export const isEqualWithoutFunctions = (o1, o2) =>
  _.isEqualWith(o1, o2, (a, b) =>
    typeof a === 'function' || typeof b === 'function' ? true : undefined,
  );

export const getMapChannelMedia = (medias: any[]) => {
  const mapChannelMedia: Record<number, any[]> = {};
  if (Array.isArray(medias) && medias.length > 0) {
    medias.forEach((m) => {
      const chID = _.get(m, 'ChannelID', null);
      const mID = _.get(m, 'id', null);
      if (chID && mID) {
        if (!(chID in mapChannelMedia)) {
          mapChannelMedia[chID] = [];
        }
        mapChannelMedia[chID].push(m);
      }
    });
  }

  return mapChannelMedia;
};

export const sortObjectFunction = (key) => (a, b) => {
  if (_.get(a, [key], null) < _.get(b, [key], null)) {
    return -1;
  }
  if (_.get(a, [key], null) > _.get(b, [key], null)) {
    return 1;
  }
  return 0;
};

export const tableSorterFunction = (key) => (a, b) => {
  if (_.get(a, key, null) < _.get(b, key, null)) {
    return -1;
  }
  if (_.get(a, key, null) > _.get(b, key, null)) {
    return 1;
  }
  return 0;
};

export const createTableFilterfromSet = (setData: Set<number | string>) =>
  Array.from(setData).map((sd) => ({
    text: `${sd}`,
    value: `${sd}`,
  }));

export const createTableFilterfromObjects = (objects: Object[], key = []) =>
  objects.map((obj) => ({
    text: _.get(obj, key, null),
    value: _.get(obj, key, null),
  }));

export const tableOnFilter = (key) => {
  return (value, record) => {
    const text = _.get(record, [key], '');
    const found = `${text}`.indexOf(value) == 0;
    return found;
  };
};

export const booleanTableFilterFunction = (key) => {
  return (value, record) => {
    if (Array.isArray(record[key])) {
      return !!record[key].length === value;
    }
    return !!record[key] === value;
  };
};

export const calculateHours = (total_seconds: number) => {
  const hours = Math.floor(total_seconds / 3600);
  const minutes = Math.floor((total_seconds - hours * 3600) / 60);
  const seconds = Math.floor(total_seconds - hours * 3600 - minutes * 60);

  const timeString =
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0');
  return timeString;
};

export function convertStringToEnum<T extends Record<string, string>>(
  enumObject: T,
  input: string,
): T[keyof T] | undefined {
  const enumKeys = Object.keys(enumObject) as T[];

  for (const key of enumKeys) {
    if (enumObject[key] === input) {
      return enumObject[key];
    }
  }

  return undefined;
}

export function downloadCSV(csvData: any, filename: string) {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}

export const getColumnSearchProps = (
  dataIndex: string,
  handleSearch: (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => void,
  placeholder: string = '',
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        placeholder={placeholder}
        value={`${selectedKeys[0] || ''}`}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys as string[], confirm)}
          size="small"
          style={{ width: 90 }}>
          Search
        </Button>
        <Button
          onClick={() => clearFilters && clearFilters()}
          size="small"
          style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
          }}>
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}>
          Close
        </Button>
      </Space>
    </div>
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()),
});

export const getHumanizedTimeDiffString = (
  timestamp1: number | moment.Moment,
  timestamp2: number | moment.Moment,
) => {
  const moment1 =
    typeof timestamp1 === 'number' ? moment.unix(timestamp1) : timestamp1;
  const moment2 =
    typeof timestamp2 === 'number' ? moment.unix(timestamp2) : timestamp2;

  const diffMilliseconds = Math.abs(moment1.diff(moment2));
  const duration = moment.duration(diffMilliseconds);

  return duration.humanize();
};

/**
 *
 * @param pathname
 * @returns
 * This function extracts the appID from the pathname if the pathname pattern is /apps/{appID}. Returns null if the pattern does not match.
 */
export const extractAppIDFromPathname = (pathname: string) => {
  const regex = /^\/apps\/(\d+)/;
  const match = pathname.match(regex);
  if (match) {
    return parseInt(match[1]);
  }
  return null;
};

export const selectFilterFunction = (input: string, option: any) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
