import LicenseStatus from '@/components/LicenseStatus';
import {
  getInfoForLicensableCategory,
  updateConfigAndLicenseForEntity,
} from '@/utils/licenses';
import { Button, Tooltip } from 'antd';
import { Component } from 'react';
import { connect } from 'umi';
import styles from './style.less';

@connect(({ accounts, loading }) => ({
  accounts,
  loadingAddConfigProfile: loading.effects['accounts/addConfigProfile'],
  loadingFetchLicenseInfo: loading.effects['accounts/fetchLicenseInfo'],
}))
class FormItemDisabler extends Component {
  render() {
    let { title, disabler, tag, licenseMode, channelID, refresh, accounts } =
      this.props;

    title = title || 'No Licenses Available';

    if (tag) {
      let info = getInfoForLicensableCategory(accounts, tag);
      let hasAvailableLicenses = info.availableLicenses;

      if (channelID || info?.category?.entityType === 'Customer') {
        title = (
          <div style={{ color: 'black' }}>
            {hasAvailableLicenses ? (
              <div className={styles.ctn}>
                <div style={{ paddingBottom: '16px' }}>
                  You have {hasAvailableLicenses} license
                  {hasAvailableLicenses === 1 ? '' : 's'} left for{' '}
                  <strong>{info.category.name}</strong>.
                </div>
                <Button
                  loading={
                    this.props.loadingAddConfigProfile ||
                    this.props.loadingFetchLicenseInfo
                  }
                  onClick={() =>
                    updateConfigAndLicenseForEntity(
                      licenseMode,
                      licenseMode,
                      tag,
                      channelID,
                      this.props,
                    ).then(() => refresh())
                  }
                  type="primary">
                  Activate License
                </Button>
              </div>
            ) : (
              <LicenseStatus channelID={channelID} tag={tag} />
            )}
          </div>
        );
      }
    }

    return (
      <>
        {disabler ? (
          <Tooltip placement="top" title={title}>
            <div
              style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                zIndex: '31',
                backgroundColor: 'rgba( 255, 255, 255, 0.5)',
                borderRadius: '8px',
              }}></div>
          </Tooltip>
        ) : null}
      </>
    );
  }
}

export default FormItemDisabler;
