import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';
import InvestigationGroupSelectionCascader from '../../investigation-group/components/investigation-group-selection-cascader';

interface CreateInvestigationProps {
  investigation?: any;
  switchOnCreate?: boolean;
  investigationGroupID?: number | 'root';
  children?: React.ReactNode;
}

// @ts-expect-error
@connect(({ investigations, loading }) => ({ investigations, loading }))
class CreateInvestigation extends React.Component<
  CreateInvestigationProps,
  any
> {
  state = {
    showModal: false,
  };

  createInvestigationFrom = React.createRef();

  toggleInvestigationModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  createOrUpdateInvestigation() {
    const {
      dispatch,
      investigation,
      switchOnCreate = true,
      investigationGroupID,
    } = this.props;
    this.createInvestigationFrom.current.validateFields().then(
      (values) => {
        if (investigation) {
          investigation.Name = values.name;
          dispatch({
            type: 'investigations/updateInvestigation',
            investigationID: investigation.InvestigationID,
            payload: values,
          }).then(() => this.toggleInvestigationModal());
        } else {
          // if form showed cascader, use its value, else use the passed value (one of these has to be valid)
          const newInvestigationGroupID = values?.investigation_group_id?.length
            ? values.investigation_group_id[
                values.investigation_group_id.length - 1
              ]
            : investigationGroupID;
          dispatch({
            type: 'investigations/createInvestigation',
            payload: {
              name: values.name,
              ...(newInvestigationGroupID !== 'root' && {
                investigation_group_id: newInvestigationGroupID,
              }),
            },
          }).then((response) => {
            this.toggleInvestigationModal();
            if (this.createInvestigationFrom.current) {
              this.createInvestigationFrom.current.resetFields();
            }
            if (switchOnCreate) {
              history.push(`/investigations/${response.data.InvestigationID}`);
            }
          });
        }
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { children, loading, investigation, investigationGroupID } =
      this.props;
    return (
      <>
        <Modal
          width={400}
          title={investigation ? 'Rename Investigation' : 'New Investigation'}
          open={this.state.showModal}
          onOk={(e) => this.createOrUpdateInvestigation(e)}
          confirmLoading={
            loading.effects['investigations/createInvestigation'] ||
            loading.effects['investigations/updateInvestigation']
          }
          onCancel={() => this.toggleInvestigationModal()}>
          <Form
            ref={this.createInvestigationFrom}
            layout="vertical"
            requiredMark={false}
            initialValues={{ name: _.get(investigation, 'Name') }}
            onFinish={(e) => this.createOrUpdateInvestigation(e)}>
            <Form.Item
              label="Investigation&nbsp;Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the investigation',
                },
              ]}>
              <Input autoFocus />
            </Form.Item>
            {investigationGroupID === undefined && (
              <Form.Item
                label="In&nbsp;Investigation&nbsp;Group"
                name="investigation_group_id">
                <InvestigationGroupSelectionCascader />
              </Form.Item>
            )}
          </Form>
        </Modal>
        <span onClick={() => this.toggleInvestigationModal()}>{children}</span>
      </>
    );
  }
}
export default CreateInvestigation;
