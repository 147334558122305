import LoadingSpinner from '@/components/LoadingSpinner';
import RangePickerDF from '@/components/RangePickerDF';
import { getCustomerProfileValue } from '@/utils/utils';
import { useSelector } from '@umijs/max';
import { Button, Checkbox, Flex, Form, Input, Typography } from 'antd';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import type { RowCountType } from '../../types';
import { ObjectTypeMap } from './constants';
import { SearchFormContainer } from './styles';
import type { FormType } from './types';
import { constructPayload } from './utils';

const objectTypeOptions = [
  { label: 'People', value: ObjectTypeMap.People },
  { label: 'Vehicles', value: ObjectTypeMap.Vehicles },
];

export interface SearchFormProps {
  selectedChannels: string[];
  calculateResultCount: (rowCountSelectorValue: RowCountType) => number;
  rowSelectorValue: RowCountType;
  resetTimelinePlayerProps: () => void;
}

const SearchForm = ({
  selectedChannels,
  calculateResultCount,
  rowSelectorValue,
  resetTimelinePlayerProps,
}: SearchFormProps) => {
  const [form] = Form.useForm<FormType>();
  const dispatch = useDispatch();
  const monitorSearchPending: boolean = useSelector(
    // @ts-expect-error
    (state) => state.loading.effects['monitor_search/search'],
  );

  const currentUser = useSelector(
    //@ts-expect-error
    (state) => state.user.currentUser,
  );
  const isSubmitDisabled =
    monitorSearchPending || !selectedChannels || selectedChannels.length === 0;

  const onFinish = (values: FormType) => {
    const pageSize = calculateResultCount(rowSelectorValue);
    const payload = constructPayload({
      formValues: values,
      channelIDs: selectedChannels ?? [],
      pageNumber: 1,
      pageSize,
    });
    dispatch({
      type: 'monitor_search/search',
      payload,
    });
  };

  const showLPR = useMemo(
    () =>
      getCustomerProfileValue(currentUser, 'fe_monitor_search_lpr')
        .visibility === 'on',
    [currentUser],
  );

  const handleFormReset = () => {
    dispatch({ type: 'monitor_search/resetState' });
    resetTimelinePlayerProps();
  };

  return (
    <SearchFormContainer>
      <Form
        style={{ width: '100%' }}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ objectType: [] }}>
        <Flex justify="space-between">
          <Typography.Title level={5}>Search</Typography.Title>
          <Button
            type="link"
            htmlType="reset"
            onClick={handleFormReset}
            style={{ marginBottom: '8px', padding: 0, alignItems: 'baseline' }}>
            Reset
          </Button>
        </Flex>
        <Form.Item
          name="dateRange"
          rules={[
            {
              type: 'array',
              required: true,
              message: 'Please select time!',
            },
          ]}>
          <RangePickerDF
            style={{ width: '100%' }}
            layout="vertical"
            styleMode="light"
          />
        </Form.Item>
        <Flex vertical gap={'small'}>
          <Typography.Text style={{ display: 'block' }}>
            Find Objects
          </Typography.Text>
          <Form.Item name="objectType">
            <Checkbox.Group options={objectTypeOptions} />
          </Form.Item>
        </Flex>
        {showLPR && (
          <Flex vertical gap={'small'}>
            <Typography.Text style={{ display: 'block' }}>
              Advanced Vehicles
            </Typography.Text>
            <Form.Item
              extra="Use '*' to select all license plates"
              name="licensePlate">
              <Input placeholder="License Plate Number" />
            </Form.Item>
          </Flex>
        )}

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            disabled={isSubmitDisabled}>
            {monitorSearchPending ? (
              <LoadingSpinner fontSize="18px" />
            ) : (
              'Search'
            )}
          </Button>
        </Form.Item>
      </Form>
    </SearchFormContainer>
  );
};

export default SearchForm;
