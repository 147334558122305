import CustomerSuccess from '@/components/CustomerSuccess';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { naturalSortKey } from '@/utils/natural-sort';
import { Button, Empty } from 'antd';
import { Component } from 'react';
import { connect, Link } from 'umi';
import InvestigationCard from '../home/components/investigation-card';
import InvestigationGroupTile from '../home/components/investigation-group-tile';
import CreateInvestigation from './components/create-investigation';
import CreateInvestigationGroup from './investigation-group/components/create-investigation-group';
import type { InvestigationGroupType } from './investigation-group/types';
import styles from './style.less';

// @ts-expect-error
@connect(({ investigations, loading }) => ({
  investigations,
  loadingInvestigations: loading.effects['investigations/fetchInvestigations'],
}))
class InvestigationsPage extends Component {
  componentDidMount() {
    document.getElementById('page-container').scrollTop = 0;
  }

  render() {
    const { investigations, loadingInvestigations } = this.props;

    if (loadingInvestigations) {
      return <LoadingSpinner />;
    }
    const rootInvestigationGroups: InvestigationGroupType[] = naturalSortKey(
      (
        investigations.investigationGroups.all[0].InvestigationGroupIDs || []
      ).map((id) => investigations.investigationGroups.byId[+id]),
      'Name',
    );
    const rootInvestigations = naturalSortKey(
      (investigations.investigationGroups.all[0].InvestigationIDs || []).map(
        (id) => investigations.investigations.byId[+id],
      ),
      'Name',
    );
    return (
      <>
        <CustomerSuccess page="investigations" />
        <div className={styles.container}>
          <PageHeader
            title="Investigations"
            right={
              <>
                <CreateInvestigation investigationGroupID="root">
                  <Button size="small" type="default">
                    + Investigation
                  </Button>
                </CreateInvestigation>
                &nbsp;
                <CreateInvestigationGroup>
                  <Button size="small" type="default">
                    + Investigation Group
                  </Button>
                </CreateInvestigationGroup>
              </>
            }
          />
          {rootInvestigations.length === 0 &&
          rootInvestigationGroups.length === 0 ? (
            <div className={styles['empty-investigations-container']}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    No Investigations found <br /> <br />
                    <CreateInvestigation investigationGroupID="root">
                      <Button type="primary">+ Investigation</Button>
                    </CreateInvestigation>
                  </span>
                }
              />
            </div>
          ) : (
            <div className={styles.ctn}>
              {rootInvestigationGroups.map((investigationGroup) => (
                <InvestigationGroupTile
                  key={investigationGroup.GroupID}
                  investigationGroup={investigationGroup}
                />
              ))}
              {rootInvestigationGroups.length > 0 &&
                rootInvestigations.length > 0 && (
                  <div className="horizontal-divider" />
                )}
              {rootInvestigations.map((investigation) => (
                <span key={investigation.ID}>
                  <Link
                    key={investigation.ID}
                    to={`/investigations/${investigation.ID}`}>
                    <InvestigationCard investigation={investigation} />
                  </Link>
                </span>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}
export default InvestigationsPage;
