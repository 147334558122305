import React from 'react';
import { Container, Content, Lock, Overlay } from './styles';

interface Props {
  isDisabled?: boolean;
  message?: string;
  children: React.ReactNode;
}

export const DisabledContent = ({
  isDisabled = false,
  message = 'This section is locked',
  children,
}: Props) => {
  if (!isDisabled) {
    return children;
  }
  return (
    <Container>
      <Content>{children}</Content>
      <Overlay>
        <Lock />
        {message}
      </Overlay>
    </Container>
  );
};
