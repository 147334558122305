import _ from 'lodash';
import semver from 'semver';

// Minutes: Timestamp every 5 minutes, tick mark every minute.
// Hours: Timestamp every hour, tick mark every 15 minute.
// Days: Timestamp every day, tick mark every 8 hours.
export const AXIS_TICK_LENGTH = 30;

// 99th percentile: 99% of base stations have a version >= this value.
export const DEFAULT_BASESTATION_VERSION = '3.1.11';

export const AXIS_SCALES = {
  SECONDS: {
    ticks: {
      period: 'seconds',
      step: 1,
      timestampFromat: 'DD MMM HH:mm:ss',
    },
  },
  SECONDS1: {
    ticks: {
      period: 'seconds',
      step: 15,
      timestampFromat: 'DD MMM HH:mm:ss',
    },
  },
  SECONDS2: {
    ticks: {
      period: 'seconds',
      step: 30,
      timestampFromat: 'DD MMM HH:mm:ss',
    },
  },
  MINUTES: {
    ticks: {
      period: 'seconds',
      step: 60,
      timestampFromat: 'DD MMM HH:mm:ss',
    },
  },
  MINUTES2: {
    ticks: {
      period: 'seconds',
      step: 15 * 60,
      timestampFromat: 'DD MMM HH:mm:ss',
    },
  },
  HOURS: {
    ticks: {
      period: 'seconds',
      step: 60 * 60,
      timestampFromat: 'DD MMM HH:mm:ss',
    },
  },
  HOURS1: {
    ticks: {
      period: 'seconds',
      step: 2 * 60 * 60,
      timestampFromat: 'DD MMM HH:mm:ss',
    },
  },
  DAYS: {
    ticks: {
      period: 'seconds',
      step: 2 * 24 * 60 * 60,
      timestampFromat: 'DD MMM HH:mm:ss',
    },
  },
  // too big, stack overflowed
  // MONTHS: {
  //   ticks: {
  //     period: 'seconds',
  //     step: 2 * 30 * 24 * 60 * 60,
  //     timestampFromat: 'DD MMM HH:mm:ss',
  //   },
  // },
};

export const carColorOptions = [
  {
    value: 'black',
    color: 'Black',
    colorHex: '#000',
  },
  {
    value: 'blue',
    color: 'Blue',
    colorHex: '#0000FF',
  },
  {
    value: 'gray',
    color: 'Gray',
    colorHex: '#666666',
  },
  {
    value: 'green',
    color: 'Green',
    colorHex: '#00AF00',
  },
  {
    value: 'red',
    color: 'Red',
    colorHex: '#f00',
  },
  {
    value: 'silver',
    color: 'Silver',
    colorHex: '#C0C0C0',
  },
  {
    value: 'white',
    color: 'White',
    colorHex: '#FFF',
  },
  {
    value: 'yellow',
    color: 'Yellow',
    colorHex: '#E6DA00',
  },
];

// 'black' 'blue' 'brown' 'green' 'maroon' 'mustard' 'orange' 'pink'
//  'purple' 'red' 'yellow'
// 10:23
// gray, white
export const personColorOptions = [
  {
    value: 'black',
    color: 'Black',
    colorHex: '#000',
  },
  {
    value: 'blue',
    color: 'Blue',
    colorHex: '#0000FF',
  },
  {
    value: 'brown',
    color: 'Brown',
    colorHex: '#A52A2A',
  },
  {
    value: 'gray',
    color: 'Gray',
    colorHex: '#666666',
  },
  {
    value: 'green',
    color: 'Green',
    colorHex: '#00AF00',
  },
  // {
  //   value: 'maroon',
  //   color: 'Maroon',
  //   colorHex: '#800000',
  // },
  // {
  //   value: 'mustard',
  //   color: 'Mustard',
  //   colorHex: '#ffdb58',
  // },
  {
    value: 'orange',
    color: 'Orange',
    colorHex: '#FFA500',
  },
  {
    value: 'pink',
    color: 'Pink',
    colorHex: '#FFC0CB',
  },
  {
    value: 'purple',
    color: 'Purple',
    colorHex: '#800080',
  },
  {
    value: 'red',
    color: 'Red',
    colorHex: '#f00',
  },
  {
    value: 'white',
    color: 'White',
    colorHex: '#FFF',
  },
  {
    value: 'yellow',
    color: 'Yellow',
    colorHex: '#E6DA00',
  },
];

// ======================================================================== //
// DEPRECATED - FE SIZE LOGIC                                               //
// ======================================================================== //

// >>> *NB* NOTICE *NB* <<<
// This logic was ported to the backend. The goal is to migrate this completely
// into the backend in the near future.
//
// We should NEVER be depending on the FE to choose these sorts of sizes for us as
// it makes the system brittle.
//
// Be careful of changing logic here until the migration has taken place, and these
// corresponding functions have been removed from `df-client/src/contasts.ts` and
// replaced by `df-services/src/app/utils/mapping_fe_logic.py`
// >>> *NB* NOTICE *NB* <<<

export const T_VIDEO_WIDTH = 640;
export const T_VIDEO_HEIGHT = 480;
// new default width from basestation version 2.6.121
export const NEW_T_VIDEO_HEIGHT = 640;

export const t_video_height = (baseStationVersion = '2.6.121'): number => {
  // DEPRECATED - MIGRATE INTO BE
  // *NB* see note for: t_video_size_from_map_channel_media
  baseStationVersion = baseStationVersion.split('-')[0];
  if (semver.gte(baseStationVersion, '2.6.121')) {
    return NEW_T_VIDEO_HEIGHT;
  }
  return T_VIDEO_HEIGHT;
};

export const t_video_width_from_thumbnail = (
  thumbnail,
  baseStationVersion = '2.6.121',
): number => {
  // DEPRECATED - MIGRATE INTO BE
  // *NB* see note for: t_video_size_from_map_channel_media
  baseStationVersion = baseStationVersion.split('-')[0];
  const video_height = semver.gte(baseStationVersion, '2.6.121')
    ? NEW_T_VIDEO_HEIGHT
    : T_VIDEO_HEIGHT;

  if (!thumbnail) {
    return T_VIDEO_WIDTH;
  }

  const thumbnail_width = _.get(thumbnail, 'Width', T_VIDEO_WIDTH);
  const thumbnail_height = _.get(thumbnail, 'Height', video_height);

  return Math.ceil((thumbnail_width * video_height) / thumbnail_height);
};

export const t_video_size_from_media = (
  media,
  baseStationVersion = '2.6.121',
): [number, number] => {
  // DEPRECATED - MIGRATE INTO BE
  // *NB* see note for: t_video_size_from_map_channel_media
  const thumbnail = _.get(media, 'Thumbnail', null);
  const transcodedVideo = _.get(media, 'TranscodedVideo');
  const transcodedVideoWidth = _.get(
    transcodedVideo,
    'Width',
    t_video_width_from_thumbnail(thumbnail, baseStationVersion),
  );
  const transcodedVideoHeight = _.get(
    transcodedVideo,
    'Height',
    t_video_height(baseStationVersion),
  );
  return [transcodedVideoWidth, transcodedVideoHeight];
};

export const t_video_size_from_map_channel_media = (
  mapChannel,
  baseStationVersion = '2.6.121',
): [number, number] => {
  // DEPRECATED - MIGRATE INTO BE
  // *NB*: This logic and all upstream logic is duplicated in the backend
  //       because we need to know the video sizes for mapping. Be careful of
  //       changing this logic without changing the backend logic!
  //       We should stop depending on this logic in the FE and return the
  //       video sizes from the backend instead.
  return t_video_size_from_media(
    _.get(mapChannel, 'Channel.LatestMedia[0]'),
    baseStationVersion,
  );
};

// ======================================================================== //
// OTHER                                                                    //
// ======================================================================== //

// Download links for the dragonfruit uploader
const DF_UPLOADER_DOWNLOAD_FOR_MAC =
  window.location.host === 'app.dragonfruit.ai'
    ? 'https://s3.amazonaws.com/downloads.dragonfruit.ai/Dragonfruit+Uploader/mac/latest/Dragonfruit+Client+Setup.dmg'
    : 'https://s3.amazonaws.com/stage-downloads.dragonfruit.ai/Dragonfruit+Uploader/mac/latest/Dragonfruit+Client+Setup.dmg';
const DF_UPLOADER_DOWNLOAD_FOR_WIN =
  window.location.host === 'app.dragonfruit.ai'
    ? 'https://s3.amazonaws.com/downloads.dragonfruit.ai/Dragonfruit+Uploader/windows/latest/Dragonfruit+Client+Setup.exe'
    : 'https://s3.amazonaws.com/stage-downloads.dragonfruit.ai/Dragonfruit+Uploader/windows/latest/Dragonfruit+Client+Setup.exe';
export { DF_UPLOADER_DOWNLOAD_FOR_MAC, DF_UPLOADER_DOWNLOAD_FOR_WIN };

export const PROXY_USERNAME = 'captain';
