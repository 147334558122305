import { Button, Popconfirm } from 'antd';
import React, { RefObject, useEffect, useState } from 'react';
import { useDispatch } from 'umi';

import SortableTable from '@/components/SortableTable';
import { dispatchWithFeedback } from '@/utils/utils';
import {
  ReportFrequency,
  ReportSectionStatusLabels,
  ReportSectionTypeLabels,
} from '../constants';
import CreateUpdateSection from './create-update-section';

type Props = {
  report?: any;
  frequency: ReportFrequency;
  onBeforeAdd: Function;
  addButtonRef: RefObject<HTMLButtonElement>;
};

const ReportSections: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const [showCreateUpdateView, toggleCreateUpdateView] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const { report, onBeforeAdd, addButtonRef, onSectionsReorder } = props;
  const [sections, setSections] = useState(report?.sections || []);

  const handleSectionsChange = (newSections) => {
    setSections(newSections);

    const payload = newSections.map((section, index) => ({
      id: section.id,
      order: index + 1,
    }));
    onSectionsReorder(payload);
  };

  useEffect(() => {
    setSections(report?.sections || []);
  }, [report]);

  const columns = [
    {
      key: 'sort',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type) => ReportSectionTypeLabels[type],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => ReportSectionStatusLabels[status],
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <div>
            <span
              onClick={() => {
                setCurrentSection(record);
                toggleCreateUpdateView(true);
              }}
              className="df-link">
              Edit
            </span>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to duplicate this report section?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Duplicating report section', {
                  type: `retail_insights/duplicateReportSection`,
                  payload: {
                    report_id: report.id,
                    section_id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link">Duplicate</span>
            </Popconfirm>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete this section?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Deleting Report Section', {
                  type: `retail_insights/removeReportSection`,
                  payload: {
                    report_id: report.id,
                    section_id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">Delete</span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          margin: '16px 0px',
        }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Sections</div>
        <Button
          type="link"
          ref={addButtonRef}
          onClick={(e) => {
            if (!report && e?.isTrusted) {
              onBeforeAdd();
            } else {
              toggleCreateUpdateView(true);
            }
          }}>
          Add
        </Button>
      </div>
      {sections.length ? (
        <SortableTable
          size="small"
          style={{ margin: '16px 0px' }}
          columns={columns}
          pagination={false}
          data={sections}
          onDragEnd={handleSectionsChange}
        />
      ) : null}
      {showCreateUpdateView && report && (
        <CreateUpdateSection
          reportId={report.id}
          frequency={props.frequency}
          section={currentSection}
          onClose={() => {
            toggleCreateUpdateView(false);
            setCurrentSection(null);
          }}
        />
      )}
    </div>
  );
};

export default ReportSections;
