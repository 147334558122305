import { getClipStartTime } from '@/utils/utils';
import { useSelector } from '@umijs/max';
import { Typography } from 'antd';
import _ from 'lodash';
import 'moment-duration-format';
import {
  SearchResultCardContainer,
  SearchResultCardTextContainer,
  SearchResultCardThumbnailContainer,
  SelectedOverlay,
} from './style';

export interface SearchResultCardProps {
  clip: {
    ChannelID: number;
    [key: string]: any;
  };
  selected: boolean;
  onClick: (clip: any) => void;
}

const SearchResultCard = ({
  clip,
  selected,
  onClick,
}: SearchResultCardProps) => {
  const channel = useSelector(
    // @ts-expect-error
    (state) => state.locations.ch.byId[clip.ChannelID],
  );
  return (
    <SearchResultCardContainer
      selected={selected}
      onClick={() => onClick(clip)}>
      {selected && <SelectedOverlay />}
      <SearchResultCardThumbnailContainer>
        {clip.s3image ? (
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain', // Ensure aspect ratio is maintained
              backgroundColor: 'black', // Add black bars if necessary
            }}
            src={clip.s3image.replace(
              _.get(
                channel?.ConfigProfiles['dc_onprem_baseFolderPath'],
                'values.path',
                '',
              ),
              '',
            )}
          />
        ) : clip.image ? (
          <img
            src={`data:image/png;base64, ${clip.image}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain', // Ensure aspect ratio is maintained
              backgroundColor: 'black', // Add black bars if necessary
            }}
          />
        ) : (
          <img
            src={_.get(clip, 'Thumbnail.SignedUrl')}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain', // Ensure aspect ratio is maintained
              backgroundColor: 'black', // Add black bars if necessary
            }}
          />
        )}
      </SearchResultCardThumbnailContainer>
      <SearchResultCardTextContainer>
        <Typography.Title
          level={5}
          style={{ fontSize: 'inherit', marginBottom: 0 }}>
          {getClipStartTime(clip, _.get(channel, 'Timezone'))?.format(
            'DD MMM HH:mm',
          )}
        </Typography.Title>
        <Typography.Text ellipsis style={{ fontSize: 'inherit' }}>
          {channel?.Name}
        </Typography.Text>
      </SearchResultCardTextContainer>
    </SearchResultCardContainer>
  );
};

export default SearchResultCard;
