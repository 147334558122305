import { useCallback } from 'react';
import {
  SearchResultCardHeight,
  SearchResultCardWidth,
} from './components/SearchResults/SearchResultCard/style';
import {
  SearchResultsContentGap,
  SearchResultsFooterAndGap,
} from './components/SearchResults/style';
import { RowCountMap } from './constants';
import type { RowCountType } from './types';

export const useResultCountCalculation = (
  sceneViewerContentRef: React.RefObject<HTMLDivElement>,
  sceneTimelineRef: React.RefObject<HTMLDivElement>,
) => {
  const calculateResultCount = useCallback(
    (rowCountSelectorValue: RowCountType) => {
      if (!sceneViewerContentRef.current || !sceneTimelineRef.current) {
        console.warn('Refs are not attached to DOM elements');
        return 0;
      }

      const contentWidth = sceneViewerContentRef.current.offsetWidth;
      const contentHeight = sceneViewerContentRef.current.offsetHeight;

      const videoHeight = contentWidth / (16 / 9);

      // Available height for just the search result cards
      const availableHeight =
        contentHeight - videoHeight - SearchResultsFooterAndGap;
      const availableWidth = contentWidth;

      // find number of columns of results.
      // This is easy. If number of columns is n then there will be n result cards and (n-1) gaps between them so
      // n * cardWidth + (n - 1) * gap <= availableWidth
      // n <= (availableWidth + gap) / (cardWidth + gap)

      const columns = Math.floor(
        (availableWidth + SearchResultsContentGap) /
          (SearchResultCardWidth + SearchResultsContentGap),
      );

      // Find number of rows of results
      let rows: number;

      if (rowCountSelectorValue === RowCountMap.FIT) {
        // Fit mode: Calculate rows to fit
        // Similar to columns calculation
        // n * cardHeight + (n - 1) * gap <= availableHeight
        // n <= (availableHeight + gap) / (cardHeight + gap)
        // Dont allow 0 rows to be shown though so use maximum between n and 1

        rows = Math.max(
          1,
          Math.floor(
            (availableHeight + SearchResultsContentGap) /
              (SearchResultCardHeight + SearchResultsContentGap),
          ),
        );
      } else {
        // Fixed rows mode: Use the provided number of rows
        rows = rowCountSelectorValue;
      }

      // Total items to fetch
      return rows * columns;
    },
    [sceneViewerContentRef, sceneTimelineRef],
  );

  return calculateResultCount;
};
