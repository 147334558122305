import { LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
`;

export const Content = styled.div`
  filter: blur(2px);
  pointer-events: none;
  opacity: 0.7;
`;

export const Lock = styled(LockOutlined)`
  fontsize: 36px;
  marginbottom: 8px;
`;
