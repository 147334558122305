import { EventType } from '@/pages/apps/app/FleetManagerApp/constants';
import { getEventLog } from '@/pages/apps/app/FleetManagerApp/services';
import moment from 'moment';

export interface ListItem {
  CreatedAt: string;
  EntityID: '106';
  EntityType: 'App';
  CustomerID: number;
  Data: any;
  EventID: string;
  EventType: keyof typeof EventType;
}

export interface FleetManagerModelState {
  log: {
    list: ListItem[];
    p_number: number;
    p_size: number;
    total_pages: number;
  };
}

const FleetManagerModel = {
  namespace: 'fleet_manager',

  state: {
    log: {
      list: [],
      p_number: 1,
      p_size: 25,
      total_pages: 1,
    },
  },

  effects: {
    *fetchEventLog(
      { payload }: { payload: any },
      { call, put }: { call: Function; put: Function },
    ): Generator<any, any, any> {
      const response = yield call(getEventLog, payload);
      if (response.success) {
        const transformedEvents = response.data.Data.list.map((event: any) => ({
          timestamp: moment.utc(event.CreatedAt).local().unix(),
          type: event.EventType,
          user_id: String(event.Data.user_id),
          data: event.Data,
        }));

        yield put({
          type: 'saveEventLog',
          payload: {
            list: transformedEvents,
            p_number: response.data.Data.p_number,
            p_size: response.data.Data.p_size,
            total_pages: response.data.Data.total_pages,
          },
        });
      }
      return response;
    },
  },

  reducers: {
    saveEventLog(state: any, { payload }: { payload: any }) {
      return {
        ...state,
        log: payload,
      };
    },
  },
};

export default FleetManagerModel;
