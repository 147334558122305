import { dispatchWithFeedback } from '@/utils/utils';
import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';
import type { InvestigationGroupType } from '../../types';

interface CreateInvestigationGroupProps {
  investigationGroup?: InvestigationGroupType;
  parentInvestigationGroupID?: number;
  children?: React.ReactNode;
}
// @ts-expect-error
@connect(({ loading }) => ({
  loadingInvestigationGroup:
    loading.effects['investigations/createInvestigationGroup'] ||
    loading.effects['investigations/updateInvestigationGroup'],
}))
class CreateInvestigationGroup extends React.Component<
  CreateInvestigationGroupProps,
  any
> {
  state = {
    showModal: false,
  };

  createInvestigationGroupForm = React.createRef();

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  createOrUpdateInvestigationGroup() {
    const { dispatch, investigationGroup, parentInvestigationGroupID } =
      this.props;
    this.createInvestigationGroupForm.current.validateFields().then(
      (values) => {
        if (investigationGroup) {
          investigationGroup.Name = values.name;
          dispatch({
            type: 'investigations/renameInvestigationGroup',
            investigationGroupID: investigationGroup.GroupID,
            payload: values,
          }).then(() => this.toggleModal());
        } else {
          if (parentInvestigationGroupID !== undefined) {
            values.parent_id = parentInvestigationGroupID;
          }
          dispatchWithFeedback(
            dispatch,
            'Investigation Group creation',
            {
              type: 'investigations/createInvestigationGroup',
              payload: values,
            },
            true,
          ).then((res) => {
            this.toggleModal();
            if (this.createInvestigationGroupForm.current) {
              this.createInvestigationGroupForm.current.resetFields();
            }
            const investigationGroupID = _.get(
              res,
              'InvestigationGroupID',
              null,
            );
            if (investigationGroupID) {
              history.push(`/investigation-groups/${investigationGroupID}`);
            }
          });
        }
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { children, loadingInvestigationGroup, investigationGroup } =
      this.props;
    return (
      <>
        <Modal
          width={400}
          title={
            investigationGroup
              ? 'Update Investigation Group'
              : 'Create Investigation Group'
          }
          open={this.state.showModal}
          onOk={(e) => this.createOrUpdateInvestigationGroup(e)}
          confirmLoading={loadingInvestigationGroup}
          onCancel={() => this.toggleModal()}>
          <Form
            ref={this.createInvestigationGroupForm}
            layout="vertical"
            requiredMark={false}
            initialValues={{ name: _.get(investigationGroup, 'Name') }}
            onFinish={(e) => this.createOrUpdateInvestigationGroup(e)}>
            <Form.Item
              label="Investigation Group Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the investigation group',
                },
              ]}>
              <Input autoFocus />
            </Form.Item>
          </Form>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default CreateInvestigationGroup;
