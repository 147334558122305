import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';
import moment from 'moment';
import { APP_ID, EventType } from './constants';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function importData(
  sitesFile: File | null,
  contactsFile: File | null,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/sites/import`;

  const formData = new FormData();

  if (sitesFile) {
    formData.append('sites', sitesFile, 'sites.csv');
  }

  if (contactsFile) {
    formData.append('site_contacts', contactsFile, 'contacts.csv');
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  return request(url, requestOptions);
}

interface EventLogFilters {
  p_number?: number;
  p_size?: number;
  type?: EventType[];
  user_id?: string[];
  timestamp?: [string | moment.Moment, string | moment.Moment];
}

export async function getEventLog(filters: EventLogFilters = {}) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  // Base params
  let params: Record<string, any> = {
    p_number: filters.p_number || 1,
    p_size: filters.p_size || 100,
  };

  // Handle event type filter
  if (filters.type) {
    params.event_types = filters.type;
  }

  // Handle user filter
  if (filters.user_id) {
    params.user_ids = filters.user_id;
  }

  // Handle timestamp filter
  if (filters.timestamp?.length === 2) {
    const [startDate, endDate] = filters.timestamp;
    if (startDate && endDate) {
      params.start_timestamp = startDate;
      params.end_timestamp = endDate;
    }
  }

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/event-log`;

  return request(url, {
    params,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
