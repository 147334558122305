/**
 * @generated SignedSource<<2961b5290b637876bbd0b1e5b6155a74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type SitesRefetchQuery$variables = {
  after?: string | null | undefined;
  app_id: number;
  customer_id: number;
  first?: number | null | undefined;
};
export type SitesRefetchQuery$data = {
  readonly ' $fragmentSpreads': FragmentRefs<'FleetManagerApp_sites'>;
};
export type SitesRefetchQuery = {
  response: SitesRefetchQuery$data;
  variables: SitesRefetchQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'after',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'app_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customer_id',
      },
      {
        defaultValue: 10000,
        kind: 'LocalArgument',
        name: 'first',
      },
    ],
    v1 = {
      kind: 'Variable',
      name: 'after',
      variableName: 'after',
    },
    v2 = {
      kind: 'Variable',
      name: 'first',
      variableName: 'first',
    },
    v3 = [
      v1 /*: any*/,
      {
        kind: 'Variable',
        name: 'appId',
        variableName: 'app_id',
      },
      {
        kind: 'Variable',
        name: 'customerId',
        variableName: 'customer_id',
      },
      v2 /*: any*/,
      {
        kind: 'Literal',
        name: 'sort',
        value: ['NAME_ASC'],
      },
    ],
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Name',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'SitesRefetchQuery',
      selections: [
        {
          args: [
            v1 /*: any*/,
            {
              kind: 'Variable',
              name: 'app_id',
              variableName: 'app_id',
            },
            {
              kind: 'Variable',
              name: 'customer_id',
              variableName: 'customer_id',
            },
            v2 /*: any*/,
          ],
          kind: 'FragmentSpread',
          name: 'FleetManagerApp_sites',
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'SitesRefetchQuery',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'SiteConnection',
          kind: 'LinkedField',
          name: 'sites',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'SiteEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'Site',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'SiteID',
                      storageKey: null,
                    },
                    v5 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'ExternalID',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'AddressInfo',
                      kind: 'LinkedField',
                      name: 'AddressInfo',
                      plural: false,
                      selections: [
                        v5 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'City',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'State',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'Country',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Label',
                      kind: 'LinkedField',
                      name: 'Labels',
                      plural: true,
                      selections: [
                        v5 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'LabelID',
                          storageKey: null,
                        },
                        v4 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'Color',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'HasShippingAddress',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'HasEscalationPath',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'HasContacts',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'Status',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'HasStatusNotes',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'Issues',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'HasIssueNotes',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: '__typename',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'cursor',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              kind: 'ClientExtension',
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: '__id',
                  storageKey: null,
                },
              ],
            },
            {
              alias: null,
              args: null,
              concreteType: 'PageInfo',
              kind: 'LinkedField',
              name: 'pageInfo',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'endCursor',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'hasNextPage',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v3 /*: any*/,
          filters: ['appId', 'customerId', 'sort'],
          handle: 'connection',
          key: 'FleetManagerApp_sites',
          kind: 'LinkedHandle',
          name: 'sites',
        },
      ],
    },
    params: {
      cacheID: '748b2485482cd6796047dd5fd6df9701',
      id: null,
      metadata: {},
      name: 'SitesRefetchQuery',
      operationKind: 'query',
      text: 'query SitesRefetchQuery(\n  $after: String\n  $app_id: Int!\n  $customer_id: Int!\n  $first: Int = 10000\n) {\n  ...FleetManagerApp_sites_gtPD6\n}\n\nfragment CreateUpdateLabel_sites on SiteConnection {\n  edges {\n    node {\n      Name\n      SiteID\n      id\n    }\n  }\n}\n\nfragment EventLog_sites on SiteConnection {\n  edges {\n    node {\n      id\n      SiteID\n      Name\n    }\n  }\n}\n\nfragment FleetManagerApp_sites_gtPD6 on Query {\n  sites(appId: $app_id, customerId: $customer_id, sort: [NAME_ASC], first: $first, after: $after) {\n    ...Labels_sites\n    ...Sites_sites\n    ...EventLog_sites\n    ...FleetStatus_sites\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment FleetStatus_sites on SiteConnection {\n  edges {\n    node {\n      id\n      ExternalID\n      Name\n      Status\n      HasStatusNotes\n      Issues\n      HasIssueNotes\n      Labels {\n        LabelID\n        Name\n        Color\n        id\n      }\n    }\n  }\n}\n\nfragment Labels_sites on SiteConnection {\n  ...CreateUpdateLabel_sites\n}\n\nfragment Sites_sites on SiteConnection {\n  edges {\n    node {\n      id\n      SiteID\n      Name\n      ExternalID\n      AddressInfo {\n        id\n        City\n        State\n        Country\n      }\n      Labels {\n        id\n        LabelID\n        Name\n        Color\n      }\n      HasShippingAddress\n      HasEscalationPath\n      HasContacts\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '3ce907a65a9e9c025eb09da09a39fcfc';

export default node;
