import type { DefaultOptionType } from 'antd/lib/cascader';
import Cascader from 'antd/lib/cascader';
import { useState } from 'react';
import { ALL_SITES_GROUP_LABEL } from './constants';
import type { SiteCascaderValue, SiteSelectionCascaderProps } from './types';
import {
  deduplicateCascaderValues,
  getSiteOptions,
  isOptionLeaf,
  updateOptions,
} from './utils';

const SiteSelectionCascader = ({
  siteGroups,
  sites,
  existingSelectedSites,
  onChange,
  ...restCascaderProps
}: SiteSelectionCascaderProps) => {
  const [siteCascaderOptions, setSiteCascaderOptions] = useState<
    DefaultOptionType[]
  >(() => {
    const options = getSiteOptions(sites, siteGroups);

    if (existingSelectedSites && existingSelectedSites.length > 0) {
      const selectedSites = existingSelectedSites.map((s) => [
        ALL_SITES_GROUP_LABEL,
        s,
      ]);
      const upOptions = updateOptions(options, selectedSites);
      return upOptions;
    }
    return options;
  });

  function handleSiteCascaderChange(
    value: SiteCascaderValue[],
    selectOptions: DefaultOptionType[][],
  ): void {
    const optionsToConsider = selectOptions.filter((optionsArray) =>
      isOptionLeaf(optionsArray[optionsArray.length - 1]),
    );
    const valueToConsider = deduplicateCascaderValues(
      optionsToConsider.map((optionsArray) =>
        optionsArray.map((option) => option.value as number | string),
      ),
    );

    setSiteCascaderOptions((prev) => updateOptions(prev, valueToConsider));

    onChange?.(valueToConsider);
  }

  return (
    <Cascader
      style={{ width: '100%' }}
      options={siteCascaderOptions}
      multiple
      onChange={(value, options) =>
        handleSiteCascaderChange(value, options as DefaultOptionType[][])
      }
      expandTrigger="hover"
      showCheckedStrategy={Cascader.SHOW_CHILD}
      defaultValue={existingSelectedSites?.map((s) => [
        ALL_SITES_GROUP_LABEL,
        s,
      ])}
      showSearch={true}
      {...restCascaderProps}
    />
  );
};

export { SiteSelectionCascader };
