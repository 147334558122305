import { ReloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'umi';

import DuplicateInsight from '@/components/Insight/duplicate-insight';
import InsightChart from '@/components/Insight/insight-chart';
import InsightStatusMessages from '@/components/Insight/insight-status-messages';
import ShareInsight from '@/components/Insight/share-insight';
import PageHeader from '@/components/PageHeader2';
import EditInsightView from '@/pages/views/components/edit-insight-view';
import { InsightClass } from '@/types/insights';
import { RouteComponentProps } from '@/types/utils';
import {
  getInsightPageHeaderTitle,
  INSIGHT_NODE_TYPE,
  isLiveInsight,
} from '@/utils/insight';
import MoveInsightGroup from '../insight-group/componets/move-insight-group';
import Facets from './components/facets';
import styles from './style.less';

import InsightSettings from '@/components/Insight/insight-settings';
import LoadingSpinner from '@/components/LoadingSpinner';
import type { InsightModalState } from '@/models/insights';
import type { LocationModalState } from '@/models/location_map';
import withRouter from '@/utils/withRouter';

interface MatchParams {
  insightID: string;
}

interface MyProps extends RouteComponentProps<MatchParams> {
  isEmbed?: boolean;
  insights?: InsightModalState;
  location_maps?: LocationModalState;
  loadingMapForLocation: boolean;
  dispatch?: (_any: any) => Promise<any>;
}

interface MyState {
  showCameras?: boolean;

  insight: InsightClass | null;
  insightConfig: Record<string, any>;
  insightPageHeaderTitle: string;

  facet_edited_by_user: boolean;

  CSVData: any[];
}

// @ts-expect-error
@connect(({ insights, location_maps, loading }) => ({
  insights,
  location_maps,
  loadingMapForLocation: loading.effects['location_maps/getMapsForLocation'],
}))
class Insight extends React.Component<MyProps, MyState> {
  static defaultProps = {
    isEmbed: false,
  };

  insightChartRef: React.RefObject<InsightChart>;
  csvLinkRef: React.RefObject<CSVLink>;
  facetsRef: React.RefObject<Facets>;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      showCameras: false,

      insight: null,
      insightConfig: {},
      insightPageHeaderTitle: '',

      facet_edited_by_user: false,

      CSVData: [],
    };

    this.insightChartRef = React.createRef<InsightChart>();
    this.csvLinkRef = React.createRef<CSVLink>();
    this.facetsRef = React.createRef<Facets>();
  }

  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate(prevProps: Readonly<MyProps>): void {
    const prev_insights = prevProps.insights.all;
    const prev_insightID = prevProps.match.params.insightID;
    const prev_insight = _.get(
      prevProps.insights,
      `byID[${prev_insightID}]`,
      null,
    );

    const insights = this.props.insights.all;
    const insightID = this.props.match.params.insightID;
    const insight = _.get(this.props.insights, `byID[${insightID}]`, null);
    if (
      !_.isEqual(prev_insightID, insightID) ||
      !_.isEqual(prev_insight, insight) ||
      !_.isEqual(prev_insights, insights)
    ) {
      this.init();
    }
  }

  init() {
    const { insights } = this.props;
    const insightID = this.props.match.params.insightID;
    const insight = _.get(insights, `byID[${insightID}]`, null);
    const insightConfig = _.get(insight, 'Config', {});

    const newState: Record<string, any> = {
      insight,
      insightPageHeaderTitle: getInsightPageHeaderTitle(
        insights,
        INSIGHT_NODE_TYPE.INSIGHT,
        insightID,
      ),
    };
    if (!this.state.facet_edited_by_user) {
      newState['insightConfig'] = insightConfig;
    }

    this.setState(newState as MyState, () => {
      this.getLocationMapFromLocations(insightConfig);
    });
  }

  getLocationMapFromLocations(insightConfig: Record<string, any>) {
    const { location_maps, dispatch } = this.props;
    if (location_maps && dispatch) {
      _.get(insightConfig, 'region_facet.regions', []).map(
        (region: Record<string, any>) => {
          const locID = region['locationID'];
          const locMapConfigs = _.get(location_maps, `byLocID[${locID}]`);
          if (!locMapConfigs && locID) {
            dispatch({
              type: 'location_maps/getMapsForLocation',
              payload: { locationID: locID },
            });
          }
        },
      );
    }
  }

  updateInsightPercentageComplete(percentage: number) {
    this.setState({ percentComplete: percentage }, () => {});
    if (percentage === 100) {
      const { insightID } = this.props.match.params;
      this.generateInsight(insightID);
    }
  }

  generateInsightWork(insightID: string, compute = false) {
    const { dispatch } = this.props;
    const { insightConfig } = this.state;

    if (dispatch) {
      dispatch({
        type: 'insights/generateInsightReport',
        payload: {
          config: insightConfig,
          compute,
        },
        insightID,
        cb: this.updateInsightPercentageComplete.bind(this),
      });
    }
  }

  generateInsight = _.throttle(this.generateInsightWork, 500, {
    leading: false,
    trailing: true,
  });

  onConfigChange(newConfig: Record<string, any>) {
    if (!_.isEqual(newConfig, _.get(this.state, 'insight.Config', {}))) {
      this.setState({ insightConfig: newConfig, facet_edited_by_user: true });
    } else {
      this.setState({ insightConfig: newConfig, facet_edited_by_user: false });
    }
  }

  updateVizSpec(vizSpec: any, insight: InsightClass) {
    const config = insight.Config;

    let newConfig = {
      ...config,
      vizSpec,
    };

    this.setState({ insightConfig: newConfig }, () =>
      this.generateInsight(insight['InsightID']),
    );
  }

  renderMessages(
    insight: InsightClass,
    _stateInsightConfig: Record<string, any>,
  ) {
    return (
      <InsightStatusMessages
        insight={insight}
        generateInsight={this.generateInsight.bind(this)}
        percentComplete={_.get(this.state, 'percentComplete', null)}
        facetEditedByUser={this.state.facet_edited_by_user}
        onRecalculate={() => {
          this.setState({ facet_edited_by_user: false });
          this.generateInsight(insight['InsightID'], true);
        }}
      />
    );
  }

  getCSVData() {
    let CSVData = [];
    if (this.insightChartRef.current) {
      CSVData = this.insightChartRef.current.getInsightCSV();
      this.setState({ CSVData }, () => {
        if (this.csvLinkRef.current) {
          this.csvLinkRef.current.link.click();
        }
      });
    }
  }

  renderPageHeader(insight: InsightClass): React.ReactNode {
    const { showCameras, insightConfig, insightPageHeaderTitle, CSVData } =
      this.state;

    const isHeatmap =
      _.get(insightConfig, 'metrics_facet.metric') === 'heat_map' ||
      _.get(insightConfig, 'metrics_facet.metric') === 'heat_map_count';

    const group_by_facet = _.get(insightConfig, 'group_by_facet', {});
    const isGroupedByTime =
      _.get(InsightChart.getGroupBys(group_by_facet), [0], '') === 'time';
    const vizSpec = _.get(insightConfig, 'vizSpec', null);

    const insightVisibility = _.get(insight, 'Labels.visibility', '');

    return (
      <PageHeader
        title={
          <Tooltip placement="bottom" title={insightPageHeaderTitle}>
            <span>{insightPageHeaderTitle}</span>
          </Tooltip>
        }
        right={
          <>
            {isHeatmap && isGroupedByTime && (
              <>
                <Button
                  onClick={() => {
                    this.setState({ showCameras: !showCameras });
                  }}
                  size="small">
                  {showCameras ? 'Hide ' : 'Show '} Cameras
                </Button>
                &nbsp;
              </>
            )}
            {!isHeatmap && (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    this.getCSVData();
                  }}>
                  Export to CSV
                </Button>
                <CSVLink
                  ref={this.csvLinkRef}
                  style={{ display: 'none' }}
                  filename={'insight.csv'}
                  data={CSVData}
                />
                &nbsp;
              </>
            )}
            <DuplicateInsight insight={insight}>
              <Button size="small">Duplicate</Button>
            </DuplicateInsight>
            &nbsp;
            <MoveInsightGroup insight={insight}>
              <Button size="small" type="default">
                Move
              </Button>
            </MoveInsightGroup>
            &nbsp;
            <ShareInsight insight={insight}>
              <Button size="small">Share</Button>
            </ShareInsight>
            &nbsp;
            <EditInsightView
              onSave={(spec) => this.updateVizSpec(spec, insight)}
              vizSpec={vizSpec}>
              <Button size="small" disabled={insightVisibility == 'read_only'}>
                View Options
              </Button>
            </EditInsightView>
            &nbsp;
            <InsightSettings insightID={this.props.match.params.insightID}>
              <Button size="small">Settings</Button>
            </InsightSettings>
          </>
        }
      />
    );
  }

  render(): React.ReactNode {
    const { showCameras, insight, insightConfig } = this.state;
    const { loadingMapForLocation } = this.props;
    if (loadingMapForLocation) {
      return <LoadingSpinner />;
    }
    if (insight) {
      const insightVisibility = _.get(insight, 'Labels.visibility', '');
      return (
        <React.Fragment key={location.pathname}>
          {this.renderPageHeader(insight)}
          <div
            className={styles.octn}
            id={`insight-container-${insight['InsightID']}`}>
            <div className={styles.ctn}>
              {this.renderMessages(insight, insightConfig)}
              {isLiveInsight(insight) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}>
                  <Button
                    size="small"
                    className={styles['reload-button']}
                    onClick={() =>
                      this.generateInsight(insight['InsightID'], true)
                    }>
                    <ReloadOutlined />
                  </Button>
                </div>
              )}
              <InsightChart
                ref={this.insightChartRef}
                showCameras={showCameras}
                insight={insight}
              />
            </div>
            <div className={styles['facets-ctn']}>
              <Facets
                key={`insight-facets-${insight['InsightID']}`}
                uniqueKey={`insight-facets-${insight['InsightID']}`}
                disableFacets={insightVisibility == 'read_only'}
                ref={this.facetsRef}
                insightConfig={insightConfig}
                facet_edited_by_user={this.state.facet_edited_by_user}
                generateInsight={(new_config: Record<string, any>) => {
                  this.setState(
                    { insightConfig: new_config, facet_edited_by_user: false },
                    () => {
                      this.generateInsight(insight['InsightID']);
                    },
                  );
                }}
                onConfigChange={(new_config: Record<string, any>) => {
                  this.onConfigChange(new_config);
                }}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
    return <></>;
  }
}
export default withRouter(Insight);
