import { Button, Form, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';
import type { InvestigationGroupType } from '../../types';
import InvestigationGroupSelectionCascader from '../investigation-group-selection-cascader';

interface MoveInvestigationGroupProps {
  investigationGroup?: InvestigationGroupType;
  investigation?: any;
  children?: React.ReactNode;
}

// @ts-expect-error
@connect(({ loading }) => ({
  loadingInvestigationGroup:
    loading.effects['investigations/updateInvestigationGroup'],
  loadingInvestigation: loading.effects['investigations/updateInvestigation'],
}))
class MoveInvestigationGroup extends React.Component<
  MoveInvestigationGroupProps,
  any
> {
  state = {};

  moveInvestigationGroupForm = React.createRef();

  componentDidMount() {}

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  handelMoveInvestigationGroup(e) {
    const { dispatch, investigationGroup, investigation } = this.props;
    e.preventDefault();
    this.moveInvestigationGroupForm.current.validateFields().then(
      (values) => {
        const newParentID = values.parentID[values.parentID.length - 1];
        if (investigation) {
          dispatch({
            type: 'investigations/moveInvestigation',
            investigationID: investigation.InvestigationID,
            payload:
              newParentID === 'root'
                ? {}
                : { investigation_group_id: newParentID },
          }).then(() => {
            this.toggleModal();
            if (this.moveInvestigationGroupForm.current) {
              this.moveInvestigationGroupForm.current.resetFields();
            }
          });
        } else if (investigationGroup !== undefined) {
          dispatch({
            type: 'investigations/moveInvestigationGroup',
            investigationGroupID: investigationGroup.GroupID,
            payload: newParentID === 'root' ? {} : { parent_id: newParentID },
          }).then(() => {
            this.toggleModal();
            if (this.moveInvestigationGroupForm.current) {
              this.moveInvestigationGroupForm.current.resetFields();
            }
          });
        }
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const {
      children,
      loadingInvestigation,
      loadingInvestigationGroup,
      investigation,
      investigationGroup,
    } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <Modal
          width={500}
          height={180}
          title={`Move ${
            _.get(investigationGroup, 'Name') || _.get(investigation, 'Name')
          }`}
          open={showModal}
          onCancel={() => this.toggleModal()}
          footer={[
            <Button key="cancel" onClick={() => this.toggleModal()}>
              Cancel
            </Button>,
            <Button
              key="addevent"
              loading={loadingInvestigationGroup || loadingInvestigation}
              onClick={(e) => this.handelMoveInvestigationGroup(e)}
              type="primary">
              Move
            </Button>,
          ]}>
          <div>
            <Form
              ref={this.moveInvestigationGroupForm}
              layout="vertical"
              requiredMark={false}
              onSubmit={(e) => this.handelMoveInvestigationGroup(e)}>
              <Form.Item
                label="Select Investigation Group"
                name="parentID"
                rules={[
                  {
                    required: true,
                    message: 'Please select the investigation group',
                  },
                ]}>
                <InvestigationGroupSelectionCascader
                  currentInvestigationGroupID={investigationGroup?.GroupID}
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default MoveInvestigationGroup;
