import LoadingSpinner from '@/components/LoadingSpinner';
import InvestigationGroupSelectionCascader from '@/pages/investigations/investigation-group/components/investigation-group-selection-cascader';
import { Button, Form, notification } from 'antd';
import React from 'react';
import { connect } from 'umi';
import styles from './style.less';

// @ts-expect-error
@connect(({ investigations }) => ({
  investigations,
}))
class MoveInvestigationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  updateInvestigationForm = React.createRef();

  componentDidMount() {}

  moveInvestigation() {
    const { dispatch } = this.props;
    const { investigationID } = this.props;
    this.updateInvestigationForm.current.validateFields().then(
      (values) => {
        this.setState({ loading: true });
        const newGroupID = values.group[values.group.length - 1];
        dispatch({
          type: 'investigations/moveInvestigation',
          investigationID,
          payload:
            newGroupID === 'root' ? {} : { investigation_group_id: newGroupID },
        }).then(() => {
          notification.open({
            message: 'Investigation moved.',
            className: 'df-notification',
            placement: 'bottomRight',
          });
          this.setState({
            edit: false,
            loading: false,
          });
        });
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    return (
      <div>
        <div className={styles['tab-container']}>
          <div className={styles['tab-title']}>Move Investigation</div>
          <div className={styles['tab-description']}>
            <Form
              ref={this.updateInvestigationForm}
              layout="vertical"
              requiredMark={false}
              onFinish={(e) => this.moveInvestigation(e)}>
              <div
                style={{
                  width: '200px',
                  marginBottom: '-20px',
                }}>
                <Form.Item
                  label=""
                  name="group"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose the new Investigation Group',
                    },
                  ]}>
                  <InvestigationGroupSelectionCascader size="small" />
                </Form.Item>
                <span className={styles.loader}>
                  {this.state.loading ? <LoadingSpinner fontSize={20} /> : null}
                </span>
              </div>
              {
                <Button
                  size="small"
                  type="primary"
                  htmlType="submit"
                  className={styles['button-text']}>
                  Done
                </Button>
              }
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
export default MoveInvestigationTab;
