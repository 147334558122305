import { LockOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import styled from 'styled-components';

export const Option = styled(Select.Option)<{
  $disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LockIcon = styled(LockOutlined)`
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
`;
