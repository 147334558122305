/**
 * @generated SignedSource<<b5fa78f163f1526a73b6f15f395bed79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type FleetStatusSidebar_SiteNodeQuery$variables = {
  siteId: string;
};
export type FleetStatusSidebar_SiteNodeQuery$data = {
  readonly node:
    | {
        readonly HasIssueNotes?: boolean | null | undefined;
        readonly HasStatusNotes?: boolean | null | undefined;
        readonly IssueNotes?: string | null | undefined;
        readonly Issues?: string | null | undefined;
        readonly Name?: string | null | undefined;
        readonly SiteID?: string;
        readonly Status?: string | null | undefined;
        readonly StatusNotes?: string | null | undefined;
        readonly id?: string;
      }
    | null
    | undefined;
};
export type FleetStatusSidebar_SiteNodeQuery = {
  response: FleetStatusSidebar_SiteNodeQuery$data;
  variables: FleetStatusSidebar_SiteNodeQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'siteId',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'siteId',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'SiteID',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Name',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Status',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'StatusNotes',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Issues',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'IssueNotes',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'HasStatusNotes',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'HasIssueNotes',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'FleetStatusSidebar_SiteNodeQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              kind: 'InlineFragment',
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
                v7 /*: any*/,
                v8 /*: any*/,
                v9 /*: any*/,
                v10 /*: any*/,
              ],
              type: 'Site',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'FleetStatusSidebar_SiteNodeQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            v2 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
                v7 /*: any*/,
                v8 /*: any*/,
                v9 /*: any*/,
                v10 /*: any*/,
              ],
              type: 'Site',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'a5ddb33a88765fa28cf79e4a82d7743c',
      id: null,
      metadata: {},
      name: 'FleetStatusSidebar_SiteNodeQuery',
      operationKind: 'query',
      text: 'query FleetStatusSidebar_SiteNodeQuery(\n  $siteId: ID!\n) {\n  node(id: $siteId) {\n    __typename\n    ... on Site {\n      id\n      SiteID\n      Name\n      Status\n      StatusNotes\n      Issues\n      IssueNotes\n      HasStatusNotes\n      HasIssueNotes\n    }\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'a37300090677e5caacc949f558ad170a';

export default node;
