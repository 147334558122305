import Combobox from '@/components/combobox';
import { Button, Form, Input, Modal } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useFleetManagerContext } from '../FleetManagerContext';
import { useUpdateSiteStatusMutation } from '../mutations/UpdateSiteStatusMutation';
import styles from './style.less';
import { FleetStatusSidebarProps } from './types';
import { FleetStatusSidebar_SiteNodeQuery } from './__generated__/FleetStatusSidebar_SiteNodeQuery.graphql';

const siteNodeQuery = graphql`
  query FleetStatusSidebar_SiteNodeQuery($siteId: ID!) {
    node(id: $siteId) {
      ... on Site {
        id
        SiteID
        Name
        Status
        StatusNotes
        Issues
        IssueNotes
        HasStatusNotes
        HasIssueNotes
      }
    }
  }
`;

const FleetStatusSidebar: React.FC<FleetStatusSidebarProps> = ({
  siteId,
  visible,
  onClose,
  uniqueStatuses,
  uniqueIssues,
}) => {
  const [isEditingStatusNotes, setIsEditingStatusNotes] = useState(false);
  const [isEditingIssueNotes, setIsEditingIssueNotes] = useState(false);
  const [form] = Form.useForm();
  const { customerId, handleShowInRegistry } = useFleetManagerContext();
  const { isInFlight, updateSiteStatus } = useUpdateSiteStatusMutation();

  const siteData = useLazyLoadQuery<FleetStatusSidebar_SiteNodeQuery>(
    siteNodeQuery,
    {
      siteId,
    },
  );

  const site = siteData?.node;

  const handleSubmit = useCallback(
    (values = form.getFieldsValue()) => {
      updateSiteStatus(
        {
          ...values,
          siteId: site?.SiteID,
          customerId,
        },
        () => {
          form.setFieldsValue(values);
          setIsEditingStatusNotes(false);
          setIsEditingIssueNotes(false);
        },
      );
    },
    [form, updateSiteStatus, site?.SiteID, customerId],
  );

  const handleDropdownChange = useCallback(
    (field: string, value: string) => {
      const newValues = {
        ...form.getFieldsValue(),
        [field]: value,
      };
      form.setFieldsValue(newValues);
      handleSubmit(newValues);
    },
    [form, handleSubmit],
  );

  const handleShowInRegistryClick = useCallback(() => {
    handleShowInRegistry(site?.SiteID);
    onClose();
  }, [site?.SiteID, handleShowInRegistry, onClose]);

  return (
    <Modal
      title={<span>Details: {site?.Name}</span>}
      open={visible}
      onCancel={onClose}
      width={400}
      className={styles.modal}
      footer={null}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          status: site?.Status,
          statusNotes: site?.StatusNotes,
          issues: site?.Issues,
          issueNotes: site?.IssueNotes,
        }}>
        <div className={styles.formHeader}>
          <Button onClick={handleShowInRegistryClick}>Show in Registry</Button>
        </div>

        <div className={styles.fieldHeader}>
          <div className={styles.fieldLabel}>Status:</div>
          <Form.Item name="status" className={styles.inlineInput}>
            <Combobox
              options={uniqueStatuses}
              onChange={(value) => handleDropdownChange('status', value)}
            />
          </Form.Item>
        </div>

        <div className={styles.fieldHeader}>
          <div className={styles.fieldLabel}>Status Notes</div>
          {!isEditingStatusNotes ? (
            <Button type="link" onClick={() => setIsEditingStatusNotes(true)}>
              Edit
            </Button>
          ) : (
            <div className={styles.actionButtons}>
              <Button
                type="link"
                onClick={() => handleSubmit(form.getFieldsValue())}
                loading={isInFlight}>
                Save
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setIsEditingStatusNotes(false);
                  form.setFieldsValue({ statusNotes: site?.StatusNotes });
                }}>
                Cancel
              </Button>
            </div>
          )}
        </div>
        <Form.Item name="statusNotes" className={styles.markdownField}>
          {isEditingStatusNotes ? (
            <Input.TextArea
              className={styles.markdownInput}
              rows={6}
              placeholder="Enter status notes in markdown format..."
            />
          ) : (
            <div
              className={classNames(
                'markdown-content',
                styles.markdownDisplay,
              )}>
              <ReactMarkdown>{site?.StatusNotes || ''}</ReactMarkdown>
            </div>
          )}
        </Form.Item>

        <div className={styles.fieldHeader}>
          <div className={styles.fieldLabel}>Issue:</div>
          <Form.Item name="issues" className={styles.inlineInput}>
            <Combobox
              options={uniqueIssues}
              onChange={(value) => handleDropdownChange('issues', value)}
            />
          </Form.Item>
        </div>

        <div className={styles.fieldHeader}>
          <div className={styles.fieldLabel}>Issue Notes</div>
          {!isEditingIssueNotes ? (
            <Button type="link" onClick={() => setIsEditingIssueNotes(true)}>
              Edit
            </Button>
          ) : (
            <div className={styles.actionButtons}>
              <Button
                type="link"
                onClick={() => handleSubmit(form.getFieldsValue())}
                loading={isInFlight}>
                Save
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setIsEditingIssueNotes(false);
                  form.setFieldsValue({ issueNotes: site?.IssueNotes });
                }}>
                Cancel
              </Button>
            </div>
          )}
        </div>
        <Form.Item name="issueNotes" className={styles.markdownField}>
          {isEditingIssueNotes ? (
            <Input.TextArea
              className={styles.markdownInput}
              rows={6}
              placeholder="Enter issue notes in markdown format..."
            />
          ) : (
            <div
              className={classNames(
                'markdown-content',
                styles.markdownDisplay,
              )}>
              <ReactMarkdown>{site?.IssueNotes || ''}</ReactMarkdown>
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FleetStatusSidebar;
