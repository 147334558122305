import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { CheckboxWrapper, LockIcon } from './styles';

const CheckboxWithLock = ({
  disabled,
  children,
  ...restProps
}: CheckboxProps) => {
  return (
    <CheckboxWrapper>
      {disabled ? (
        <LockIcon />
      ) : (
        <Checkbox disabled={disabled} {...restProps} />
      )}
      <span>{children}</span>
    </CheckboxWrapper>
  );
};

export default CheckboxWithLock;
