import styled from 'styled-components';
import { SearchResultCardWidth } from './SearchResultCard/style';

export const SearchResultsContentGap = 20; //px
const SearchResultsFooterHeight = 48; //px
const SearchResultsContainerGap = 16; //px
export const SearchResultsFooterAndGap =
  SearchResultsFooterHeight + SearchResultsContainerGap;

export const SearchResultsContainer = styled.div<{ $show_results: boolean }>`
  overflow: visible;
  transition: height 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: ${SearchResultsContainerGap}px;
`;
export const SearchResultsContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${SearchResultCardWidth}px, 1fr)
  );
  gap: ${SearchResultsContentGap}px;
`;

export const SearchResultsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${SearchResultsFooterHeight}px;
`;

export const SearchResultsFooterSortCountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 16px;
`;

export const NoResultsFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;
