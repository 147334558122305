import { graphql, useMutation } from 'react-relay';

const mutation = graphql`
  mutation UpdateSiteStatusMutation($input: UpdateSiteStatusInput!) {
    updateSiteStatus(input: $input) {
      site {
        id
        SiteID
        Status
        StatusNotes
        Issues
        IssueNotes
        HasStatusNotes
        HasIssueNotes
      }
    }
  }
`;

export function useUpdateSiteStatusMutation() {
  const [commit, isInFlight] = useMutation(mutation);
  const updateSiteStatus = (
    input: {
      siteId: number;
      customerId: number;
      status?: string;
      statusNotes?: string;
      issues?: string;
      issueNotes?: string;
    },
    onCompleted?: () => void,
  ) =>
    commit({
      variables: {
        input,
      },
      onCompleted,
    });

  return {
    isInFlight,
    updateSiteStatus,
  };
}
