import TimelinePlayer from '@/components/TimelinePlayer';
import { useSelector } from '@umijs/max';
import { Flex } from 'antd';
import React, { useMemo } from 'react';
import { useMonitorContext } from '../../../../MonitorContext';
import { useSceneUpdateActions } from '../../../../MonitorMutations';
import { getSceneName } from '../../../../utils';
import type { SceneFragment$data } from '../../../Scene/__generated__/SceneFragment.graphql';
import { EditableName } from './EditableName';
import { NewSceneTimelinePlaceholder } from './NewSceneTimelinePlaceholder';
import { TimelinePlayerContainer } from './styles';

interface SceneTimelineProps {
  timelinePlayerRef: React.MutableRefObject<undefined>;
  timelinePlayerProps?: any;
  selectedSceneData: SceneFragment$data | null | undefined;
  selectedChannels: string[] | undefined;
  fitTimeline: boolean; // True if timeline needs to be fit to prevent scrolling
}

const SceneTimeline = React.forwardRef<HTMLDivElement, SceneTimelineProps>(
  (
    {
      timelinePlayerRef,
      selectedSceneData,
      selectedChannels,
      timelinePlayerProps,
      fitTimeline,
    },
    ref,
  ) => {
    const { appId, currentUserId } = useMonitorContext();

    const { updateSceneName } = useSceneUpdateActions({
      scene: selectedSceneData as SceneFragment$data,
      appId,
    });

    const ch = useSelector(
      (state) =>
        // @ts-expect-error
        state.locations.ch,
    );

    const sceneName = useMemo(
      () =>
        selectedSceneData?.name ??
        getSceneName(selectedSceneData?.channelIDs as string[], ch),
      [selectedSceneData, ch],
    );

    const chIDs = (selectedChannels || []).map((n) => parseInt(n));

    const handleSceneNameChange = (name: string) => {
      updateSceneName({ name });
    };

    const showTimelineHeader = !!selectedSceneData;

    return (
      <Flex vertical flex={'1 0'} ref={ref}>
        {showTimelineHeader && (
          <Flex gap={8} align="center" justify="space-between">
            <EditableName
              name={sceneName}
              onNameChange={handleSceneNameChange}
              isEditable={selectedSceneData.createdBy === currentUserId}
            />
          </Flex>
        )}
        <TimelinePlayerContainer $fitTimeline={fitTimeline}>
          {chIDs.length > 0 ? (
            <TimelinePlayer
              innerRef={timelinePlayerRef}
              {...timelinePlayerProps}
            />
          ) : (
            <NewSceneTimelinePlaceholder />
          )}
        </TimelinePlayerContainer>
      </Flex>
    );
  },
);

export { SceneTimeline };
