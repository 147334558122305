import DataList from '@/components/DataList';
import DateRangeFilter from '@/components/DataList/DateRangeFilter';
import FreeTextSearchFilter from '@/components/DataList/FreeTextSearchFilter';
import NumberRangeFilter from '@/components/DataList/NumberRangeFilter';
import ViewSwitcherSecondary from '@/components/DataList/ViewSwitcherSecondary';
import LabelAndSiteSelector from '@/components/LabelAndSiteSelector';
import ReviewQueueCard from '@/components/ReviewQueueCard';
import { PRIORITY_REVIEW_QUEUE } from '@/utils/notifications';
import { getFlexDate, interpretClipData } from '@/utils/utils';
import { Button } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'umi';
import { APP_ID } from '../constants';
import { LossType, LossTypeLabels, ModelState, Transaction } from '../types';
import TransactionSidebar from './sidebar';
import styles from './style.less';
import {
  FilterParams,
  generateLossTypeDropdownOptions,
  getReviewCardProps,
  PaginationParams,
  SortingParams,
} from './utils';

const DEFAULT_PAGE_SIZE = 50;

const _generateFilterOptions = (arr: string[]) => {
  return (arr || []).map((item) => ({
    text: item,
    value: item,
  }));
};

interface TransactionsProps {
  transactions: ModelState['transactions'];
  fetchTransactions: (
    p: PaginationParams,
    f: FilterParams,
    s?: SortingParams,
  ) => void;
  fetchTransactionFilters: () => void;
}

const Transactions: React.FC<TransactionsProps> = ({
  transactions,
  fetchTransactions,
  fetchTransactionFilters,
}) => {
  const isLoading = useSelector((state: any) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects['checkout_insights/fetchIncidents'] ||
      loadingEffects['checkout_insights/fetchIncidentFilters'] ||
      loadingEffects['checkout_insights/fetchTransactions'] ||
      loadingEffects['checkout_insights/fetchTransactionFilters'] ||
      loadingEffects['accounts/fetchUsers']
    );
  });
  const locations = useSelector((state: any) => state['locations']);
  const sitesById = useSelector((state: any) => state.sites.byID);

  const [selectedTransactionId, setSelectedTransactionId] = useState<
    number | null
  >(null);
  const [visibleCards, setVisibleCards] = useState<{
    [key: string | number]: boolean;
  }>({});
  const [filterConfig, setFilterConfig] = useState({});
  const [paginationConfig, setPaginationConfig] = useState({
    p_number: 1,
    p_size: DEFAULT_PAGE_SIZE,
    total_pages: 0,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: [25, 50, 100, 250],
  });
  const getTransactionId = (transaction: Transaction) => {
    return transaction?.incident?.id || transaction.pos_transaction_id;
  };
  const selectedTransaction = transactions.list.find((transaction) => {
    return getTransactionId(transaction) === selectedTransactionId;
  });

  useEffect(
    () =>
      setPaginationConfig({
        ...paginationConfig,
        p_number: transactions.p_number || paginationConfig.p_number,
        p_size: paginationConfig.p_size,
        total_pages: transactions.total_pages || paginationConfig.total_pages,
      }),
    [transactions],
  );

  useEffect(() => {
    if (_.isEmpty(transactions.filters)) {
      fetchTransactionFilters();
    }
    // if (!transactions.p_number) {
    //   fetchTransactions(paginationConfig, filterConfig);
    // }
  }, []);

  // const onIncidentArchive = (incident: Incident) => {
  //   return (reason: string) => {
  //     dispatch({
  //       type: 'checkout_insights/archiveIncident',
  //       payload: {
  //         reason,
  //         ...incident,
  //       },
  //     });
  //   };
  // };

  const transactionsJsx = useMemo(
    () => (
      <DataList
        style={{ overflow: 'scroll' }}
        isLoading={isLoading}
        dataList={transactions.list}
        columns={[
          {
            title: 'Site',
            render: (site_id) => {
              return sitesById[site_id]?.Name;
            },
            dataIndex: 'site_id',
            key: 'site_id',
          },
          {
            title: 'Register',
            key: 'register',
            dataIndex: 'register',
            filters: (transactions.filters.registers || []).map(
              ({ id, label }) => {
                return { text: label, value: id };
              },
            ),
            sorter: true,
          },
          {
            title: 'Transaction ID',
            key: 'customer_transaction_id',
            dataIndex: 'customer_transaction_id',
            filterDropdown: FreeTextSearchFilter,
          },
          {
            title: 'Date',
            key: 'date',
            render: (__, record) => {
              const timezone = record.site_timezone || moment.tz.guess();
              return getFlexDate(
                moment.unix(record.start_time).tz(timezone),
                false,
                false,
                false,
                false,
              );
            },
            filterDropdown: DateRangeFilter,
            sorter: true,
          },
          {
            title: 'Time',
            key: 'time',
            render: (___, record) => {
              const timezone = record.site_timezone || moment.tz.guess();
              return moment
                .unix(record.start_time)
                .tz(timezone)
                .format('HH:mm:ss z');
            },
          },
          {
            title: 'Customer',
            key: 'customer',
            dataIndex: 'customer',
            filterDropdown: FreeTextSearchFilter,
            sorter: true,
          },
          {
            title: '# of Items',
            key: 'total_items',
            dataIndex: 'total_items',
            filterDropdown: NumberRangeFilter,
            sorter: true,
          },
          {
            title: 'Total',
            key: 'net_amount',
            dataIndex: 'net_amount',
            filterDropdown: (props) => (
              <NumberRangeFilter {...props} min={0} max={10000} />
            ),
            sorter: true,
          },
          {
            title: 'Cashier',
            key: 'cashier',
            dataIndex: 'cashier',
            filters: _generateFilterOptions(transactions.filters.cashiers),
          },
          {
            title: '# of Comments',
            key: 'num_of_comments',
            dataIndex: 'num_of_comments',
            render: (num_of_comments) => {
              return num_of_comments || 0;
            },
            filterDropdown: NumberRangeFilter,
            sorter: true,
          },
          {
            title: 'Loss Type',
            key: 'loss_type',
            dataIndex: 'loss_type',
            render: (loss_type: LossType) => {
              return LossTypeLabels[loss_type];
            },
            filters: generateLossTypeDropdownOptions(),
            sorter: true,
          },
          {
            title: 'Loss Amount',
            key: 'loss_amount',
            dataIndex: 'loss_amount',
            sorter: true,
            render: (amt) => {
              return isNaN(amt) ? '-' : amt;
            },
            filterDropdown: (props) => (
              <NumberRangeFilter {...props} min={0} max={10000} />
            ),
          },
          {
            title: 'Summary',
            key: 'summary',
            dataIndex: 'summary',
            render: (summary) => {
              return summary || '-';
            },
            filterDropdown: FreeTextSearchFilter,
            sorter: true,
          },
          {
            title: 'Actions',
            key: 'actions',
            excludeFromExport: true,
            render: (_e, record) => {
              return (
                <span>
                  <span
                    className="df-link"
                    onClick={() =>
                      setSelectedTransactionId(getTransactionId(record))
                    }>
                    View
                  </span>
                </span>
              );
            },
          },
        ]}
        rowClassName={(record: Transaction) => {
          return `${
            getTransactionId(record) === selectedTransactionId
              ? styles['selected-row']
              : ''
          }`;
        }}
        name={'checkout-incidents'}
        onChange={(newPagination, filters, newSorting) => {
          const newFilters = { ...filterConfig, ...filters };
          setFilterConfig(newFilters);
          setPaginationConfig({ ...paginationConfig, ...newPagination });
          fetchTransactions(newPagination, newFilters, newSorting);
        }}
        isControlled={true}
        pagination={paginationConfig}
        cardMap={{
          content: (record: Transaction, index: number) => {
            const alert = record.incident
              ? interpretClipData(
                  record.incident,
                  locations,
                  record?.site_timezone,
                )
              : getReviewCardProps(record, locations);
            return (
              <ReviewQueueCard
                alert={alert}
                alertTitle={
                  alert ? (
                    <div>
                      <div>
                        Alert for&nbsp;{_.get(record.incident, 'ruleName')}
                      </div>
                    </div>
                  ) : (
                    <>Transaction: {record.customer_transaction_id}</>
                  )
                }
                controls={
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Button
                      className="df-link"
                      onClick={() =>
                        setSelectedTransactionId(getTransactionId(record))
                      }>
                      View Details
                    </Button>
                    &nbsp;&nbsp;
                    {/* <Button>
                        <ArchiveIncident
                          incident={incident}
                          onArchive={onIncidentArchive(incident)}>
                          <span className="df-link df-error-text">Archive</span>
                        </ArchiveIncident>
                      </Button> */}
                  </div>
                }
                style={
                  _.get(alert, 'priority') === PRIORITY_REVIEW_QUEUE
                    ? { border: '2px solid red' }
                    : {}
                }
                // If card is first or it was added to visiblecards by clicking on it, have it visible
                timelineIsVisible={
                  index === 0 || !!visibleCards[getTransactionId(record)]
                }
                onTimeLineClick={() =>
                  setVisibleCards({
                    ...visibleCards,
                    [getTransactionId(record)]: true,
                  })
                }
              />
            );
          },
        }}
        ViewSwitcher={ViewSwitcherSecondary}
        //exportedFilename={hasAssociatedIncidents ? 'incidents' : 'transactions'}
      />
    ),
    [
      isLoading,
      transactions,
      paginationConfig,
      filterConfig,
      visibleCards,
      selectedTransaction,
    ],
  );

  return (
    <div>
      {
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}>
          <LabelAndSiteSelector
            app_id={APP_ID}
            onSitesChange={(newSites) => {
              const newFilters = { ...filterConfig, site_ids: newSites };
              setFilterConfig(newFilters);
              setPaginationConfig({ ...paginationConfig, p_number: 1 });
              fetchTransactions({ p_number: 1 }, newFilters);
            }}
          />
        </div>
      }

      {selectedTransaction && (
        <TransactionSidebar
          transaction={selectedTransaction}
          onClose={() => {
            setSelectedTransactionId(null);
          }}
        />
      )}
      {transactionsJsx}
    </div>
  );
};

export default Transactions;
