import { useSelector } from '@umijs/max';
import { Cascader } from 'antd';
import React from 'react';
import type { InvestigationGroupType } from '../../types';

type InvestigationGroupSelectionCascaderProps = React.ComponentProps<
  typeof Cascader
> & {
  currentInvestigationGroupID?: number;
};

const InvestigationGroupSelectionCascader: React.FC<
  InvestigationGroupSelectionCascaderProps
> = ({ currentInvestigationGroupID, ...props }) => {
  const investigationGroups = useSelector(
    (state) => state.investigations.investigationGroups,
  );
  // Get the root group (first item in the 'all' array)
  const rootGroup = investigationGroups.all[0];

  // Recursively build the options for Cascader
  const buildOptions = (
    group: InvestigationGroupType,
    isDisabled: boolean = false,
  ): any[] => {
    const isGroupDisabled =
      isDisabled ||
      (group.GroupID !== undefined &&
        group.GroupID === currentInvestigationGroupID);

    const option = {
      value: group.GroupID ?? 'root',
      label: group.Name ?? 'Investigations',
      disabled: isGroupDisabled,
      children: group.InvestigationGroupIDs
        ? group.InvestigationGroupIDs.map((childGroupId) =>
            buildOptions(
              investigationGroups.byId[childGroupId],
              isGroupDisabled,
            ),
          ).flat()
        : [],
    };

    return [option]; // Return as an array to work with Cascader's format
  };

  // Build options starting from the root group
  const options = buildOptions(rootGroup);
  return <Cascader options={options} changeOnSelect {...props} />;
};

export default InvestigationGroupSelectionCascader;
