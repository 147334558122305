import { AccountsModelState } from '@/models/accounts';
import {
  andify,
  dispatchWithFeedback,
  getCurrentCustomerID,
  getMailtoLink,
} from '@/utils/utils';
import _ from 'lodash';

const DF_LICENSE: any = {};
export const getConfigMap = (skus: {
  suites: any[];
  modules: any[];
}): Record<string, any> => {
  if (_.isEmpty(skus)) {
    return {};
  }
  if (DF_LICENSE.configMap) {
    return DF_LICENSE.configMap;
  }
  const configMap: Record<string, any> = {};
  [skus.suites, skus.modules].forEach((list) =>
    Object.values(list).forEach((category: any) =>
      category?.license_modes.forEach((mode: any) => {
        configMap[mode?.tag] = {
          category,
          tag: mode?.tag,
          entityType: mode?.entity,
          name: mode?.name || category?.name,
          description: mode?.description || category?.description,
          dependencies: mode?.dependencies || category?.dependencies,
          configs: mode?.configs || category?.configs,
        };
      }),
    ),
  );
  DF_LICENSE.configMap = configMap;
  return configMap;
};

export const getInfoForTag = (
  accounts: AccountsModelState,
  tag: string,
): Record<string, any> => {
  let suite = _.get(accounts, 'skus.suites').find((t: any) => t.tag == tag);
  if (suite) {
    return suite;
  }
  let module = _.get(accounts, 'skus.modules').find((t: any) => t.tag == tag);
  if (module) {
    return module;
  }

  return _.get(getConfigMap(accounts.skus), tag, { name: tag });
};

export const doLicenseOp = (
  op,
  { dispatch, accounts },
  licenses,
  tags,
  entityID,
) => {
  let configProfiles = [];
  let newLicenses = _.cloneDeep(licenses);

  tags.forEach((tag) => {
    let oldTagLicenses = _.cloneDeep(
      _.get(newLicenses, tag, { entities: [], licenses: 0 }),
    );
    let newEntities;
    if (op === 'activate') {
      newEntities = _.union(oldTagLicenses.entities, [entityID]);
    } else if (op === 'deactivate') {
      _.remove(oldTagLicenses.entities, (e) => e === entityID);
      newEntities = oldTagLicenses.entities;
    }

    newLicenses = {
      ...newLicenses,
      [tag]: {
        entities: newEntities,
        licenses: oldTagLicenses.licenses,
      },
    };

    // check what other configs we need to set up
    let info = getInfoForTag(accounts, tag);
    let path;
    if (op === 'activate') {
      path = 'configs.enabled';
    } else if (op === 'deactivate') {
      path = 'configs.disabled';
    }

    let tagConfigs = _.get(info, path, []);
    configProfiles.push(
      ...tagConfigs.map((spec) => ({
        entity_type: info.entityType,
        entity_id: entityID,
        profile_name: spec.name,
        profile_type: spec.type,
      })),
    );
  });

  const customerID = +getCurrentCustomerID();
  configProfiles.push({
    entity_type: 'Customer',
    entity_id: customerID,
    profile_type: 'licenses',
    profile_name: JSON.stringify(newLicenses),
  });

  return dispatchWithFeedback(
    dispatch,
    'Saving licenses',
    {
      type: 'accounts/addConfigProfiles',
      payload: {
        configs: configProfiles,
      },
    },
    true,
  ).then(() => newLicenses);
};

export const activateLicense = (
  { dispatch, accounts },
  licenses,
  tags,
  entityID,
) => {
  return doLicenseOp(
    'activate',
    { dispatch, accounts },
    licenses,
    tags,
    entityID,
  );
};

export const deactivateLicense = (
  { dispatch, accounts },
  licenses,
  tags,
  entityID,
) => {
  return doLicenseOp(
    'deactivate',
    { dispatch, accounts },
    licenses,
    tags,
    entityID,
  );
};

export const getInfoForLicensableCategory = (
  accounts: AccountsModelState,
  tag: string,
): {
  category: Record<string, any>;
  catLicenses: Record<string, any>;
  availableLicenses: number;
} | null => {
  let { licenses } = accounts;
  if (_.isEmpty(accounts.skus)) {
    return null;
  }

  let category = getInfoForTag(accounts, tag);

  let cat_licenses: Record<string, any>;
  let number_of_licenses;

  // if this is a top-level module, we have to aggregate licenses
  if (_.get(category, 'license_modes')) {
    let license_modes = _.get(category, 'license_modes', []);
    cat_licenses = license_modes.reduce(
      (acc: Record<string, any>, mode: any) => {
        acc[mode.tag] = licenses[mode.tag] || { entities: [], licenses: 0 };
        return acc;
      },
      {},
    );
    number_of_licenses = Object.keys(cat_licenses).reduce((acc, ltag) => {
      const new_acc =
        acc +
        (_.get(cat_licenses, `${ltag}.licenses`, 0) -
          _.get(cat_licenses, `${ltag}.entities.length`, 0));
      return new_acc;
    }, 0);
  } else {
    cat_licenses = _.get(licenses, tag);
    number_of_licenses =
      _.get(cat_licenses, 'licenses', 0) -
      _.get(cat_licenses, 'entities.length', 0);
  }

  return {
    category,
    catLicenses: cat_licenses,
    availableLicenses: number_of_licenses,
  };
};

export const updateConfigAndLicenseForEntity = (
  radio_val,
  license_val,
  tag,
  entityID,
  { dispatch, accounts },
) => {
  const catLicenses = _.get(
    getInfoForLicensableCategory(accounts, tag),
    'catLicenses',
    {},
  );

  if (!radio_val) {
    return Promise.resolve();
  }

  let licenses = accounts.licenses;
  let toDeactivate = Object.keys(catLicenses);

  if (radio_val !== 'off') {
    toDeactivate = _.without(Object.keys(catLicenses), license_val);
  }

  // need to this in sequence else stale licenses
  // will be used in the next step
  // also, deactivate before activation because sometimes same config vars
  // are used by multiple tags (eg STR-7DY and STR-3MO) and we need to
  // make sure they end up being set up at the end
  return Promise.resolve()
    .then(() => {
      // deactivate all other licenses of this type
      if (!toDeactivate.length) {
        return licenses;
      }
      return deactivateLicense(
        { dispatch, accounts },
        licenses,
        toDeactivate,
        +entityID,
      );
    })
    .then((newLicenses) => {
      if (radio_val === 'off') {
        return newLicenses;
      }
      return activateLicense(
        { dispatch, accounts },
        newLicenses,
        [license_val],
        +entityID,
      );
    })
    .then(() =>
      dispatchWithFeedback(
        dispatch,
        'Refreshing',
        {
          type: 'accounts/fetchLicenseInfo',
        },
        true,
      ),
    );
};

export const findDependentLicenses = (accounts, tag, channelID) => {
  // for each license we have, check if that license has this entity, and in so,
  // if that license depends on this one
  let { licenses } = accounts;

  let dependents = [];
  Object.entries(licenses).forEach(([testTag, { entities = [] }]) => {
    let info = getInfoForTag(accounts, testTag);
    if (info.entityType !== 'Channel' || entities.indexOf(channelID) === -1) {
      return;
    }
    if (_.get(info, 'dependencies.Channel', []).indexOf(tag) === -1) {
      return;
    }
    dependents.push(info.name);
  });

  return dependents;
};

export const getMailtoForLicenseRequest = (currentUser, license) => {
  const customer = currentUser.Customers[getCurrentCustomerID()].Customer;

  const subject = `License Request for ${license.name}`;
  const body = `We would like to add a new license to our Dragonfruit account. Please contact us. ${customer.Name} (id: ${customer.CustomerID}), License: ${license.name}.`;

  return getMailtoLink('sales@dragonfruit.ai', subject, body);
};

// function gives you license tag and location/channel id mapping
// we can do it in two format:- ID_TO_TAGS, TAG_TO_IDS
// entity: id -> license_tag[] mapping
export type ENTITIES_LICENSES_ID_TO_TAGS = {
  location: Record<number, string[]>;
  channel_group: Record<number, string[]>;
  channel: Record<number, string[]>;
};
// entity: license_tag -> id[] mapping
export type ENTITIES_LICENSES_TAG_TO_IDS = {
  location: Record<string, number[]>;
  channel_group: Record<string, number[]>;
  channel: Record<string, number[]>;
};
export const entitiesHasLicensesOfType = (
  accounts: AccountsModelState,
  types: string[] = [],
  {
    IDsType = 'Channel',
    IDs = [],
  }: {
    IDsType: 'Project' | 'ChannelGroup' | 'Channel';
    IDs: (string | number)[];
  },
  mapping: 'ID_TO_TAGS' | 'TAG_TO_IDS',
): ENTITIES_LICENSES_ID_TO_TAGS | ENTITIES_LICENSES_TAG_TO_IDS => {
  let entities: ENTITIES_LICENSES_ID_TO_TAGS | ENTITIES_LICENSES_TAG_TO_IDS = {
    location: {},
    channel_group: {},
    channel: {},
  };

  // Get found_type
  const found_type =
    IDsType === 'Project'
      ? 'location'
      : IDsType === 'ChannelGroup'
      ? 'channel_group'
      : IDsType === 'Channel'
      ? 'channel'
      : null;
  if (!found_type) return entities;

  if (mapping === 'TAG_TO_IDS') {
    const found: ENTITIES_LICENSES_TAG_TO_IDS = {
      location: {},
      channel_group: {},
      channel: {},
    };
    Object.values(accounts.skus).forEach((list) => {
      list.forEach((tagInfo: any) => {
        if (types.includes(tagInfo.tag)) {
          tagInfo.license_modes.forEach((mode: any) => {
            const license_entities: number[] = _.get(
              accounts,
              `licenses[${mode.tag}].entities`,
              [],
            );
            IDs.forEach((id) => {
              const Id = +id;
              if (license_entities.includes(Id)) {
                const found_type_obj = found[found_type];
                if (tagInfo.tag in found_type_obj) {
                  found_type_obj[tagInfo.tag].push(Id);
                } else {
                  found_type_obj[tagInfo.tag] = [Id];
                }
              }
            });
          });
        }
      });
    });
    entities = found;
  }

  if (mapping === 'ID_TO_TAGS') {
    const found: ENTITIES_LICENSES_ID_TO_TAGS = {
      location: {},
      channel_group: {},
      channel: {},
    };
    Object.values(accounts.skus).forEach((list) => {
      list.forEach((tagInfo: any) => {
        if (types.includes(tagInfo.tag)) {
          tagInfo.license_modes.forEach((mode: any) => {
            const license_entities: number[] = _.get(
              accounts,
              `licenses[${mode.tag}].entities`,
              [],
            );
            IDs.forEach((id) => {
              const Id = +id;
              if (license_entities.includes(Id)) {
                const found_type_obj = found[found_type];
                if (Id in found_type_obj) {
                  found_type_obj[Id].push(tagInfo.tag);
                } else {
                  found_type_obj[Id] = [tagInfo.tag];
                }
              }
            });
          });
        }
      });
    });
    entities = found;
  }

  return entities;
};

export const entityHasLicenseOfType = (
  accounts: AccountsModelState,
  type: string,
  channelID: number | null = null,
  locationID: number | null = null,
): boolean => {
  let found = false;
  Object.values(accounts.skus).forEach((list) => {
    list.forEach((tagInfo: any) => {
      if (tagInfo.tag !== type) {
        return;
      }
      tagInfo.license_modes.forEach((mode: any) => {
        let entities = _.get(accounts, `licenses[${mode.tag}].entities`, []);
        let entity = channelID;
        if (mode.entity === 'Project') {
          entity = locationID;
        }
        // console.log('looking into', type, mode, entity, entities);
        if (entities.indexOf(entity) > -1) {
          found = true;
        }
      });
    });
  });
  return found;
};

export const checkEnableStatusForLicenseMode = (
  accounts,
  category,
  license_key,
  channelID,
) => {
  const catLicenses = _.get(
    getInfoForLicensableCategory(accounts, category),
    'catLicenses',
    {},
  );
  let modeInfo = getInfoForTag(accounts, license_key);

  // this is for a specific license_mode, not category overall
  const license_available =
    _.get(catLicenses, `${license_key}.licenses`, 0) -
    _.get(catLicenses, `${license_key}.entities.length`, 0);

  if (
    catLicenses &&
    _.get(catLicenses, `${license_key}.entities`, []).indexOf(channelID) ===
      -1 &&
    license_available <= 0
  ) {
    return `No available licenses for ${modeInfo.name}.`;
  }

  let channelDependencies = _.get(modeInfo, 'dependencies.Channel', []);
  let dependenciesNotMet = [];
  channelDependencies.forEach((tag) => {
    if (!entityHasLicenseOfType(accounts, tag, channelID)) {
      let info = getInfoForTag(accounts, tag);
      dependenciesNotMet.push(info.name);
    }
  });
  if (dependenciesNotMet.length) {
    return `Requires ${andify(dependenciesNotMet)} to be enabled.`;
  }

  return null;
};

export const getActivePolicy = (
  accounts: AccountsModelState,
  entityID: number,
  tag: string,
) => {
  const catLicenses = _.get(
    getInfoForLicensableCategory(accounts, tag),
    'catLicenses',
    {},
  );
  let policy: string | null = null;
  Object.entries(catLicenses).forEach(([catTag, licenseInfo]) => {
    if (_.get(licenseInfo, 'entities', []).indexOf(entityID) !== -1) {
      policy = catTag;
    }
  });
  return policy;
};
