import { css, styled } from 'styled-components';

export const SearchResultCardHeight = 150; // in px
export const SearchResultCardWidth = 157; // in px

export const SearchResultCardContainer = styled.div<{ selected: boolean }>(
  ({ theme, selected }) => css`
    background-color: white;
    box-shadow: ${theme.token?.boxShadow};
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    outline: ${selected ? `medium solid ${theme.token?.colorPrimary}` : 'none'};
    cursor: pointer;
    width: 100%;
    height: ${SearchResultCardHeight}px;
    z-index: 1;
    flex: 1 0 ${SearchResultCardWidth}px; /* Allow cards to grow to fill space while keeping a minimum width */
  `,
);

export const SearchResultCardThumbnailContainer = styled.div`
  width: 100%;
  height: 98px;
  background-color: black;
`;

export const SearchResultCardTextContainer = styled.div`
  font-size: 12px;
  line-height: 18px;
  padding: 6px 10px 10px 10px;
`;

export const SelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  pointer-events: none;
  z-index: 2;
`;
