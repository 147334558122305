import { Layout } from 'antd';
import styled, { css } from 'styled-components';

const { Sider } = Layout;

export const SceneViewerContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 20px;
`;

export const SceneViewerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

export const StyledSider = styled(Sider)(
  ({ theme }) => css`
    background: ${theme.components?.Layout?.siderBg} !important;

    @media (max-width: ${theme.token?.screenSM}px) {
      position: absolute !important;
      top: 10px;
      left: 10px;
      z-index: 1;
    }
  `,
);
