import LinkWithArrow from '@/components/LinkWithArrow';
import type { InvestigationGroupType } from '@/pages/investigations/investigation-group/types';
import { Link, useSelector } from '@umijs/max';
import { Empty } from 'antd';
import InvestigationCard from '../investigation-card';
import styles from './style.less';

export default ({
  investigationGroup,
}: {
  investigationGroup: InvestigationGroupType;
}) => {
  const investigationsById = useSelector(
    (state) => state.investigations.investigations.byId,
  );
  return (
    <div>
      <div key={investigationGroup.GroupID}>
        <div className={styles['investigation-group-name-heading']}>
          {investigationGroup.Name}&nbsp;
          <LinkWithArrow
            link={`/investigation-groups/${investigationGroup.GroupID}`}
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {investigationGroup.InvestigationIDs.length == 0 ? (
            <div className={styles['empty-investigation-group-container']}>
              <Empty
                description="No Investigations"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          ) : null}
          {investigationGroup.InvestigationIDs.map((investigationID) => (
            <Link
              key={investigationID}
              to={`/investigations/${investigationID}`}>
              <InvestigationCard
                investigation={investigationsById[investigationID]}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
