import DataList from '@/components/DataList';
import LoadingSpinner from '@/components/LoadingSpinner';
import { FormOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import type { ColumnType } from 'antd/es/table';
import React, { Suspense, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import FleetStatusSidebar from './FleetStatusSidebar';
import styles from './style.less';
import { FleetStatusProps, SiteRecord } from './types';

const ITEMS_PER_PAGE = 25;

export const FleetStatusFragment = graphql`
  fragment FleetStatus_sites on SiteConnection {
    edges {
      node {
        id
        ExternalID
        Name
        Status
        HasStatusNotes
        Issues
        HasIssueNotes
        Labels {
          LabelID
          Name
          Color
        }
      }
    }
  }
`;

const FleetStatus: React.FC<FleetStatusProps> = ({ sitesRef }) => {
  const [selectedSite, setSelectedSite] = useState<SiteRecord | null>(null);
  const sites = useFragment(FleetStatusFragment, sitesRef);

  const statusOptions = useMemo(() => {
    const statuses = new Set<string>();
    sites?.edges.forEach((edge) => {
      if (edge?.node?.Status) {
        statuses.add(edge.node.Status);
      }
    });
    return Array.from(statuses).map((status) => ({
      text: status,
      value: status,
    }));
  }, [sites]);

  const issuesOptions = useMemo(() => {
    const issues = new Set<string>();
    sites?.edges.forEach((edge) => {
      if (edge?.node?.Issues) {
        issues.add(edge.node.Issues);
      }
    });
    return Array.from(issues).map((issue) => ({
      text: issue,
      value: issue,
    }));
  }, [sites]);

  const labelOptions = useMemo(() => {
    const labels = new Set<string>();
    sites?.edges.forEach((edge) => {
      edge?.node?.Labels?.forEach((label) => {
        if (label?.Name) {
          labels.add(label.Name);
        }
      });
    });
    return Array.from(labels).map((label) => ({
      text: label,
      value: label,
    }));
  }, [sites]);

  const uniqueStatuses = useMemo(() => {
    const statuses = new Set<string>();
    sites?.edges.forEach((edge) => {
      if (edge?.node?.Status) {
        statuses.add(edge.node.Status);
      }
    });
    return Array.from(statuses);
  }, [sites]);

  const uniqueIssues = useMemo(() => {
    const issues = new Set<string>();
    sites?.edges.forEach((edge) => {
      if (edge?.node?.Issues) {
        issues.add(edge.node.Issues);
      }
    });
    return Array.from(issues);
  }, [sites]);

  const columns = [
    {
      title: 'Site',
      dataIndex: 'Name',
      key: 'Name',
      enableLocalFiltering: true,
      enableLocalSorting: true,
    },
    {
      title: 'Site ID',
      dataIndex: 'ExternalID',
      key: 'ExternalID',
      enableLocalFiltering: true,
      enableLocalSorting: true,
      render: (_: unknown, record: SiteRecord) => (
        <span>{record?.ExternalID || '-'}</span>
      ),
    },
    {
      title: 'Groups',
      key: 'Labels',
      render: (_: unknown, record: SiteRecord) => {
        if (!record?.Labels?.length) return '-';
        return (
          <span>
            {record.Labels.slice(0, 3).map((label, index) => (
              <React.Fragment key={label?.LabelID}>
                <span style={{ color: label?.Color || '#000000' }}>
                  {label?.Name}
                </span>
                {index < 2 &&
                  index !== (record?.Labels?.length || 0) - 1 &&
                  ', '}
              </React.Fragment>
            ))}
            {record.Labels.length > 3 && (
              <span>
                {' and '}
                {record.Labels.length - 3}
                {' more'}
              </span>
            )}
          </span>
        );
      },
      filters: labelOptions,
      filterMultiple: true,
      onFilter: (value: string, record: SiteRecord) =>
        record?.Labels?.some((label) => label?.Name === value) || false,
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      render: (status: string | null, record: SiteRecord) => (
        <span>
          {status || '-'}
          {record?.HasStatusNotes && <FormOutlined style={{ marginLeft: 8 }} />}
        </span>
      ),
      filters: statusOptions,
      filterMultiple: true,
      onFilter: (value: string, record: SiteRecord) => record?.Status === value,
    },
    {
      title: 'Issues',
      dataIndex: 'Issues',
      key: 'Issues',
      render: (issues: string | null, record: SiteRecord) => (
        <span>
          {issues || '-'}
          {record?.HasIssueNotes && <FormOutlined style={{ marginLeft: 8 }} />}
        </span>
      ),
      filters: issuesOptions,
      filterMultiple: true,
      onFilter: (value: string, record: SiteRecord) => record?.Issues === value,
    },
    {
      title: 'Asset Status',
      key: 'assetStatus',
      render: () => '-',
    },
    {
      title: 'Tickets',
      key: 'ticket',
      render: () => '-',
    },
    {
      title: 'Actions',
      key: 'details',
      render: (_: unknown, record: SiteRecord) => (
        <span className="df-link" onClick={() => setSelectedSite(record)}>
          Details
        </span>
      ),
    },
  ];

  return (
    <>
      <DataList
        dataList={sites?.edges.map((edge) => edge?.node) || []}
        columns={columns as ColumnType<SiteRecord>[]}
        bordered
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No sites found"
            />
          ),
        }}
        pagination={{
          p_size: ITEMS_PER_PAGE,
          position: ['bottomLeft'],
        }}
      />
      {selectedSite && (
        <FleetStatusSidebar
          siteId={selectedSite.id}
          visible={!!selectedSite}
          onClose={() => setSelectedSite(null)}
          uniqueStatuses={uniqueStatuses}
          uniqueIssues={uniqueIssues}
        />
      )}
    </>
  );
};

const FleetStatusWrapper: React.FC<FleetStatusProps> = (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <div className={styles.header}>
      <div className={styles.title}>
        <h3 style={{ margin: '0px' }}>Sites</h3>
      </div>
    </div>
    <FleetStatus {...props} />
  </Suspense>
);

export default FleetStatusWrapper;
