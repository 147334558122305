import styled, { css } from 'styled-components';

const ThumbnailHeight = 58; // in px
const ThumbnailAspectRatio = 12 / 7;
export const ChannelGroupsListContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  /* overflow-y auto clips the box shadow of children. So this is done to give children space to expand into */
  padding-right: 8px;
  margin-right: -8px;

  // Hiding the scrollbar as its overlapping the content
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

// Contains one of two typexs of channel groups: selected Channel List or Site Channel List
// Group has Group Header containing name and list (of channels)
export const ChannelGroupContainer = styled.div``;

export const ChannelGroupHeaderContainer = styled.div`
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const ChannelGroupListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const OverlayContainer = styled.div(
  ({ theme }) => `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.token?.colorBgMask};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    pointer-events: none; /* Ensures the overlay doesn't block interactions with the image */
    border-radius: inherit; /* Keep border-radius if the image has one */
    z-index: 1;
`,
);

export const LivePreviewPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  /* To override tile containers messed up margin. remove once it is fixed */
  .df-tile-container {
    margin: 0px;
  }
`;

export const ChannelTileAndNameContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: ${ThumbnailHeight}px;
    border-radius: 2px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    overflow: hidden;
    background-color: white;
    box-shadow: ${theme.token?.boxShadow};
  `,
);

export const ChannelTileAndNameThumbnailContainer = styled.div`
  position: relative;
  height: 100%;
  aspect-ratio: ${ThumbnailAspectRatio};
  background-color: #6c757d;

  .ant-image {
    width: 100%;
    height: 100%;
  }

  .ant-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
