import { Input, Typography } from 'antd';
import styled, { css } from 'styled-components';

export const StyledAntdInput = styled(Input)<{ inputWidth: number }>`
  max-height: 24px;
  border: none;
  margin: 0;
  padding: 0;
  background-color: #e0e0e0;
  font-size: 24px;
  font-weight: 800;
  width: ${({ inputWidth }) => `${inputWidth}px`};
  outline: none;
  box-shadow: none; /* Remove any default box shadow */
  background-color: inherit; /* Inherit background from the container */
  &:hover {
    background-color: inherit; /* Maintain the background color on hover */
  }

  &:focus {
    background-color: inherit;
    background-color: inherit;
    border: none;
    box-shadow: none;
    box-shadow: none;
  }
`;

export const NameIconAndCheckContainer = styled.div`
  display: inline-flex;
  align-items: center; /* Align items to the top */
  gap: 10px;
`;

export const NameAndIconContainer = styled.div<{ isEditing: boolean }>`
  display: inline-flex;
  align-items: center; /* Align items to the top */
  padding: 8px;
  background-color: ${({ isEditing }) =>
    isEditing ? '#e0e0e0' : 'transparent'}; /* Dark background when editing */
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }

  .icon {
    margin-left: 8px;
    visibility: hidden; /* Initially hidden */

    &:hover {
      cursor: pointer; /* Show pointer cursor when hovering over the icon */
    }
  }

  &:hover .icon {
    visibility: visible; /* Show the icon on hover */
  }
`;

export const HiddenTypography = styled(Typography.Text)`
  visibility: hidden;
  white-space: pre;
  font-size: 24px;
  font-weight: 800;
  position: absolute;
  pointer-events: none;
`;

export const TimelinePlayerPlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const TimelinePlayerPlaceholderBlockContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

export const TimelinePlayerPlaceholderBlock = styled.div(
  ({ theme }) => `
  flex: 1;
  min-width: 49%;
  background-color: ${theme.token?.colorBorder};
`,
);

export const TimelinePlayerContainer = styled.div<{ $fitTimeline: boolean }>`
  ${({ theme, $fitTimeline }) => css`
    padding: 16px;
    width: 100%;
    flex-grow: ${$fitTimeline ? '1' : '0'};
    height: ${$fitTimeline ? 'auto' : '100%'};
    aspect-ratio: ${$fitTimeline ? 'unset' : '16 / 9'};
    background-color: ${theme.token?.colorBgContainer};
    border-radius: ${theme.token?.borderRadiusLG}px;
  `}
`;
