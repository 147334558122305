import DefaultCameraImage from '@/assets/cam-image-default.png';
import { getChannelThumbnailImageUrl } from '@/pages/apps/app/VMSPlus/utils';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Image, Popover, theme as antdTheme, Typography } from 'antd';
import { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { graphql, useFragment } from 'react-relay';
import {
  ChannelTileAndNameContainer,
  ChannelTileAndNameThumbnailContainer,
  LivePreviewPopoverContainer,
  OverlayContainer,
} from './styles';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';

const PopoverContent = ({
  channelID,
  channelName,
  siteName,
}: {
  channelID: number;
  channelName: string;
  siteName: string;
}) => {
  return (
    <LivePreviewPopoverContainer>
      <ChannelTile
        key={Number(channelID)}
        channelID={Number(channelID)}
        showTime={false}
        showImagePreview={true}
        hideFooter={true}
      />
      <Typography.Text style={{ fontSize: '16px', fontWeight: 800 }}>
        {channelName}
      </Typography.Text>
      <Typography.Text>{siteName}</Typography.Text>
      <Typography.Text strong style={{ color: 'red' }}>
        Live Now
      </Typography.Text>
    </LivePreviewPopoverContainer>
  );
};

const ChannelTileFragment = graphql`
  fragment ChannelTileAndName_Channel on Channel {
    Name
    ChannelID
    MonitorStatus
    LatestMedia
  }
`;

type ChannelTileAndNameProps = {
  tileFragmentRef: ChannelTileAndName_Channel$key;
  siteName: string;
  onClick: (
    channelId: string,
    channelRef: ChannelTileAndName_Channel$key,
  ) => void;
  isSelected?: boolean;
};
const ChannelTileAndName = ({
  tileFragmentRef,
  isSelected,
  siteName,
  onClick,
}: ChannelTileAndNameProps) => {
  const { useToken } = antdTheme;
  const { token } = useToken();
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const channelData = useFragment(ChannelTileFragment, tileFragmentRef);
  const channelThumbnailUrl = useMemo(
    () => (inView ? getChannelThumbnailImageUrl(channelData) : ''),
    [channelData, inView],
  );

  return (
    <Popover
      placement="leftBottom"
      arrow={false}
      content={
        <PopoverContent
          channelID={Number(channelData.ChannelID)}
          channelName={channelData.Name as string}
          siteName={siteName}
        />
      }>
      <ChannelTileAndNameContainer
        key={channelData.ChannelID}
        onClick={() => {
          onClick(channelData.ChannelID, tileFragmentRef);
        }}>
        <ChannelTileAndNameThumbnailContainer ref={ref}>
          <Image
            src={channelThumbnailUrl}
            fallback={DefaultCameraImage}
            preview={false}
          />
          {isSelected && (
            <OverlayContainer>
              <CheckCircleTwoTone twoToneColor={token.colorPrimary} />
            </OverlayContainer>
          )}
        </ChannelTileAndNameThumbnailContainer>
        <Typography.Text style={{ fontWeight: 500 }}>
          {channelData.Name}
        </Typography.Text>
      </ChannelTileAndNameContainer>
    </Popover>
  );
};

export default ChannelTileAndName;
