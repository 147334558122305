import { useCustomerProfileValue } from '@/utils/hooks';
import _ from 'lodash';

export const useDemographicInsights = (): boolean => {
  const values = useCustomerProfileValue('fe_demographic_insights_visibility');
  return _.get(values, 'visibility') == 'on';
};

export const useGPTSummary = (): boolean => {
  const values = useCustomerProfileValue('be_gpt_summary');
  return _.get(values, 'enabled') == true;
};

export const useStaffDetection = (): boolean => {
  const values = useCustomerProfileValue('fe_staff_detection');
  return _.get(values, 'visibility') == 'on';
};
