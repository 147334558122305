import React from 'react';
import { connect, history } from 'umi';

import { Modal } from 'antd';
import type { InvestigationGroupType } from '../../types';

interface DeleteInvestigationGroupProps {
  investigationGroup: InvestigationGroupType;
  children?: React.ReactNode;
  disabled?: boolean;
}

// @ts-expect-error
@connect(({ loading }) => ({ loading }))
class DeleteInvestigationGroup extends React.Component<
  DeleteInvestigationGroupProps,
  any
> {
  state = {
    showModal: false,
  };

  componentDidMount() {}

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  deleteInvestigationGroup(e) {
    const { dispatch, investigationGroup } = this.props;
    e.preventDefault();
    dispatch({
      type: 'investigations/deleteInvestigationGroup',
      investigationGroupID: investigationGroup.GroupID,
    }).then(() => {
      this.toggleModal();
      history.push('/investigations');
    });
  }

  render() {
    const { children, loading, investigationGroup, disabled } = this.props;
    return (
      <>
        <Modal
          width={400}
          title="Delete Investigation Group"
          open={this.state.showModal}
          onOk={(e) => this.deleteInvestigationGroup(e)}
          confirmLoading={
            loading.effects['investigations/deleteInvestigationGroup']
          }
          onCancel={() => this.toggleModal()}>
          Are you sure you want to delete the investigation group{' '}
          <b>{investigationGroup?.Name}</b>?
        </Modal>
        <span onClick={() => !disabled && this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default DeleteInvestigationGroup;
