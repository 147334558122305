/**
 * @generated SignedSource<<5d52e3657a8d813abc368ab5fa693b54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LabelsRefetchQuery$variables = {
  after?: string | null | undefined;
  app_id: number;
  customer_id: number;
  first?: number | null | undefined;
};
export type LabelsRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FleetManagerApp_labels">;
};
export type LabelsRefetchQuery = {
  response: LabelsRefetchQuery$data;
  variables: LabelsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "app_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customer_id"
  },
  {
    "defaultValue": 10000,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "app_id"
  },
  {
    "kind": "Variable",
    "name": "customerId",
    "variableName": "customer_id"
  },
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "sort",
    "value": [
      "NAME_ASC"
    ]
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LabelsRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "app_id",
            "variableName": "app_id"
          },
          {
            "kind": "Variable",
            "name": "customer_id",
            "variableName": "customer_id"
          },
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "FleetManagerApp_labels"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LabelsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "LabelConnection",
        "kind": "LinkedField",
        "name": "labels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LabelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "LabelID",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "LabelType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "Color",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteConnection",
                    "kind": "LinkedField",
                    "name": "Sites",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Site",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "SiteID",
                                "storageKey": null
                              },
                              (v5/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "appId",
          "customerId",
          "sort"
        ],
        "handle": "connection",
        "key": "FleetManagerApp_labels",
        "kind": "LinkedHandle",
        "name": "labels"
      }
    ]
  },
  "params": {
    "cacheID": "1eb999f860bf131138a7a128a67f5eb8",
    "id": null,
    "metadata": {},
    "name": "LabelsRefetchQuery",
    "operationKind": "query",
    "text": "query LabelsRefetchQuery(\n  $after: String\n  $app_id: Int!\n  $customer_id: Int!\n  $first: Int = 10000\n) {\n  ...FleetManagerApp_labels_gtPD6\n}\n\nfragment CreateUpdateLabel_label on Label {\n  id\n  LabelID\n  Name\n  LabelType\n  Color\n  Sites {\n    edges {\n      node {\n        SiteID\n        Name\n        id\n      }\n    }\n  }\n}\n\nfragment CreateUpdateLabel_labels on LabelConnection {\n  edges {\n    node {\n      id\n      LabelID\n      Name\n      LabelType\n      Color\n      Sites {\n        edges {\n          node {\n            SiteID\n            Name\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CreateUpdateSite_labels on LabelConnection {\n  edges {\n    node {\n      Name\n      LabelID\n      Color\n      id\n    }\n  }\n}\n\nfragment EventLog_labels on LabelConnection {\n  edges {\n    node {\n      id\n      LabelID\n      Name\n    }\n  }\n}\n\nfragment FleetManagerApp_labels_gtPD6 on Query {\n  labels(appId: $app_id, customerId: $customer_id, sort: [NAME_ASC], first: $first, after: $after) {\n    ...Labels_labels\n    ...Sites_labels\n    ...EventLog_labels\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Labels_labels on LabelConnection {\n  ...CreateUpdateLabel_labels\n  edges {\n    node {\n      id\n      LabelID\n      Name\n      LabelType\n      Color\n      Sites {\n        edges {\n          node {\n            SiteID\n            Name\n            id\n          }\n        }\n      }\n      ...CreateUpdateLabel_label\n    }\n  }\n}\n\nfragment Sites_labels on LabelConnection {\n  ...CreateUpdateSite_labels\n}\n"
  }
};
})();

(node as any).hash = "a98a475093b71ee24b4acc1aaca92ea3";

export default node;
