import { LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  //We need this for the tooltip (if shown) to be positioned correctly
  position: relative;
  width: fit-content;
`;

export const LockIcon = styled(LockOutlined)(
  ({ theme }) => `
  font-size: 18px;
  color: ${theme.token?.colorTextDisabled || 'rgba(0, 0, 0, 0.85)'}
`,
);
